import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
    name: 'paymentData',
    // initialState: {id: 1, firstName: 'Aslam', lastName: 'Idrisi', lastLogin: '' },
    initialState: {
        amount: null,
        currency: 'INR',
        email: '',
        contact: '',
        notes: {
            address: '',
        },
        order_id: '',// Replace with Order ID generated in Step 4
        method: null,

        // method specific fields
        // bank: 'HDFC'
    },
    reducers: {
        updatePaymentData: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        clearPaymentData: (state, action) => {
            return null;
        }
    }
});

export const { updatePaymentData, clearPaymentData } = paymentSlice.actions;

export default paymentSlice.reducer;