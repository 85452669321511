import { createSlice } from '@reduxjs/toolkit';

export const errSlice = createSlice({
    name: 'err',
    // initialState: {id: 1, firstName: 'Aslam', lastName: 'Idrisi', lastLogin: '' },
    initialState: "",
    reducers: {
        updateErr: (state, action) => {
            const err = action.payload;
            return err;
        }
    },
});

export const { updateErr } = errSlice.actions;

export default errSlice.reducer;