const cardData = {
    months: [
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ],
    year: [
        { value: '2023', label: '2023' },
        { value: '2024', label: '2024' },
        { value: '2025', label: '2025' },
        { value: '2026', label: '2026' },
        { value: '2027', label: '2027' },
        { value: '2028', label: '2028' },
        { value: '2029', label: '2029' },
        { value: '2030', label: '2030' },
        { value: '2031', label: '2031' },
        { value: '2032', label: '2032' },
        { value: '2033', label: '2033' },
        { value: '2034', label: '2034' },
        { value: '2035', label: '2035' },
        { value: '2036', label: '2036' },
        { value: '2037', label: '2037' },
        { value: '2038', label: '2038' },
        { value: '2039', label: '2039' },
        { value: '2040', label: '2040' },
        { value: '2041', label: '2041' },
        { value: '2042', label: '2042' },
        { value: '2043', label: '2043' }
    ]
}

export { cardData }