import { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { faXmark, faGlobe, faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as ROUTES from "../constant/routes";


export default function UserMenuContainer({ show = false, handleUserMenuToggle, handleToggleSidebar, user, handleLogout }) {

    return (
        <Transition show={show}
            className="h-screen w-full fixed top-0 z-[2] overflow-hidden"
        >
            <Transition.Child
                className="h-full w-full absolute top-0 bg-[rgba(0,0,0,0.4)] "
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                onClick={handleUserMenuToggle}
            />
            <Transition.Child
                className="w-[80%] bg-slate-100 h-full relative overflow-y-scroll top-0"
                enter="transition ease duration-500 transform"
                enterFrom="opacity-0 translate-x-[180%]"
                enterTo="opacity-100 translate-x-[24%]"
                leave="transition ease duration-300 transform"
                leaveFrom="opacity-100 translate-x-[24%]"
                leaveTo="opacity-0 translate-x-[180%]"
                onClick={e => e.stopPropagation()}
            >
                <section className="bg-sky-200 w-full h-28 relative flex justify-end items-start flex-col p-3 px-5" >
                    <button onClick={handleToggleSidebar} className="absolute font-medium top-2 right-3" >
                        Browse
                        <FontAwesomeIcon icon={faBars} size="lg" className="ml-2" />
                    </button>
                    <p className="text-lg leading-none font-medium" >Hello, <span className="lowercase" >{user.fullName.split(" ")[0]}</span></p>
                    <p className="text-[28px] font-semibold leading-none" >Your Account</p>
                </section>
                <section className="border-b-4 p-5 border-solid border-gray-300 flex flex-col" >
                    <span className="font-semibold text-[24px]  leading-none mb-2" >Your Orders</span>
                    <Link onClick={handleUserMenuToggle} to={"/orders"} className="text-lg my-4" >Track and Manage Your Orders</Link>
                    <Link onClick={handleUserMenuToggle} className="text-lg my-4" >Buy Again</Link>
                    <Link onClick={handleUserMenuToggle} to={ROUTES.RETURNS} className="text-lg my-4" >Returns Center</Link>
                    <Link onClick={handleUserMenuToggle} className="text-lg my-4" >Delivery Speeds & Charges</Link>
                    <Link onClick={handleUserMenuToggle} to={ROUTES.CUSTOMER_SERVICE} className="text-lg my-4" >Customer Service</Link>
                </section>
                <section className="border-b-4 p-5 border-solid border-gray-300 flex flex-col" >
                    <span className="font-semibold text-[24px]  leading-none mb-2" >Your Account</span>
                    <Link className="text-lg my-4" >Lists</Link>
                    <Link className="text-lg my-4" >Recommendations</Link>
                    <Link className="text-lg my-4" >Browsing History</Link>
                </section>
                <section className="border-b-4 p-5 border-solid border-gray-300 flex flex-col" >
                    <span className="font-semibold text-[24px]  leading-none mb-2" >Settings</span>
                    <Link className="text-lg my-4" >
                        <FontAwesomeIcon icon={faGlobe} size="lg" className="mr-2" />
                        English
                    </Link>
                    <button className="text-lg my-4 text-left" onClick={handleLogout} >Sign Out</button>
                </section>
            </Transition.Child>
            <button className="absolute top-1 right-[82%]" >
                <FontAwesomeIcon onClick={handleUserMenuToggle} icon={faXmark} size="2xl" className="text-white" />
            </button>
        </Transition>
    );
}