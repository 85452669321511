import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom";
import * as ROUTES from "../constant/routes";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { loginUserAsync } from "../redux/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateErr } from "../redux/errSlice";
import { addToCartAsync } from "../redux/cartSlice";
import validateInput from "../helpers/validateInput";
import CustomLoader from "../components/CustomLoader";

import Varyns from "../assets/logos/varyns-logo-big.svg";



export default function LogIn() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cart = useSelector(state => state.cart);
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);

    const onSubmit = async data => {
        try {
            setLoading(true);
            dispatch(loginUserAsync({ ...data }))
                .then((data) => {
                    setLoading(false);
                    if (data.error) {
                        dispatch(updateErr(data.error.message));
                    } else {
                        const { _id, token } = data.payload.user;
                        if (cart.length > 0) {
                            const productArr = cart.map(({ productId, quantity }) => ({ productId, quantity }))
                            dispatch(addToCartAsync({ userId: _id, productArr, token }));
                        }
                        navigate(-1);
                    }
                })
                .catch(err => console.log(err, "<==== err"));
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <div className="flex justify-center items-center h-screen" >
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-72 " >
                <img src={Varyns} className='mb-8 w-52 my-0 mx-auto' />
                <input {...register("email", { required: true, validate: value => validateInput(value) })} type="text" placeholder="Enter Mobile number" className="h-12 rounded-md bg-slate-200 px-4 mb-3 text-lg font-medium" />
                {errors.email && <span>This field is required</span>}
                <input
                    {...register("password", { required: true })}
                    type="password"
                    placeholder="Enter your password"
                    className="h-12 rounded-md bg-slate-200 px-4  text-lg font-medium"
                />
                {errors.password && <span>This field is required</span>}
                <Link to={ROUTES.FORGOT_PASSWORD} className="text-xs underline ml-[2px] mt-1 font-medium mb-4" >Forgot password</Link>
                <button disabled={loading} className="relative py-2 px-0 bg-slate-400 font-medium rounded-sm text-lg" >
                    Log In
                    {
                        loading && <CustomLoader />
                    }
                </button>
                <small className="text-sm mt-1 font-medium" >Don't have an account? <Link to={ROUTES.REGISTER} className="underline" >Register</Link></small>
            </form>
        </div>
    );
}