import { useDispatch, useSelector } from "react-redux";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import * as ROUTES from "../../constant/routes";
import { updateUser } from "../../redux/userSlice";
import { updateErr } from "../../redux/errSlice";
import useQuery from "../../hooks/use-query";

export default function Address() {

    const [addressSelected, setAddressSelected] = useState(null);

    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const query = useQuery();

    const handleClick = () => {
        navigate(ROUTES.NEW_ADDRESS)
    }

    const handleDeliverToThisAddress = async () => {
        let errorOcc = false;
        for (let i = 0; i < user.address.length; i++) {
            if (user.address[i].default) {
                if (user.address[i]._id !== addressSelected) {
                    const endpoint = "https://varyns.onrender.com/"
                    try {
                        const res = await fetch(endpoint + "user/default-address", {
                            method: "PATCH",
                            headers: {
                                'Content-Type': "application/json",
                                'x-access-token': user.token,
                            },
                            body: JSON.stringify({
                                userId: user._id,
                                addressId: addressSelected
                            })
                        })
                        if (res.ok) {
                            const updatedUser = await res.json();
                            dispatch(updateUser({ address: updatedUser.address }));
                            if (query.get("buynow")) {
                                navigate(ROUTES.DELIVERY + "?buynow=true&order=" + query.get("order"));
                            } else {
                                navigate(ROUTES.DELIVERY)
                            }
                        } else {
                            errorOcc = true;
                            dispatch(updateErr("Something went wrong"))
                        }
                    } catch (err) {
                        dispatch(updateErr(err));
                    }
                }
            }
            if (!errorOcc) {
                if (query.get("buynow")) {
                    navigate(ROUTES.DELIVERY + "?buynow=true&order=" + query.get("order"));
                } else {
                    navigate(ROUTES.DELIVERY)
                }
            }
        }
    }

    const handleChange = (id) => {
        setAddressSelected(id);
    }


    useEffect(() => {
        if (user.address) {
            user.address.forEach(element => {
                element.default && setAddressSelected(element._id);
            });
        }
    }, [user]);

    return (
        <div className="px-4" >
            <h2 className="font-bold text-xl my-2 mb-5" >Select a delivery address</h2>
            {
                user && user.address && user.address.map((item) => (
                    <div key={item._id} className="border border-solid border-slate-400 rounded-lg p-4" >
                        <div className="flex gap-3" >
                            <div className="flex justify-center items-center" >
                                <input onChange={() => handleChange(item._id)} checked={item._id === addressSelected} type="radio" className="w-6 h-6" />
                            </div>
                            <div className="w-4/5" >
                                <p className="font-medium leading-none mb-2" >{item.fullName}</p>
                                <p className="leading-snug" >{`${item.area}, ${item.town}`}</p>
                                <p className="leading-snug" >{item.state} {item.pincode}</p>
                                <p className="leading-snug" >{item.country}</p>
                                <p className="leading-snug" >Phone number: {item.mobileNumber}</p>
                            </div>
                        </div>
                        {
                            addressSelected === item._id && <button disabled={!addressSelected} onClick={handleDeliverToThisAddress} className="cursor-pointer w-full my-3 rounded-lg bg-yellow-300 p-3" >Deliver to this address</button>
                        }
                    </div>
                ))
            }
            <div onClick={handleClick} className="cursor-pointer flex items-center justify-between p-3 px-4 border border-solid border-slate-200 rounded-sm" >
                <p className="font-medium text-sm" >Add a New Address</p>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </div>
    )
}