export default function Loader ({small = true}) {

    const style = small ? "w-10 h-10" : "w-16 h-16";

    return (
        <div className="w-screen h-screen fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center" >
            <div className={style} >
                <span className="rounded-full block animate-spin border-t-[4px] border-solid border-teal-400 w-full h-full" ></span>
            </div>
        </div>
    )
}