import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import { updateErr } from '../../redux/errSlice';
import * as ROUTES from "../../constant/routes";
import { updatePaymentData } from '../../redux/paymentSlice';
import useQuery from '../../hooks/use-query';

export default function Delivery() {

    const [skeleton, setSkeleton] = useState(true);
    const [services, setServices] = useState(null);

    const navigate = useNavigate();
    const endPoint = "https://varyns.onrender.com/"
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const query = useQuery();

    const pincodeServiceability = async () => {
        const defaultAddress = user.address.filter(item => item.default)[0];

        try {
            const response = await fetch(`${endPoint}courier-availability?cod=${1}&delivery_postcode=${defaultAddress.pincode}&weight=${'0.8'}`, {
                type: 'GET',
                headers: {
                    'x-access-token': user.token,
                }
            })
            const data = await response.json();
            setServices(data);
            setSkeleton(false);
            dispatch(updatePaymentData({ estimated_delivery_days: data.estimated_delivery_days }));
        } catch (err) {
            dispatch(updateErr("Something went wrong. Try again later!"));
        }
    }


    const generateDateOfDeliveryTxt = (date) => {
        if (date === 1) {
            return "Today";
        } else if (date === 2) {
            return "Day after Tomorrow";
        } else {
            const currentDate = new Date().getTime();
            return new Date(currentDate + date * 24 * 60 * 60 * 1000).toLocaleDateString();
        }
    }

    const handleClick = () => {
        if (query.get("buynow")) {
            navigate(ROUTES.PAYMENT + "?buynow=true&order=" + query.get("order"));
        } else {
            navigate(ROUTES.PAYMENT)
        }
    }

    useEffect(() => {
        (async () => { await pincodeServiceability() })();
    }, []);


    return (
        <div className="px-4" >
            <h2 className="font-bold text-[21px] my-2 mb-2" >Choose your delivery options</h2>
            <h3 className="font-semibold text-lg ml-1" >Choose your delivery speed</h3>
            {
                skeleton ? <Skeleton width={"100%"} count={2} height="100px" /> : (
                    <div className="flex gap-3 h-32 items-center border border-solid border-gray-300 rounded-sm p-4 my-2" >
                        <input className="w-7 h-7" type="radio" checked={true} />
                        <div className="w-4/5" >
                            <p>
                                {/* <span className='font-semibold' >{generateDateOfDeliveryTxt(services.estimated_delivery_days)} </span> */}
                                <span className='font-semibold' >Guaranted 24 Hour </span>
                                -- FREE Delivery
                            </p>
                        </div>
                    </div>
                )
            }
            <button onClick={handleClick} className="w-full mt-4 rounded-lg bg-[#ffd814] p-3" >Continue</button>
        </div >
    )
}