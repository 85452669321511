import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const endPoint = "https://varyns.onrender.com";

export const registerUserAsync = createAsyncThunk(
    'user/register',
    async (data) => {
        const { fullName, mobileNumber, password, otp } = data;
        try {
            const resOtp = await fetch(endPoint + "/verify-otp", {
                headers: { 'Content-Type': 'application/json' },
                method: "POST",
                body: JSON.stringify({
                    mobileNumber,
                    otp
                })
            })

            const resData = await resOtp.json();

            if (resOtp.ok) {
                const res = await fetch(endPoint + "/register", {
                    headers: { 'Content-Type': 'application/json' },
                    method: "POST",
                    body: JSON.stringify({
                        fullName,
                        mobile: mobileNumber,
                        password
                    })
                })
                const user = await res.json();
                return { user };
            } else {
                throw new Error(resData);
            }
        } catch (err) {
            throw new Error(err);
        }
    }
);

export const sendOtpAsync = createAsyncThunk(
    'user/register/send-otp',
    async ({ mobileNumber }) => {
        try {
            const res = await fetch(endPoint + "/send-otp", {
                headers: { 'Content-Type': 'application/json' },
                method: "POST",
                body: JSON.stringify({
                    mobileNumber,

                })
            })
            return await res.json();

        } catch (err) {
            throw err;
        }
    }
);

export const loginUserAsync = createAsyncThunk(
    'user/login',
    async ({ email, password }) => {
        try {
            const res = await fetch(endPoint + "/login", {
                headers: { 'Content-Type': 'application/json' },
                method: "POST",
                body: JSON.stringify({
                    email,
                    password
                })
            })
            const data = await res.json();
            if (!res.ok) {
                throw data;
            };
            return { user: data };
        } catch (err) {
            throw err;
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    // initialState: {id: 1, firstName: 'Aslam', lastName: 'Idrisi', lastLogin: '' },
    initialState: null,
    reducers: {
        updateUser: (state, action) => {
            const user = {
                ...state,
                ...action.payload
            };
            return user;
        },
        logoutUser: (state, action) => {
            const user = null;
            return user;
        }
    },
    extraReducers: {
        [registerUserAsync.fulfilled]: (state, action) => {
            return action.payload.user
        },
        [registerUserAsync.rejected]: (state, action) => {
            return action.payload.error
        },
        [loginUserAsync.fulfilled]: (state, action) => {
            return action.payload.user
        },
    }
});

export const { updateUser, logoutUser } = userSlice.actions;

export default userSlice.reducer;