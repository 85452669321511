import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserSearchHistory, addUserSearchToHistory, deleteUserSearchFromHistory } from "../assets/index";

export const addSearchToHistoryAsync = createAsyncThunk(
    'searchHistory/add',
    async ({api_address, token}) => {
        try {
            const res = await addUserSearchToHistory(api_address, token);
            return { searches: res.searches.reverse().slice(0, 10) };
        } catch (err) {
            throw err;
        }
    }
);

export const deleteSearchFromHistoryAsync = createAsyncThunk(
    'searchHistory/add',
    async ({api_address, token}) => {
        try {
            const res = await deleteUserSearchFromHistory(api_address, token);
            return { searches: res.searches };
        } catch (err) {
            throw err;
        }
    }
);

export const getSearchHistoryAsync = createAsyncThunk(
    'searchHistory/get',
    async ({api_address, token}) => {
        try {
            const res = await getUserSearchHistory(api_address, token);
            console.log(res.splice(0, 10).reverse(), "sliced <<<========");
            return { searches: res.reverse().slice(0, 10) };
        } catch (err) {
            throw err;
        }
    }
);

export const searchHistorySlice = createSlice({
    name: "searchHistory",
    initialState: null,
    extraReducers: {
        [addSearchToHistoryAsync.fulfilled]: (state, action) => {
            return action.payload.searches;
        },
        [getSearchHistoryAsync.fulfilled]: (state, action) => {
            return action.payload.searches;
        },
    }
});

export const { } = searchHistorySlice.actions;

export default searchHistorySlice.reducer;