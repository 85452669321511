import ReactStars from "react-stars";
import month from "../constant/months";

export default function ReviewContainer({ reviewArr }) {
    const generateReviewedOnDate = (createdAt) => {
        const date = new Date(createdAt);
        return `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`
    };
    return (
        <div className="p-2" >
            {
                reviewArr.map(review => (
                    <div className="mb-7" >
                        <div className="flex items-center" >
                            <div>
                                <img className="w-6 h-6 rounded-full bg-slate-300" src="" alt="" />
                            </div>
                            <p className="uppercase font-medium ml-2" >{review.name}</p>
                        </div>
                        <div className="flex items-center" >
                            <ReactStars
                                count={5}
                                edit={false}
                                value={review.rating}
                                size={20}
                                color2={'#ffd700'}
                            />
                            <p className="text-sm  ml-2 mt-[5px] font-semibold" >Verified Purchase</p>
                        </div>
                        <small className="text-gray-700" >Reviewed in India on {generateReviewedOnDate(review.createdAt)}</small>
                        <p className="leading-tight text-sm font-medium" >{review.description}</p>
                    </div>
                ))
            }
        </div>
    )
}