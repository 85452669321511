import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import errReducer from "./errSlice";
import cartReducer from "./cartSlice";
import catalogueReducer from "./catalogueSlice";
import paymentDataReducer from "./paymentSlice";
import searchHistoryReducer from "./searchHistorySlice";
import orderReducer from "./orderSlice";
import cacheReducer from "./cacheSlice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['err', 'paymentData'],
    // add "catalogue" to make homepage data refresh
}

const rootReducer = combineReducers({
    user: userReducer,
    err: errReducer,
    catalogue: catalogueReducer,
    cart: cartReducer,
    paymentData: paymentDataReducer,
    orders: orderReducer,
    searchHistory: searchHistoryReducer,
    cache: cacheReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)