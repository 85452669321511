import { useEffect, useRef, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faFilter, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { Transition } from "@headlessui/react";



import { getProductsBySlug, scrollToTop, getAddressFromPincode } from "../assets";
import FooterContainer from "../container/footer.container";
import Card from "../components/card";
import Loader from "../components/loader.spinner";
import defaultAddress from "../helpers/defaultAddress";
import AddressSelector from "../container/address.selector.container";
import deliveryText from "../helpers/deliveryText";


export default function Catalogue() {
    const [gridView, setGridView] = useState(false);
    const [cardArr, setCardArr] = useState(null);
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [loading, setLoading] = useState(true);
    const { slug } = useParams();
    const endPoint = "https://varyns.onrender.com";
    const moveToTopRef = useRef();
    const user = useSelector(state => state.user);
    const navigate = useNavigate();

    const [addressSelector, setAddressSelector] = useState({
        pincode: null,
        show: false,
        selected: null,
        pincodeEditor: false,
        pincodeAddress: null,
        deliveryTime: null,
        discount: 0
    });

    const toggleAddressSelector = (e) => {
        e?.stopPropagation();
        setAddressSelector(prevState => ({ ...prevState, show: !prevState.show }))
    }

    const handleBack = () => navigate(-1);

    const selectedAddress = defaultAddress(user ? user.address.length > 0 ? user.address : null : null);
    const { text: renderDeliveryText, pincode } = deliveryText(addressSelector, user, selectedAddress);

    const handleChangePincode = ({ target }) => {
        const { value } = target;
        if (!isNaN(value)) {
            setAddressSelector(prevState => ({ ...prevState, pincode: value }));
        }
    }

    const handleChoosePincode = (e) => {
        setAddressSelector(prevState => ({ ...prevState, pincodeEditor: !prevState.pincodeEditor }));
    }

    const handleSelectAddress = (selected) => {
        if (user) {
            setAddressSelector(prevState => ({ ...prevState, selected, show: false, pincodeAddress: null }));
        }
    };

    const handlePincode = useCallback(async (e) => {
        e.preventDefault();
        if (addressSelector.pincode.length >= 6) {
            const res = await getAddressFromPincode(addressSelector.pincode);
            if (res) {
                setAddressSelector(prevState => ({ ...prevState, pincodeAddress: res, pincode: null, pincodeEditor: false, show: false }));
            }
        }
    }, [addressSelector]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const data = await getProductsBySlug(endPoint + "/catalogue/search?slug=" + slug);
            setLoading(false);
            setCardArr([...data]);
        })();
        scrollToTop(window);
    }, [slug]);

    return (
        <div className="" ref={moveToTopRef} >
            <button onClick={toggleAddressSelector} className="bg-slate-400 h-10 flex items-center px-3 w-full" >
                <FontAwesomeIcon icon={faLocationDot} size="lg" />
                <p className="cursor-pointer font-medium ml-2 text-sm leading-0" >
                    {
                        renderDeliveryText
                    }
                </p>
            </button>
            <div
                className="flex justify-end bg-white border border-slate-300 h-11 flex items-center"
            >
                <div></div>
                <button
                    className="flex justify-evenly items-center w-20 h-full border-l border-solid border-slate-300"
                >
                    <FontAwesomeIcon icon={faFilter} size="lg" />
                    <p className="text-md font-medium" >Filter</p>
                </button>
            </div>

            {/* <button onClick={(handleBack)} className="mx-3 mt-6 font-semibold" >
                <FontAwesomeIcon className="mr-1" icon={faChevronLeft} size="xs" />
                Back
            </button> */}

            <div className={`${gridView ? 'grid' : 'flex'} flex-col gap-8 py-8`} >
                {loading ?
                    <>
                        <Skeleton height={"200px"} />
                        <Skeleton height={"200px"} />
                    </>

                    : cardArr.length > 0 ? (
                        cardArr.reverse().map(card => (
                            <Card {...card} endPoint={endPoint} />
                        ))
                    ) : (
                        <p>No Items Found</p>
                    )
                }
            </div>
            <FooterContainer passedRef={moveToTopRef} />
            <Transition
                show={addressSelector.show}
            // show={false}
            >
                <Transition.Child
                    onClick={toggleAddressSelector}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.4)] w-screen h-screen z-[10]"
                >

                </Transition.Child>
                <Transition.Child
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                    className="fixed bottom-0 left-0 right-0 z-[11]"
                >
                    <AddressSelector handlePincode={handlePincode} pincode={addressSelector.pincode} handleChoosePincode={handleChoosePincode} handleChangePincode={handleChangePincode} handleSelectAddress={handleSelectAddress} pincodeEditor={addressSelector.pincodeEditor} selected={addressSelector.selected} user={user} />
                </Transition.Child>
            </Transition>
        </div>
    )
}