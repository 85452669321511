import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import { getOrder, getProductDetails, scrollToTop } from '../../assets';
import useQuery from '../../hooks/use-query';
import * as ROUTES from '../../constant/routes';
import costCalculator from '../../helpers/costCalculator';


export default function OrderFailed() {

    const [failedOrder, setFailedOrder] = useState(null);

    const user = useSelector(state => state.user);
    const endpoint = "https://varyns.onrender.com";
    const query = useQuery();
    const navigate = useNavigate();

    const calculateAmount = (arr) => {
        let amount = 0;
        arr.forEach(item => {
            amount += item.amount;
        })
        return amount;
    };

    const handleRetry = () => {
        navigate(ROUTES.RETRY_PAYMENT + `?&order=${query.get("order")}`);
    }

    console.log(failedOrder, "failed order first");

    useEffect(() => {
        (async () => {
            const data = await getOrder(endpoint + `/orders?orderId=${query.get("order")}`, user.token);
            const orderData = await getProductDetails(data.map(item => ({ product: item.productId })));
            setFailedOrder(data.map((item, index) => ({ ...item, description: orderData[index].value.description, images: orderData[index].value.images, title: orderData[index].value.title, discount: orderData[index].value.discount })));

            // const [data] = await getOrder(endpoint + `/orders?orderId=${query.get("order")}&productId=${query.get("productId")}`, user.token);
            // const [orderData] = await getProductDetails([{product: data.productId}]);
            // setFailedOrder({...data, description: orderData.value.description, images: orderData.value.images, title: orderData.value.title, discount: orderData.value.discount});
        })();
        scrollToTop(window);
    }, []);

    return (
        <div className="p-3" >
            <section className="rounded-[4px] p-3 my-3 border-2 border-red-700 border-solid" >
                <p className="font-bold text-red-700" >Payment Failed</p>
                <p className=" leading-tight my-1 font-medium" >
                    Your payment for this order could not be
                    completed. If money has been deducted from your card/bank
                    account, it would be refunded in 2-3 business days.
                </p>
                <button onClick={handleRetry} className="bg-gradient-to-b from-[#f1dca7] to-[#e9b74b] my-3 border-2 border-solid border-[#ceb983] rounded-sm bg-orange-200 w-full p-2 text-center font-medium" >Retry Payment</button>
            </section>
            <section>
                <h2 className="font-bold text-[24px] mb-2" >View order details</h2>

                {
                    failedOrder ? (
                        <div className="grid grid-cols-[30%_70%] border-gray-200 rounded-sm p-2 px-4 border-2 border-solid" >
                            <p className="text-gray-700 row-start-1 row-end-2" >Order date</p>
                            <p className="text-gray-700 row-start-2 row-end-3" >Order #</p>
                            <p className="text-gray-700 row-start-3 row-end-4" >Order total</p>

                            <p className="row-start-1 row-end-2 col-start-2 col-end-3" >{failedOrder[0].createdAt.split("T")[0]}</p>
                            <p className="row-start-2 row-end-3 col-start-2 col-end-3" >{failedOrder[0].orderId}</p>
                            <p className="row-start-3 row-end-4 col-start-2 col-end-3" >{calculateAmount(failedOrder)}</p>
                        </div>
                    ) : (
                        <Skeleton enableAnimation={true} count={1} height={150} />
                    )
                }
            </section >
            <section className="my-2" >
                <h2 className="font-bold text-lg mb-2" >Shipment details</h2>
                <div className="rounded-[4px] p-3 my-3 border-2 border-red-700 border-solid" >
                    <p className="font-bold text-red-700" >Payment Failed</p>
                    <p className="text-gray-600 leading-tight my-1 " >
                        Your payment for this order could not be
                        completed. If money has been deducted from your card/bank
                        account, it would be refunded in 2-3 business days.
                    </p>
                    {
                        failedOrder ? (
                            failedOrder.map(item => (
                                <div className="p-3 flex gap-x-2" >

                                    <img className="block w-1/3 h-20 w-20" src={item.images[0]} alt={item.title} />
                                    <div>
                                        <p className="font-bold leading-tight h-5 overflow-hidden ellipsis" >{item.description}</p>
                                        <div className="flex items-center" >
                                            <p className="text-red-600 font-bold" >{item.amount}.00</p>
                                        </div>
                                        <div className="flex items-center" >
                                            <p className="text-red-600" >Quantity: {item.quantity}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Skeleton enableAnimation={true} count={1} height={120} />
                        )
                    }
                </div>
            </section>
        </div >
    )
}