import { sendOtpAsync } from "../redux/userSlice";
import * as ROUTES from "../constant/routes";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useCallback, useRef, useState } from "react";
import Slider from "react-slick";
import { updateErr } from "../redux/errSlice";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { verifyOtp, resetPassword } from "../assets";
import CustomLoader from "../components/CustomLoader";

export default function ForgotPassword() {

    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState({ new: "", confirm: "" });

    const [loaders, setLoaders] = useState({
        confirm: false,
        verify: false,
        reset: false,
    })

    const endPoint = "https://varyns.onrender.com";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sliderRef = useRef(null);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setLoaders(prevState => ({ ...prevState, confirm: true }));
        dispatch(sendOtpAsync({ mobileNumber: phoneNumber })).then((res) => {
            sliderRef.current.slickNext();
            setLoaders(prevState => ({ ...prevState, confirm: false }));
        });
    }, [sliderRef, phoneNumber]);

    const handleOtpverification = async (e) => {
        e.preventDefault();
        setLoaders(prevState => ({ ...prevState, verify: true }));
        try {
            const res = await verifyOtp(phoneNumber, otp);
            if (res) {
                sliderRef.current.slickNext();
            }
            setLoaders(prevState => ({ ...prevState, verify: false }));
        } catch (err) {
            dispatch(updateErr(err.message));
        }
    }

    const handlePassword = (e) => {
        const { name, value } = e.target;
        setPassword(prevState => ({ ...prevState, [name]: value }));
    }

    const disabled = otp.length !== 6;

    const handleChange = ({ target }) => {
        const { value } = target;
        setOtp(value);
    }

    const handleResetPassword = useCallback(async (e) => {
        e.preventDefault();
        setLoaders(prevState => ({ ...prevState, reset: true }));
        try {
            if (password.new && password.confirm) {
                const res = await resetPassword(endPoint + "/reset-password", { password: password.new, mobile: phoneNumber });
                if (res) {
                    sliderRef.current.slickNext();
                    setTimeout(() => navigate(ROUTES.LOGIN), 5000);
                }
            } else {
                dispatch(updateErr("Enter your new password"));
            }
            setLoaders(prevState => ({ ...prevState, reset: false }));
        } catch (err) {
            dispatch(updateErr(err.message));
        }
    }, [sliderRef, password, phoneNumber]);

    const settings = {
        dots: false,
        infinite: false,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        arrows: false,
        draggable: false,
    };

    const passwordCheck = !password.new || !password.confirm || (password.new !== password.confirm);

    return (
        <div className="min-h-screen flex items-center justify-center flex-col" >
            <h1 className="text-2xl mb-3" >Reset Password</h1>

            <Slider ref={sliderRef} className=" w-52 2xs:w-72 " {...settings} >
                <form className="text-center  bg-slate-200 p-4 rounded" onSubmit={handleSubmit} >
                    <div className="relative" >
                        <input
                            className="p-2 pl-10 outline-0 w-56"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            type="tel" placeholder="Enter your Phone Number"
                            required
                            pattern="^((\+91)?|91)?[789][0-9]{9}"
                        />
                        <p className="absolute top-2 left-5" >+91</p>
                    </div>
                    <button className="relative py-2 text-white px-6 bg-slate-400 mt-4" >
                        Confirm
                        {
                            loaders.confirm && <CustomLoader />
                        }
                    </button>
                </form>
                <div className='p-4 bg-slate-200 rounded' >
                    <h1 className="text-2xl font-bold my-3" >Verify mobile number</h1>
                    <h3 className="font-semibold mb-3" >IN +91 {phoneNumber} (<span onClick={() => sliderRef.current.slickPrev()} className="text-sky-600 cursor-pointer" >Change</span>)</h3>
                    <input placeholder="Enter OTP" className="outline-0 h-12 w-full p-2 rounded-sm block my-3 bg-slate-300" onChange={handleChange} value={otp} />
                    <button disabled={disabled} className={`relative w-full h-11 rounded-sm ${disabled ? "cursor-not-allowed bg-sky-200" : "cursor-pointer bg-sky-300"}`} onClick={handleOtpverification} >
                        Verify
                        {
                            loaders.verify && <CustomLoader />
                        }
                    </button>
                    <small className='leading-tight block mt-2' >By creating an account or logging in, you agree to Va's <Link to={ROUTES.CONDITION_OF_USE} className="text-sky-600" >Conditions of Use</Link> and <Link to={ROUTES.PRIVACY_POLICY} className="text-sky-600" >Privacy Policy</Link>.</small>
                    <button className='w-full bg-slate-300 h-10 px-4 flex justify-between items-center mt-2 text-sm font-semibold' >
                        Resend OTP
                        <span>&#62;</span>
                    </button>
                </div>
                <div className="w-52 bg-slate-200 p-4 rounded" >
                    <form onSubmit={handleResetPassword} className="flex justify-center flex-col" >
                        <input
                            className="p-2"
                            value={password.new}
                            name="new"
                            onChange={handlePassword}
                            placeholder="Enter New Password"
                            required
                            type="password"
                            // pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$"
                        />
                        <input
                            className="p-2 my-3"
                            value={password.confirm}
                            name="confirm"
                            onChange={handlePassword}
                            placeholder="Confirm Password"
                            required
                            type="password"
                            // pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$"
                        />

                        {passwordCheck && password.new.length > 0 && password.confirm.length > 0 && (<p className="text-xs text-red-500" >
                            Password does not match
                        </p>)}
                        <button disabled={passwordCheck} className='relative w-full bg-slate-300 h-10 px-4 mt-2 text-sm font-semibold' type="submit" >
                            Reset Password
                            {
                                loaders.reset && <CustomLoader />
                            }
                        </button>
                        <ul className="list-disc px-4 text-xs mt-4" >
                            <li>At least one upper case letter</li>
                            <li>At least one lower case letter</li>
                            <li>At least one digit</li>
                            <li>At least one special character</li>
                            <li>At least should be of minimum 8 digit long</li>
                        </ul>
                    </form>
                </div>
                <div className="w-52 bg-slate-200 p-4 rounded" >
                    <p>Your Password has been successfully Changed! You will be redirected to Login now.</p>
                </div>
            </Slider>
        </div>
    );
}