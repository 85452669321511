import { useEffect, useRef } from "react";
import FooterContainer from "../container/footer.container";
import { scrollToTop } from "../assets";

export default function AboutUs() {

    const moveToTopRef = useRef();


    useEffect(() => {
        scrollToTop(window)
    }, []);

    return (
        <div ref={moveToTopRef} >
            <div className="p-6" >
                <h1 className="text-[35px] font-medium" >Varyns About Us</h1>
                <section className="my-6" >
                    <p className="text-xl font-medium my-3" >Our Story</p>
                    <p className="leading-7 px-4 my-3 text-justify" >
                        At Varyns, we're more than just an e-commerce store; we're a reflection of India's
                        rich culture, diversity, and craftsmanship. Our journey began with a vision to create
                        an online shopping experience that celebrates the uniqueness of India. We take pride
                        in offering you a platform that brings together the finest products from every corner
                        of this incredible country.
                    </p>
                </section>

                <section>
                    <h2 className="text-xl font-medium my-3" >Our Mission</h2>
                    <p className="leading-7 px-4 my-3 text-justify" >
                        Our mission is to connect customers with authentic, high-quality products,
                        while empowering artisans, craftsmen, and small businesses across India.
                        We believe in the power of e-commerce to bridge the gap between urban consumers
                        and rural artisans, thereby preserving our country's heritage and supporting
                        local economies.
                    </p>
                </section>

                <section>
                    <h2 className="text-xl font-medium my-3" >What We Offer</h2>
                    <p className="leading-7 px-4 my-3 text-justify" >
                        <span className="text-lg text-red-700" >Quality:</span> We are committed to providing you with products that meet the highest standards of quality and craftsmanship. We source our merchandise directly from skilled artisans and verified suppliers.</p>
                    <p className="leading-7 px-4 my-3 text-justify" >
                        <span className="text-lg text-red-700" >Diversity:</span> Explore a vast array of products that encompass the diverse cultural tapestry of India. From handwoven textiles to intricately crafted jewelry, we have something for every taste and occasion.
                    </p>
                    <p className="leading-7 px-4 my-3 text-justify" >
                        <span className="text-lg text-red-700" >Value:</span> We strive to offer competitive prices and exclusive deals to ensure that you get the best value for your money.
                    </p>
                </section>
                <section>
                    <h2 className="text-xl font-medium my-3" >Why Choose Us?</h2>
                    <p className="leading-7 px-4 my-3" >
                        <span className="text-lg text-red-700 text-justify" >Authenticity:</span> We guarantee the authenticity of every product you purchase from us. Our sourcing process involves extensive quality checks and working closely with artisans to ensure genuine craftsmanship.
                    </p>
                    <p className="leading-7 px-4 my-3" >
                        <span className="text-lg text-red-700" >Customer-Centric:</span> Your satisfaction is our top priority. We provide excellent customer support, hassle-free returns, and secure payment options for your peace of mind.
                    </p>
                    <p className="leading-7 px-4 my-3" >
                        <span className="text-lg text-red-700 text-justify" >Supporting Artisans:</span> By shopping with us, you're not just getting a product; you're supporting the livelihoods of artisans and small businesses who create these beautiful items.
                    </p>
                </section>
                <section>
                    <h2 className="text-xl font-medium my-3" >Our Team</h2>
                    <p className="leading-7 px-4 my-3 text-justify" >
                        Behind the scenes, there's a dedicated team at Varyns who work tirelessly to bring you the best of India's offerings. Our employees share a passion for Indian culture and craftsmanship, and they are committed to making your shopping experience exceptional.
                    </p>
                </section>
                <section>
                    <h2 className="text-xl font-medium my-3" >Get in Touch</h2>
                    <p className="leading-7 px-4 my-3 text-justify" >
                        We're always here to assist you, whether you have a question, a suggestion, or just want to say hello. Feel free to contact us through our customer support or connect with us on social media.
                    </p>
                    <p className="leading-7 px-4 my-3 text-justify" >
                        Thank you for choosing Varyns for your shopping needs. We look forward to serving you and sharing the beauty of India with the world.
                    </p>
                    <div className="mx-4 my-6" >
                        <p className="font-medium" >Sincerely,</p>
                        <div className="flex mt-2" >
                            <p className="mr-4 text-lg leading-snug font-medium" >
                                Pankaj Pandey<br />
                                <span className="text-[15px] font-normal" >CoFounder, Varyns</span>
                            </p>
{/*                             <p className="text-lg font-medium leading-snug" >
                                Aslam Idrisi<br />
                                <span className="text-[15px] font-normal" >CoFounder, Varyns</span>
                            </p> */}
                        </div>
                    </div>
                </section>
            </div>
            <FooterContainer passedRef={moveToTopRef} />
        </div>
    );
}
