import { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faCircleInfo, faCircleCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from 'react-hook-form';

import * as ROUTES from '../../constant/routes';
import { cardData } from '../../constant/data';
import { updatePaymentData } from '../../redux/paymentSlice';
import useQuery from '../../hooks/use-query';
import { updateErr } from '../../redux/errSlice';

export default function Pay() {

    const [verified, setVerified] = useState(false);
    const [cardForm, setCardForm] = useState(false);
    const [error, setError] = useState({
        cardErrors: null
    })
    const [payment, setPayment] = useState({
        method: 'netbanking',

        // method specific fields
    });
    const [netbankingList, setNetbankingList] = useState(null);
    const [cardDetailsPresent, setCardDetailsPresent] = useState(false);

    // const paymentData = useSelector(state => state.paymentData);

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const dispatch = useDispatch();
    const query = useQuery();
    const navigate = useNavigate();

    const user = useSelector(state => state.user);
    const razorpay = useRef(null);
    const vpaRef = useRef(null);

    const handleChange = ({ target }) => {
        const { name } = target;
        setPayment(prevState => ({ ...prevState, method: name }));
        setVerified(false);
    }

    const handleVerifyVpa = async () => {
        if (payment.vpa) {
            try {
                const response = await razorpay.current.verifyVpa(payment.vpa);
                setVerified(true);
            } catch (err) {
                dispatch(updateErr("Invalid UPI Id"));
                setPayment(prevState => ({ ...prevState, vpa: "" }));
                vpaRef.current.text = "";
                vpaRef.current.focus();
            }
        } else {
            alert("Enter Vpa");
        }
    }

    const handleClick = (e) => {
        e.preventDefault();
        setCardForm(prevState => !prevState);
    }

    const onSubmit = (data) => {
        setCardDetailsPresent(true);
        setCardForm(false);
        setPayment(prevState => ({ ...prevState, ...data }));
    };

    const inputHandler = ({ target }) => {
        const { name, value } = target;
        setPayment(prevState => ({ ...prevState, [name]: value }));
        setVerified(false);
    }

    const handleCvv = (e) => {
        setPayment(prevState => ({ ...prevState, cvv: e.target.value }))
    }

    const handleDisable = useCallback(() => {
        if (payment.method === 'upi') {
            if (!payment.vpa || !verified) {
                return true;
            }
        } else if (payment.method === 'card') {
            if (!payment.name || !payment.number || !payment.expiry_month || !payment.expiry_year || !payment.cvv) {
                return true;
            }
        } else {
            if (payment.method === "netbanking") {
                if (!payment.bank) {
                    return true;
                }
            }
        }
        return false;
    }, [payment, verified]);

    const handleContinue = () => {
        dispatch(updatePaymentData({
            ...payment,
            email: user.email ? user.email : "aslamidrs@gmail.com",
            contact: user.mobile,
            notes: {
                address: user.address.filter(item => item.default)[0] || user.address[0]
            }
        }));
        // navigate(ROUTES.PLACE_ORDER);
        if (query.get("buynow")) {
            navigate(ROUTES.PLACE_ORDER + "?buynow=true&order=" + query.get("order"));
        } else {
            navigate(ROUTES.PLACE_ORDER);

        }
    }



    useEffect(() => {
        const Razorpay = window.Razorpay;
        razorpay.current = new Razorpay({
            key: 'rzp_test_NlVykh9LKJwoJI',
            image: 'https://i.imgur.com/n5tjHFD.jpg'
        })
        razorpay.current.once('ready', function (response) {
            setNetbankingList(Object.keys(response.methods.netbanking).map(item => ({ value: item, label: response.methods.netbanking[item] })));
        })
    }, []);

    useEffect(() => {
        setPayment(prevState => ({ method: prevState.method, cvv: '', name: '', number: '', expiry_month: '', expiry_year: '' }));
        if (payment.method !== "card") {
            setCardDetailsPresent(false);
            reset({
                name: '',
                number: '',
                expiry_month: '01',
                expiry_year: '2023',
                cvv: ''
            })
        }
    }, [payment.method]);

    return cardForm ? (
        <div>
            <div>
                <button onClick={handleClick} ><FontAwesomeIcon icon={faChevronLeft} className='text-gray-500 m-2 mx-3' /></button>
            </div>
            {
                error.cardErrors && (
                    <div className="border-2 border-l-8 border-red-600 p-2 border-solid rounded-lg m-3" >
                        <p className='text-red-600 font-semibold' >There was a problem.</p>
                        <ul className='px-2' >
                            {error.cardErrors.map((item, index) => <li key={index + item} className="text-sm ml-3 list-disc" >{item}</li>)}
                        </ul>
                    </div>
                )
            }
            <form onSubmit={handleSubmit(onSubmit)} className='m-3' >
                <p className='font-semibold' >Enter card details</p>
                <p className='my-1 text-sm mb-3' >
                    <FontAwesomeIcon icon={faCircleInfo} className='text-teal-500 mr-2' />
                    Please ensure your card is enabled for online transactions.
                </p>
                <input {...register("name", { required: true })} placeholder='Name on card' className='w-full border border-solid border-gray-300 rounded-md p-2 h-14 outline-0' />
                {
                    errors.name && <span className='text-red-600 text-sm my-3 font-medium' >This field is required</span>
                }

                <input {...register("number", { required: true })} placeholder='Card number' className='mb-2 w-full border border-solid border-gray-300 rounded-md p-2 h-14 outline-0' />
                {
                    errors.number && <p className='text-red-600 text-sm mb-2 font-medium' >This field is required</p>
                }
                <label className='font-medium text-left w-full' >Expiry date</label>

                <div className='flex w-full gap-x-4' >
                    <div className='w-[49%] my-3' >
                        <select
                            required
                            className="w-full h-10 border border-solid border-gray-300 p-2 shadow-lg cursor-pointer"
                            {...register("expiry_month", { required: true })}
                        >
                            {cardData.months.map(item => <option value={item.value} >{item.label}</option>)}
                        </select>
                        {
                            errors.expiry_month && <span className='text-red-600 text-sm mb-2 font-medium' >This field is required</span>
                        }
                    </div>
                    <div className='w-[49%] my-3' >
                        {/* <Select
                            className=" shadow-lg"
                            options={cardData.year}
                            {...register("expiry_year", { required: true })}
                        /> */}
                        <select required className="w-full h-10 border border-solid border-gray-300 p-2 shadow-lg cursor-pointer" {...register("expiry_year", { required: true })} >
                            {cardData.year.map(item => <option value={item.value} >{item.label}</option>)}
                        </select>
                        {
                            errors.expiry_year && <span className='text-red-600 text-sm font-medium' >This field is required</span>
                        }
                    </div>
                </div>

                <button className='w-full p-3 bg-yellow-300 rounded-lg' >Enter card details</button>
            </form>
        </div>
    ) : (
        <div className="px-4" >
            <h2 className="font-bold text-[22px] my-2 mb-2" >Select a payment </h2>
            <button onClick={handleContinue} disabled={handleDisable()} className="w-full mt-4 rounded-lg bg-[#ffd814] p-3" >Continue</button>

            <div className='my-2' >
                <strong className='uppercase text-[11px] text-gray-600' >currently added card{"(s)"}</strong>
                <div className='flex gap-x-2 my-2 items-center' >
                    <FontAwesomeIcon className='text-cyan-600 -mt-[0.5px]' size='1x' icon={faCircleInfo} />
                    <p className='leading-tight text-sm' >
                        Please ensure your card is enabled for online transactions.
                    </p>
                </div>
                <div>
                    <small className='block my-2 text-gray-600' >We will not store your card information</small>
                    {/* {
                    savedCards.map(card => (
                        <div
                            className='h-36 mb-2 bg-orange-50 w-full border border-solid border-orange-200 rounded-lg'
                        >

                        </div>
                    ))
                } */}
                </div>
                <div className='my-3' >
                    <p className='mb-3 font-bold text-xs uppercase text-gray-600' >more ways to pay</p>
                    {/* <div className='flex flex-col border-gray-300 border-2 border-solid rounded-md p-5 min-h-20' >
                        <div className='flex items-center gap-x-3' >
                            <input onChange={handleChange} name='upi' checked={payment.method === "upi"} id="upi" type='radio' className='w-6 h-6' />
                            <label for="upi" className='font-semibold leading-none' >
                                UPI
                            </label>
                        </div>
                        {
                            payment.method === "upi" && (
                                <div className='mt-2' >
                                    <div className="grid grid-cols-[67%_30%] grid-rows-[35%_65%] h-20 py-1 gap-x-3" >
                                        <label for="upi-input" className='text-sm text-gray-700' >Please enter your UPI ID</label>
                                        <input ref={vpaRef} name="vpa" onChange={inputHandler} className='bg-white rounded-lg border-solid border border-black row-start-2 px-2' id="upi-input" type="text" placeholder='Ex: MobileNumber@upi' />
                                        <button disabled={verified} onClick={handleVerifyVpa} className='row-start-2 rounded-lg col-start-2 bg-yellow-400' >Verify</button>
                                    </div>
                                    {verified && <>
                                        <p className="text-[#007600] text-sm" >
                                            <FontAwesomeIcon className='mr-2 text-[#007600]' icon={faCircleCheck} />
                                            Verified!</p>
                                        <p className="text-[#007600] text-sm" >Please press continue to complete the purchase.</p>
                                    </>}
                                </div>
                            )
                        }
                    </div>
                    <div className='border-gray-300 border-2 border-solid rounded-md  p-5 flex flex-col' >
                        {
                            cardDetailsPresent ? (
                                <div className='min-h-20 flex items-center gap-3 ' >
                                    <input name='card' checked={payment.method === 'card'} id="cards" type='radio' className='w-6 h-6' />
                                    <div>
                                        <p className='text-sm font-medium' >****{payment.number.slice(payment.number.length - 5, payment.number.length - 1)}</p>
                                        <p className='text-sm' >{payment.name}</p>
                                        <input value={payment.cvv} onChange={handleCvv} className='p-3 rounded-sm h-10 w-28 outline-0 border-2 text-sm border-solid border-gray-300' placeholder='Enter CVV' />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className='min-h-20 flex items-center gap-3 ' >
                                        <input onChange={handleChange} name='card' checked={payment.method === 'card'} id="cards" type='radio' className='w-6 h-6' />
                                        <div>
                                            <label for="cards" className='font-semibold leading-none' >
                                                Pay with Debit/Credit/ATM Cards
                                            </label>
                                            <p className='text-sm' >You can save your cards as per new RBI guidelines</p>
                                        </div>
                                    </div>
                                    {
                                        payment.method === 'card' && (
                                            <button className='text-teal-600 mx-8 text-left py-1 my-2 border-0 border-b-2 border-solid border-b-gray-400' onClick={handleClick} >Enter card details</button>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className='border-gray-300 border-2 border-solid rounded-md min-h-20 p-5 flex flex-col' >
                        <div className='flex items-center gap-x-3 ' >
                            <input onChange={handleChange} name='netbanking' checked={payment.method === 'netbanking'} id="net-banking" type='radio' className='w-6 h-6' />
                            <label for="net-banking" className='font-semibold leading-none' >
                                Net Banking
                            </label>
                        </div>
                        {
                            payment.method === 'netbanking' && (
                                <Select
                                    className="my-3 shadow-lg"
                                    options={netbankingList}
                                    value={payment.bank ? payment.bank : 'AIRP'}
                                    placeholder="Choose Bank"
                                    onChange={(value) => {
                                        setPayment(prevState => ({ ...prevState, 'bank': value }))
                                    }}
                                />
                            )
                        }
                    </div> */}
                    <div className='flex items-center border-gray-300 border-2 border-solid rounded-md min-h-20 p-5 gap-3 mt-2' >
                        <input onChange={handleChange} name='pod' checked={payment.method === 'pod'} id="pod" type='radio' className='w-6 h-6' />
                        <div>
                            <label for="pod" className='font-semibold leading-none' >
                                Cash on Delivery/Pay on Delivery
                            </label>
                            <p className='text-sm' >
                                Pay using Cash and UPI.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <button onClick={handleContinue} disabled={handleDisable()} className="w-full my-4 rounded-lg bg-[#ffd814] p-3" >Continue</button>
        </div>
    )
}