// import { createSlice } from '@reduxjs/toolkit';

// const { ENV } = process.env;

// const endPoint = ENV ? ENV : "http://localhost:4001";


// export const catalogueSlice = createSlice({
//     name: 'catalogue',
//     // initialState: {id: 1, firstName: 'Aslam', lastName: 'Idrisi', lastLogin: '' },
//     initialState: {
//         userHistory: null,
//         heroSlides: null,
//         buyAgain: null,
//         todaysDeals: null
//     },
//     reducers: {
//        updateCatalogue: (state, action) => {
//         const data = {...state, ...action.payload}
//         return data
//        }
//     },

// });

// export const { updateCatalogue } = catalogueSlice.actions;

// export default catalogueSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

export const catalogueSlice = createSlice({
    name: 'catalogue',
    // initialState: {id: 1, firstName: 'Aslam', lastName: 'Idrisi', lastLogin: '' },
    initialState: {
        userHistory: null,
        heroSlides: null,
        buyAgain: null,
        homepage: []
    },
    reducers: {
        updateCatalogue: (state, action) => {
            const catalogue = { ...state, ...action.payload };
            return catalogue;
        },
        updateCatalogueHomepage: (state, action) => {
            // const updated = state.homepage.map(item => {
            //     return item.name === action.payload.name ? action.payload : item;
            // })

            const getIndex = (arr, name) => {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].name === name) {
                        return i;
                    }
                }

                return null
            }

            let nameArr = state.homepage.map(item => item.name);
            const doesExist = nameArr.includes(action.payload.name);

            if (doesExist) {
                const old = state.homepage[getIndex(state.homepage, action.payload.name)];
                if (old.arr.length !== action.payload.arr.length)
                    state.homepage[getIndex(state.homepage, action.payload.name)] = action.payload;
            } else {
                state.homepage.push(action.payload);
            }

            // const catalogue = { ...state };
            // return catalogue;
        }
    },
});

export const { updateCatalogue, updateCatalogueHomepage } = catalogueSlice.actions;

export default catalogueSlice.reducer;