import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useNavigate, useNavigation } from "react-router-dom";
import { useState, useMemo } from "react";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { ColorRing } from "react-loader-spinner";


import * as ROUTES from "../../constant/routes";
import states from "../../constant/states";
import { updateErr } from "../../redux/errSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/userSlice";


export default function NewAddress() {

    const navigate = useNavigate();

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const options = useMemo(() => countryList().getData(), []);
    const [loader, setLoader] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const changeHandler = value => {
        setCountry(value);
    }

    const changeState = value => {
        setState(value);
    }

    const onSubmit = async (data) => {
        setLoader(true);
        const endpoint = "https://varyns.onrender.com/"
        try {
            const res = await fetch(endpoint + "user", {
                method: "PATCH",
                headers: {
                    'Content-Type': "application/json",
                    'x-access-token': user.token,
                },
                body: JSON.stringify({
                    userId: user._id,
                    address: { ...data, country: country.label, state: state.value }
                })
            })
            const updatedUser = await res.json();
            dispatch(updateUser({address: updatedUser.address}));
            navigate(-1);
        } catch (err) {
            setLoader(false);
            dispatch(updateErr(err));
        }
        
    }
    const handleClick = () => navigate(-1);
    return (
        <div className=" border-t-4 border-solid border-gray-200" >
            <button onClick={handleClick} className="mx-3 font-semibold my-2" >
                <FontAwesomeIcon className="mr-1" icon={faChevronLeft} size="xs" />
                Back
            </button>
            <form className="flex flex-col p-2 py-5 border border-solid border-slate-200" onSubmit={handleSubmit(onSubmit)} >
                <p className="font-bold" >Add a new address</p>

                {/* select country */}
                <Select
                    className="my-3 shadow-lg"
                    options={options}
                    value={country}
                    onChange={changeHandler}
                />

                {/* full name */}
                <label className="mb-1 font-semibold ml-1" for="fullName" >Full name{`(First and Last name)`}</label>
                <input className="mb-3 p-[11px] px-2 border border-solid border-slate-500 rounded-sm" id="fullName" {...register("fullName", { required: true })} />
                {/* errors will return when field validation fails  */}
                {errors.fullName && <span>This field is required</span>}

                {/* mobile number */}
                <label className="mb-1 font-semibold ml-1" for="mobile" >Mobile number</label>
                <input className="mb-3 p-[11px] px-2 border border-solid border-slate-500 rounded-sm" id="mobile" {...register("mobileNumber", { required: true })} />
                {/* errors will return when field validation fails  */}
                {errors.mobileNumber && <span>This field is required</span>}

                {/* flat */}
                <label className="mb-1 font-semibold ml-1" for="flat" >Flat, House no., Building, Company, Apartment</label>
                <input className="mb-3 p-[11px] px-2 border border-solid border-slate-500 rounded-sm" id="flat" {...register("apartment", { required: true })} />
                {/* errors will return when field validation fails  */}
                {errors.apartment && <span>This field is required</span>}

                {/* area */}
                <label className="mb-1 font-semibold ml-1" for="area" >Area, Street, Sector, Village</label>
                <input className="mb-3 p-[11px] px-2 border border-solid border-slate-500 rounded-sm" id="area" {...register("area", { required: true })} />
                {/* errors will return when field validation fails  */}
                {errors.area && <span>This field is required</span>}

                {/* landmark */}
                <label className="mb-1 font-semibold ml-1" for="landmark" >Landmark</label>
                <input className="mb-3 p-[11px] px-2 border border-solid border-slate-500 rounded-sm" id="landmark" placeholder="E.g. near max hospital"  {...register("landmark", { required: true })} />
                {/* errors will return when field validation fails  */}
                {errors.landmark && <span>This field is required</span>}

                {/* pincode */}
                <label className="mb-1 font-semibold ml-1" for="pincode" >Pincode</label>
                <input className="mb-3 p-[11px] px-2 border border-solid border-slate-500 rounded-sm" id="pincode" placeholder="6 digits PIN code"  {...register("pincode", { required: true })} />
                {/* errors will return when field validation fails  */}
                {errors.pincode && <span>This field is required</span>}

                {/* town */}
                <label className="mb-1 font-semibold ml-1" for="town" >Town/City</label>
                <input className="mb-3 p-[11px] px-2 border border-solid border-slate-500 rounded-sm" id="town"  {...register("town", { required: true })} />
                {/* errors will return when field validation fails  */}
                {errors.town && <span>This field is required</span>}

                <label className="mb-1 font-semibold ml-1" for="state" >State</label>
                <Select
                    id="state"
                    className="mb-5"
                    options={states}
                    value={state}
                    onChange={changeState}
                />

                <div className="flex items-center ml-1 mb-6" >
                    <input {...register("default")} id="default" type="checkbox" className="w-5 h-5" />
                    <label for="default" className="ml-3 leading-0" >Make this my default address</label>
                </div>
                <button disabled={loader} type="submit" className="relative rounded-lg bg-yellow-300 p-3" >
                    Use this address
                    {
                        loader && (
                            <span className="rounded-md absolute bg-[rgba(0,0,0,0.6)] top-0 left-0 w-full h-full flex justify-center items-center" >
                                <ColorRing
                                    visible={true}
                                    height="32"
                                    width="32"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                />
                            </span>
                        )
                    }
                </button>
            </form>
        </div>
    )
}