export default function AddressCard({address, selected, handleSelectAddress}){
    
    return (
        <div onClick={() => handleSelectAddress(address)} className={`cursor-pointer my-2 border-solid ${selected ? "border-2 border-teal-700" : "border border-gray-300" } p-3 w-28 h-24 shrink-0 rounded`} >
            <p className="text-[10px] whitespace-nowrap font-semibold leading-tight mb-1" >{address.fullName}</p>
            <p className="text-[10px] text-gray-600 leading-tight mb-1" >{address.apartment},</p>
            <p className="text-gray-600 text-[10px] leading-none" >{address.area},</p>
            {address.default && <small className="text-[10px] text-gray-600 leading-none trekking-none whitespace-nowrap" >Default address</small>}
        </div>
    );
}