import { useEffect, useRef } from "react";
import { scrollToTop } from "../assets";
import FooterContainer from "../container/footer.container";

export default function ReturnPolicy() {

    const moveToTopRef = useRef();

    useEffect(() => {
        scrollToTop(window);
    }, []);

    return (
        <div ref={moveToTopRef} >
            <div className="p-6" >
                <h1 className="text-[35px] font-medium" >Return Policy</h1>
                <p className="leading-7 px-4 my-3" >
                    <span className="text-lg text-red-700" >Last Updated:</span>October 16th 2023</p>
                <p className="leading-7 px-4 my-3 text-justify" >
                    Varyns ("we," "our," or "us") is committed to customer satisfaction. We strive to provide high-quality products and services. If, for any reason, you are not satisfied with your purchase, we offer a straightforward return and refund policy.
                </p>
                <ol className="list-decimal px-4" >
                    <li>
                        <p className="text-xl font-medium my-3" > Returns and Refunds</p>
                        <ul className="list-disc px-6 " >
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    <span className="text-lg text-red-700" >Eligibility:</span> We accept returns on most products within 7 days from the date of delivery. To be eligible for a return, your item must be unused, in the same condition that you received it, and in the original packaging.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify " >
                                    <span className="text-lg text-red-700" >Non-Returnable Items:</span> Some products are not eligible for returns, including but not limited to perishable goods, customized products, and intimate or sanitary goods.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify " >
                                    <span className="text-lg text-red-700" >Refund Process:</span> To initiate a return, please contact our customer support team at +919560543590 within the specified return period. Our team will guide you through the return process. After receiving and inspecting the returned item, we will notify you of the approval or rejection of your refund.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify " >
                                    <span className="text-lg text-red-700" >Refund Timeframe:</span> If your return is approved, we will process your refund within 7 days. The refund will be issued to the original payment method.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify " >
                                    <span className="text-lg text-red-700" >Shipping Costs:</span> You are responsible for the return shipping costs unless the return is a result of our error or a defective product.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Damaged or Defective Items</p>
                        <p className="leading-7 px-4 my-3 text-justify" >
                            If you receive a damaged or defective product, please contact us within 7 days of receiving the item. We may request you to provide photographs or other evidence of the damage or defect. We will arrange for a replacement or a refund, including shipping costs, in such cases.
                        </p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Cancellation</p>
                        <p className="leading-7 px-4 my-3 text-justify " >You may cancel an order before it is shipped. Contact us at +919560543590 as soon as possible to request a cancellation. If the order has already been shipped, please follow our return policy.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Exchange Policy</p>
                        <p className="leading-7 px-4 my-3 text-justify " >We do not offer direct exchanges. If you need a different product, you can return the unwanted item and place a new order for the desired product.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Contact Us</p>
                        <p className="leading-7 px-4 my-3 text-justify " >If you have any questions or need assistance with a return or refund, please contact our customer support team at +919560543590.</p>
                    </li>
                </ol>

            </div>
            <FooterContainer passedRef={moveToTopRef} />
        </div>
    );
}