import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteSearchFromHistoryAsync, getSearchHistoryAsync } from "../redux/searchHistorySlice";
import CustomLoader from "../components/CustomLoader";


export default function SearchArea({ handleFocus }) {
    const [loading, setLoading] = useState(false);
    const { searchHistory, user } = useSelector(state => ({ searchHistory: state.searchHistory, user: state.user }));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const endPoint = "https://varyns.onrender.com";

    const handleDelete = (e, userId, item, token) => {
        e.stopPropagation();
        const data = { api_address: `${endPoint}/user-search-history?userId=${userId}&value=${item}`, token };
        dispatch(deleteSearchFromHistoryAsync({ ...data }));
    }

    const handleNavigate = (item) => {
        handleFocus();
        navigate(`/catalogue/search/${item}`);
    }

    useEffect(() => {
        if (!searchHistory && user) {
            setLoading(true);
            const data = { api_address: `${endPoint}/user-search-history?userId=${user._id}`, token: user.token };
            dispatch(getSearchHistoryAsync({ ...data })).then(() => setLoading(false));
        }
    }, []);

    return !user || !searchHistory || (searchHistory.length === 0) ? <></> : (
        <div className=" w-full p-2 shadow-2xl absolute top-9 bg-white left-0 right-0" >
            {loading ?
                (
                    <div className="w-full h-full relative" >
                        <CustomLoader />
                    </div>
                ) :
                (searchHistory && searchHistory.map((item, index) => (
                    <div key={item + index} onClick={() => handleNavigate(item)} className="cursor-pointer flex items-center px-2 py-1 w-full justify-start" >
                        <FontAwesomeIcon className="cursor-pointer" onClick={(e) => handleDelete(e, user._id, item, user.token)} icon={faXmark} size="lg" />
                        <p className="font-medium text-violet-800 ml-4" >{item}</p>
                    </div>
                )))
            }
        </div>
    );
}