import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


const endPoint = "https://varyns.onrender.com";


// export const quantityInCartAsync = createAsyncThunk(
//     'cart/quantity',
//     async (data) => {
//         const { userId, token, productId, shouldIncrease } = data;
//         try {
//             const res = await fetch(endPoint + "/cart/quantity", {
//                 headers: { 'Content-Type': 'application/json', 'x-access-token': token },
//                 method: "POST",
//                 body: JSON.stringify({
//                     userId,
//                     productId,
//                     shouldIncrease
//                 })
//             })
//             const cart = await res.json();
//             if (!res.ok) {
//                 throw cart;
//             };
//             return { cart };
//         } catch (err) {
//             throw err;
//         }
//     }
// );

// export const getCartAsync = createAsyncThunk(
//     'cart',
//     async ({ userId, token }) => {
//         try {
//             const res = await fetch(endPoint + `/cart?userId=${userId}`, {
//                 headers: { 'x-access-token': token }
//             })
//             const cart = await res.json();
//             if (!res.ok) {
//                 throw cart;
//             }
//             return { cart }
//         } catch (err) {
//             throw err;
//         }
//     }
// )


export const orderSlice = createSlice({
    name: 'orders',
    initialState: [],
    reducers: {
        addOrders: (state, action) => {
                return [...action.payload];
        },
        
    }
});

export const { addOrders } = orderSlice.actions;

export default orderSlice.reducer;