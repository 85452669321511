import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";


import FooterContainer from "../container/footer.container";
import ProductGrouper from "../container/product.grouper";
import catalogueSlice, { updateCatalogue, updateCatalogueAsync, updateCatalogueHomepage } from "../redux/catalogueSlice";
import { updateErr } from "../redux/errSlice";
import { userBought, getHomepage, getHomepageData, getTodaysDeals, scrollToTop, shouldCacheUpdate, getProduct } from "../assets";
import * as ROUTES from "../constant/routes";
import { updateCache, updateCacheAsync } from "../redux/cacheSlice";


export default function Home() {
    const settings = {
        dots: false,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        autoplaySpeed: 5000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,

        // appendDots: dots => <ul>{<FontAwesomeIcon icon={faCircle} />}</ul>
    };

    const moveToTopRef = useRef();

    const catalogueArr = useSelector(state => state.catalogue);
    const endPoint = "https://varyns.onrender.com";

    const { user, cache } = useSelector(state => ({ user: state.user, cache: state.cache }));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleProductClick = (id) => {
        navigate("/catalogue/product/" + id);
    }

    useEffect(() => {
        (
            async () => {
                try {
                    const resHeroSlide = await fetch(endPoint + "/hero-slides");
                    const dataHeroSlide = await resHeroSlide.json();
                    if (!resHeroSlide.ok) { dispatch(updateErr(dataHeroSlide)) } else {
                        dispatch(updateCatalogue({ heroSlides: dataHeroSlide }));
                    }

                    const updatedCache = await shouldCacheUpdate(`${endPoint}/did-cache-update`);
                    if (!updatedCache || !cache.homePageItems || (updatedCache.homePageItems.lastUpdated.length !== cache.homePageItems.lastUpdated.length)) {
                        let homepage = await getHomepage(endPoint + "/catalogue/homepage");
                        const docs = [];
                        if (homepage) {
                            if (!updateCache || !cache.homePageItems) {
                                for (let i = 0; i < homepage.length; i++) {
                                    const arr = await Promise.all(homepage[i].products.map(async (item) => await getProduct(item)))
                                    const item = homepage[i];
                                    docs.push({ name: item.title, arr: [...arr], isItCategory: item.isItCategory ? true : false });
                                    dispatch(updateCatalogue({ homepage: [...docs] }));

                                }
                            } else {
                                for (let i = 0; i < homepage.length; i++) {
                                    const arr = await Promise.all(homepage[i].products.map(async (item) => await getProduct(item)))
                                    const item = homepage[i];
                                    docs.push({ name: item.title, arr: [...arr], isItCategory: item.isItCategory ? true : false });
                                }
                                dispatch(updateCatalogue({ homepage: [...docs] }));
                            }
                        }

                        if (updatedCache) {
                            dispatch(updateCache({ ...updatedCache }));
                        }

                    }

                    if (user) {
                        // fetching products user clicked 
                        const userHistory = await getHomepageData(endPoint + "/user-history?user=" + user._id, user.token)
                        dispatch(updateCatalogue({ userHistory }));

                        // fetching products bought by user earlier
                        const buyAgain = await userBought(endPoint + "/user-bought?user=" + user._id, user.token);
                        dispatch(updateCatalogue({ buyAgain }));

                    }

                    // setCatalogueArr({ ...finalObject });
                } catch (err) {
                    dispatch(updateErr(err.message))
                }
            }
        )();
        scrollToTop(window);
    }, [user]);


    return (
        <div className="scrollbar scrollbar-thumb-slate-300 scrollbar-track-slate-100 scrollbar-thin" ref={moveToTopRef} >
            <div className="bg-black p-1 whitespace-nowrap" >
                <p className=" text-center text-lg font-medium text-white" >24 Hours Guaranteed Free Delivery!</p>
            </div>
            <div className="relative" >
                <Slider  {...settings} >
                    {
                        catalogueArr.heroSlides ? catalogueArr.heroSlides.map(item => <Link to={item.url} className="relative" key={item._id} >
                            <img className="h-[360px] 2xs:h-[470px] xs:h-[520px] w-full inline-block object-fill " src={item.image} alt={item.alt} />
                        </Link>) : (
                            <Skeleton count={1} height="250px" />
                        )
                    }
                </Slider>
            </div>

            <div className={`${catalogueArr.homepage.length > 0 ? "-translate-y-36 -mb-36" : ""}`} >
                {
                    user && catalogueArr.userHistory?.length > 0 && <ProductGrouper title="Pick up where you left off" products={catalogueArr.userHistory} handleProductClick={handleProductClick} />
                }
                <br />
                {
                    user && catalogueArr.buyAgain?.length > 0 && <ProductGrouper products={catalogueArr.buyAgain} title="Buy Again" handleProductClick={handleProductClick} />
                }
                {
                    catalogueArr.homepage.length > 0 ?
                        catalogueArr.homepage.map((item, index) => item.isItCategory ? (
                            <div className="px-6 py-2 border-box bg-slate-200 m-4 rounded" >
                                {/* <p className="pt-2 pb-2 text-xl font-bold col-start-1 col-end-3 row-start-1 row-end-2" >{item.name}</p> */}
                                <p className="pt-2 text-xl font-bold col-start-1 col-end-3 row-start-1 row-end-2 mb-4 capitalize" >{item.name} <Link to={`${ROUTES.SEE_MORE}?category=${item.name}`} className="text-sky-600 text-xs " >See all deals</Link></p>

                                <div className="grid gap-2 grid-rows-[47%_47%] h-96 grid-cols-2" >
                                    {
                                        item.arr.slice(0, 4).map((item) => (
                                            // <div key={item._id} className="border-4 border-solid border-slate-200 rounded bg-white shadow-xl cursor-pointer" onClick={() => handleProductClick(item._id)} >
                                            //     <img className="h-full object-contain w-full inline-block" src={item.images[0]} />
                                            // </div>
                                            <div key={item._id} className="min-w-[130px] border-4 border-solid border-slate-200 rounded bg-white shadow-xl cursor-pointer" onClick={() => handleProductClick(item._id)} >
                                                <img className="h-[60%] object-contain w-full inline-block p-2" src={item.images[0]} />
                                                <div className=" p-2 h-[40%]" >
                                                    {/* <p className="text-sm" >Deal of the Day</p> */}
                                                    <p className="traking-sm leading-tighter font-[400] w-full h-6 text-left text-sm pt-1 text-red-600 overflow-hidden text-ellipsis " >{item.title}</p>
                                                    <div className="flex items-center" >
                                                        <p className="text-[12px] 2xs:text-[14px] mt-[1px]" >MRP:<s className=" text-gray-700 ml-1" >&#8377;{item.mrp ? item.mrp : item.cost}</s></p>
                                                        <p className="text-[18px] 2xs:text-[22px] relative px-2" >&#x20B9;{item.cost}<span className="text-[11px] absolute top-[4px] 2xs:top-[6px]" >00</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ) : (
                            <div key={item.name + index} className="p-6 my-2" >
                                <p className="pt-2 text-xl font-bold col-start-1 col-end-3 row-start-1 row-end-2 mb-4" >{item.name} <Link to={`${ROUTES.SEE_MORE}?key=${item.name}`} className="text-sky-600 text-xs " >See all deals</Link></p>
                                <div className="scrollbar scrollbar-thumb-slate-300 scrollbar-track-slate-100 scrollbar-thin flex gap-x-5 overflow-none overflow-x-scroll p-3" >
                                    {
                                        item.arr.map(deal => (
                                            <Link to={"/catalogue/product/" + deal._id} key={deal._id} className="border-4 shadow-xl bg-white border-slate-200 rounded-md border-solid w-56 h-72 inline-block shrink-0" >
                                                <img src={deal.images && deal.images[0]} alt={deal.title} className="object-contain w-full block h-[60%] bg-blend-luminosity p-2" />
                                                <div className=" px-2 pt-2 bg-slate-200 h-[40%]" >
                                                    <p className="text-sm bg-red-700 text-white p-2 py-[2.9px] rounded-md inline-block" >{deal.discount === 0 ? `${Math.round((deal.mrp - deal.cost) / deal.mrp * 100)}% off` : `${deal.discount}% off`}</p>
                                                    {/* <p className="text-sm" >Deal of the Day</p> */}
                                                    <p className="traking-sm leading-tighter font-[400] w-full h-10 text-left text-sm pt-1 text-red-600 overflow-hidden text-ellipsis " >{deal.title}</p>
                                                    <div className="flex items-center mt-1" >
                                                        <p className="text-[14px]" >MRP:<s className=" text-gray-700 ml-1" >&#8377;{deal.mrp ? deal.mrp : deal.cost}</s></p>
                                                        <p className="text-[22px] relative px-2" >&#x20B9;{deal.cost}<span className="text-[11px] absolute top-[4px] 2xs:top-[6px]" >00</span></p>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>
                        )) :
                        (
                            <div className="p-4">
                                <div>
                                    <Skeleton height="40px" className="my-3" width="300px" />
                                    <div className="flex gap-x-5 overflow-none overflow-x-scroll p-3" >
                                        <Skeleton height="184px" width="152px" />
                                        <Skeleton height="184px" width="152px" />

                                        <Skeleton height="184px" width="152px" />

                                    </div>
                                </div>
                                <div>
                                    <Skeleton height="40px" className="my-3" width="300px" />
                                    <div className="flex gap-x-5 overflow-none overflow-x-scroll p-3" >
                                        <Skeleton height="184px" width="152px" />
                                        <Skeleton height="184px" width="152px" />

                                        <Skeleton height="184px" width="152px" />

                                    </div>
                                </div>
                                <div>
                                    <Skeleton height="40px" className="my-3" width="300px" />
                                    <div className="flex gap-x-5 overflow-none overflow-x-scroll p-3" >
                                        <Skeleton height="184px" width="152px" />
                                        <Skeleton height="184px" width="152px" />

                                        <Skeleton height="184px" width="152px" />

                                    </div>
                                </div>
                            </div>
                        )
                }
            </div>
            <FooterContainer passedRef={moveToTopRef} />
        </div >
    )
}