import { createBrowserRouter, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
    Home,
    Catalogue,
    RetryPayment,
    Register,
    LogIn,
    NewAddress,
    VerifyMobileNumber,
    Product,
    Cart,
    TodaysDeals,
    NotFound,
    Buy,
    Address,
    PlaceOrder,
    Payment,
    Delivery,
    OrderPlacedSuccessfully,
    OrderProcessing,
    Orders,
    OrderDetails,
    OrderFailed,
    PrivacyPolicy,
    AboutUs,
    TermsAndConditions,
    Returns,
    TrackingOrder,
    ReturnPolicy,
    ForgotPassword,
    SeeMore,
    CustomerService,
} from "./pages";
import * as ROUTES from "./constant/routes";
import NavbarContainer from "./container/navbar.container";

// const loader = (user) => {
//     const isSignedIn = user;
//     if (!isSignedIn) {
//         return redirect("/");
//     }
//     return null;
// }

const ProtectedRoute = ({ route }) => {
    const user = useSelector(state => state.user);
    return user ? route : <Navigate to={ROUTES.LOGIN} />;
}

const routes = (user) => {
    const publicRoutes = [
        {
            path: "",
            element: <NavbarContainer />,
            children: [
                {
                    path: ROUTES.HOME,
                    element: <Home />
                },
                {
                    path: ROUTES.CATALOGUE,
                    element: <Catalogue />
                },
                {
                    path: ROUTES.PRODUCT,
                    element: <Product />
                },
                {
                    path: ROUTES.SEE_MORE,
                    element: <SeeMore />
                },
                {
                    path: ROUTES.CART,
                    element: <Cart />
                },
                {
                    path: ROUTES.TODAYSDEALS,
                    element: <TodaysDeals />
                },
                {
                    path: ROUTES.ABOUT_US,
                    element: <AboutUs />
                },
                {
                    path: ROUTES.PRIVACY_POLICY,
                    element: <PrivacyPolicy />
                },
                {
                    path: ROUTES.CONDITION_OF_USE,
                    element: <TermsAndConditions />
                },
                {
                    path: ROUTES.RETURN_POLICY,
                    element: <ReturnPolicy />
                },
                {
                    path: ROUTES.CUSTOMER_SERVICE,
                    element: <CustomerService />
                },
                {
                    path: ROUTES.ORDER_PROCESSING,
                    element: <ProtectedRoute route={<OrderProcessing />} />,
                },
                {
                    path: ROUTES.RETURNS,
                    element: <ProtectedRoute route={<Returns />} />,
                },
                {
                    path: ROUTES.TRACKING_ORDER,
                    element: <ProtectedRoute route={<TrackingOrder />} />,
                },
                {
                    path: ROUTES.ORDERS,
                    element: <ProtectedRoute route={<Orders />} />,
                },
                {
                    path: ROUTES.PAYMENT_FAILED,
                    element: <ProtectedRoute route={<OrderFailed />} />,
                },
                {
                    path: ROUTES.ORDER_DETAILS,
                    element: <ProtectedRoute route={<OrderDetails />} />,
                },
                {
                    path: ROUTES.RETRY_PAYMENT,
                    element: <ProtectedRoute route={<RetryPayment />} />,
                },
                {
                    path: ROUTES.ORDER_PLACED_SUCCESSFULLY,
                    element: <ProtectedRoute route={<OrderPlacedSuccessfully />} />,
                }
            ]
        },
        {
            path: ROUTES.BUY,
            element: <ProtectedRoute route={<Buy />} />,
            children: [
                {
                    path: ROUTES.ADDRESS,
                    element: <ProtectedRoute route={<Address />} />

                },
                {
                    path: ROUTES.DELIVERY,
                    element: <ProtectedRoute route={<Delivery />} />
                },
                {
                    path: ROUTES.PAYMENT,
                    element: <ProtectedRoute route={<Payment />} />
                },
                {
                    path: ROUTES.PLACE_ORDER,
                    element: <ProtectedRoute route={<PlaceOrder />} />
                },
                {
                    path: ROUTES.NEW_ADDRESS,
                    element: <ProtectedRoute route={<NewAddress />} />
                }
            ]
        },
        {
            path: ROUTES.LOGIN,
            element: user ? <Navigate to={"/"} replace /> : <LogIn />
        },
        {
            path: ROUTES.REGISTER,
            element: user ? <Navigate to={"/"} replace /> : <Register />
        },
        {
            path: ROUTES.VERIFY_MOBILE_NUMBER,
            element: user ? <Navigate to={"/"} replace /> : <VerifyMobileNumber />
        },
        {
            path: ROUTES.FORGOT_PASSWORD,
            element: user ? <Navigate to={"/"} replace /> : <ForgotPassword />
        },
        {
            path: "*",
            element: <NotFound />
        }
    ]
    return publicRoutes
}

const router = (user) => {
    return createBrowserRouter(routes(user));
}

export default router;