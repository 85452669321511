import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CustomStepper = ({ steps, active = 0 }) => {

    return (
        <div className="p-4" >
            {steps.map((step, index) => (
                <div key={step.label} >
                    <div className="flex items-center" >
                        <div className={`${active >= index ? "bg-teal-300" : "bg-white border-zinc-200 border-2 border-solid"} flex justify-center items-center w-6 h-6 mr-2`} >
                            <FontAwesomeIcon color="#fff" icon={faCheck} />
                        </div>
                        <p className={`${active === index ? "font-semibold" : "font-normal"} ${active < index ? "text-zinc-300 font-semibold" : "text-black"} text-[15px] capitalize`} >{step.label}</p>
                    </div>
                    {steps.length - 1 > index && (
                        <>
                            <div className={`w-1 ${active > index ? "h-16 bg-teal-300" : "h-14 bg-zinc-200"}  ml-[10px]`} />
                            {
                                active <= index && (
                                    <div className={`w-1 h-3 bg-zinc-200 ml-[10px] mt-1`} />
                                )
                            }
                        </>
                    )}
                </div>
            ))}
        </div>
    )
}

export default CustomStepper;