import { useEffect, useRef } from "react";
import { scrollToTop } from "../assets";
import FooterContainer from "../container/footer.container";

export default function TermsAndConditions() {

    const moveToTopRef = useRef();

    useEffect(() => {
        scrollToTop(window);
    }, []);

    return (
        <div ref={moveToTopRef} >
            <div className="p-6" >
                <h1 className="text-[35px] font-medium" >Terms & Conditions of Use and Sale</h1>

                <ol className="list-decimal px-4 font-medium" >
                    <li>
                        <p className="text-xl font-medium my-3" >Introduction</p>
                        <p className="leading-7 px-4 my-3 font-normal text-justify" >
                            These Terms and Conditions of Use and Sale ("Terms") govern the use of our e-commerce website (the "Website") and the purchase of products or services offered on the Website. By accessing or using the Website and making a purchase, you agree to be bound by these Terms.
                        </p>
                    </li>

                    <li>
                        <p className="text-xl font-medium my-3" >Definitions</p>
                        <ul className="list-disc font-normal" >
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    "We," "us," "our," and "Company" refer to Varyns.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    "User" refers to any person who uses the Website.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    "Customer" refers to a User who places an order for products or services.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    "Products" refers to the goods and services offered for sale on the Website.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Acceptance of Terms</p>
                        <p className="leading-7 px-4 my-3 text-justify font-normal" >By accessing the Website or making a purchase, you confirm that you have read and understood these Terms and agree to comply with them. If you do not agree with these Terms, please do not use our Website.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Privacy Policy</p>
                        <p className="leading-7 px-4 my-3 text-justify font-normal" >Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Orders</p>
                        <ul className="list-disc font-normal" >
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    Customers may place orders for Products through the Website.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    We reserve the right to accept or reject any order in our sole discretion.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    Orders are subject to product availability and may be canceled or modified.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    All orders are subject to verification and approval, and we may request additional information from you.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Pricing and Payment</p>
                        <ul className="list-disc font-normal" >
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    All prices on the Website are in Indian Rupees (INR) and are inclusive of applicable taxes.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    Payment methods accepted will be listed on the Website.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    We reserve the right to change prices at any time, and such changes will not affect orders already accepted.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Shipping and Delivery</p>
                        <ul className="list-disc font-normal" >
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    Shipping fees, if applicable, will be mentioned during the checkout process.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    Delivery times are estimates and may vary based on location and other factors.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    Risk of loss or damage to the Products passes to the Customer upon delivery.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Returns and Refunds</p>
                        <p className="leading-7 px-4 my-3 text-justify font-normal" >Please refer to our Return and Refund Policy for information on our return and refund process.
                        </p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Intellectual Property</p>
                        <p className="leading-7 px-4 my-3 text-justify font-normal" >All content and materials on the Website are protected by copyright, trademark, and other intellectual property laws. You may not use our content without our explicit written permission.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Limitation of Liability</p>
                        <p className="leading-7 px-4 my-3 text-justify font-normal" >We shall not be liable for any indirect, incidental, or consequential damages.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Governinng Law</p>
                        <p className="leading-7 px-4 my-3 text-justify font-normal" >These Terms are governed by the laws of India. Any disputes will be subject to the exclusive jurisdiction of the courts in NCT of Delhi.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Changes to Terms</p>
                        <p className="leading-7 px-4 my-3 text-justify font-normal" >We reserve the right to update and modify these Terms at any time. Changes will be effective upon posting on the Website.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Contact Information</p>
                        <p className="leading-7 px-4 my-3 text-justify font-normal" >If you have any questions or concerns, please contact us at +919560543590.</p>
                    </li>
                </ol>

            </div>
            <FooterContainer passedRef={moveToTopRef} />
        </div>
    );
}