import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

export default function SearchBar({ passedRef, handleSearch, handleChange, value, handleFocus }) {
    return (
        <form onSubmit={handleSearch} className='flex bg-white rounded-lg h-9 border-box' >
            <input ref={passedRef} onChange={handleChange} value={value} onFocus={handleFocus}  className="p-3 py-1 font-medium text-lg text-slate-500 outline-0 border-0 rounded-lg w-32 2xs:w-40 xs:w-52 focus:outline-0" type="text" placeholder='Search Varyns' />
            <button className='py-1 px-2' >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
        </form>
    );
}