import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";


import * as ROUTES from "../../constant/routes";
import { getProductDetails, orderDetails, authenticatePayment, authenticatePod } from "../../assets";
import { clearPaymentData, updatePaymentData } from "../../redux/paymentSlice";
import { updateErr } from "../../redux/errSlice";
import { emptyCart, removeAllItemsFromCartAsync } from "../../redux/cartSlice";
import Loader from "../../components/loader.spinner";
import useQuery from "../../hooks/use-query";
import costCalculator from "../../helpers/costCalculator";

export default function PlaceOrder() {
    // const shipment = [0, 1];

    const [shipment, setShipment] = useState(null);
    const [loading, setLoading] = useState(true);

    const user = useSelector(state => state.user);
    const cart = useSelector(state => state.cart);
    const navigate = useNavigate();
    const razorpay = useRef(null);
    const dispatch = useDispatch();
    const query = useQuery();

    const months = ['',
        'January', 'Febuary', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October',
        'November', 'December'
    ];
    const endPoint = "https://varyns.onrender.com/";
    // const endPoint = "http://localhost:4001/"


    const paymentData = useSelector(state => state.paymentData);

    const paymentType = () => {
        if (paymentData.method === "upi") {
            return paymentData.vpa;
        } else if (paymentData.method === "netbanking") {
            return paymentData.bank.label
        } else {
            return paymentData.number
        }
    }

    const generateDateOfDeliveryTxt = (date) => {
        if (date === 1) {
            return "Today";
        } else if (date === 2) {
            return "Day after Tomorrow";
        } else {
            const currentDate = new Date().getTime();
            return new Date(currentDate + date * 24 * 60 * 60 * 1000).toLocaleDateString();
        }
    }

    const getCartData = async () => {
        const response = await getProductDetails(cart.map(item => ({ product: item.productId })));
        // need to change it later to something better
        setShipment(response.map((item, index) => ({ ...item, ...cart[index] })));
    }

    const initiatePayment = useCallback(async () => {
        setLoading(true);
        const { order: { amount, orderId: order_id, currency, doc_id }, email, notes, method, contact } = paymentData;
        if (paymentData.method !== "pod") {

            let methodSpecificData = {};

            // eslint-disable-next-line default-case
            switch (method) {
                case "upi":
                    methodSpecificData = { vpa: paymentData.vpa };
                    break;
                case "netbanking":
                    methodSpecificData = { bank: paymentData.bank.value };
                    break;
                case "card":
                    methodSpecificData = {
                    }
            }
            razorpay.current.createPayment({
                amount,
                order_id,
                currency,
                email,
                notes,
                method,
                contact,
                ...methodSpecificData
            })

            razorpay.current.on('payment.success', async function (res) {
                const { verified: verifyPayment } = await authenticatePayment(`${endPoint}verify-payment-signature`, { razorpayPaymentId: res.razorpay_payment_id, signature: res.razorpay_signature, doc_id }, user.token);
                // need to do this operation in backend instead of here. !! important
                !query.get("buynow") && dispatch(removeAllItemsFromCartAsync({ userId: user._id, doc_id, token: user.token }))
                setLoading(false);
                if (verifyPayment) {
                    navigate(`/order-placed?order=${order_id}`);

                } else {
                    navigate(`/order-processing?order=${order_id}`);
                }

                setTimeout(() => {
                    dispatch(clearPaymentData());
                }, 3000);
            });

            razorpay.current.on('payment.error', function (res) {
                dispatch(updateErr(res.error.description));
                !query.get("buynow") && dispatch(removeAllItemsFromCartAsync({ userId: user._id, token: user.token }));
                // navigate(`/orders/order-detail/payment-failed?order=${paymentData.order.orderId}`);
                navigate(`/orders`);
                setTimeout(() => {
                    dispatch(clearPaymentData());
                }, 3000);
            })
        } else {
            const { verified: verifyPayment } = await authenticatePod(`${endPoint}verify-pay-on-delivery`, doc_id, user.token);

            if (verifyPayment) {
                navigate("/order-placed?order=" + order_id);

            } else {
                dispatch(updateErr("Something went wrong. Your couldn't be confirmed. Please Try again later."));
                navigate(`/orders/order-detail/payment-failed?order=${paymentData.order.orderId}`);
            }
            !query.get("buynow") && dispatch(removeAllItemsFromCartAsync({ userId: user._id, token: user.token }));
            setTimeout(() => {
                dispatch(clearPaymentData());
            }, 3000);

        }
    }, [paymentData]);

    useEffect(() => {
        const Razorpay = window.Razorpay;
        razorpay.current = new Razorpay({
            key: 'rzp_test_NlVykh9LKJwoJI',
            image: 'https://i.imgur.com/n5tjHFD.jpg'
        });


        (async () => {
            try {
                if (paymentData.notes.address) {
                    let order;
                    if (query.get("buynow")) {
                        order = await orderDetails(`${endPoint}buy-now?userId=${user._id}&order=${query.get("order")}`, user.token, {
                            address: paymentData.notes.address,
                            estimated_delivery_days: paymentData.estimated_delivery_days,
                            quantity: paymentData.quantity,
                            method: paymentData.method,
                            size: paymentData.selectedSize,
                            color: paymentData.selectedColor,
                        });
                    } else {
                        order = await orderDetails(`${endPoint}create-order?userId=${user._id}`, user.token, {
                            address: paymentData.notes.address,
                            estimated_delivery_days: paymentData.estimated_delivery_days,
                            method: paymentData.method,
                            quantity: paymentData.quantity,
                            size: paymentData.selectedSize,
                            color: paymentData.selectedColor,
                        });
                    }


                    dispatch(updatePaymentData({ ...paymentData, order }));
                    setLoading(false);
                } else {
                    navigate(`/orders`);
                }
            } catch (err) {
                dispatch(updateErr(err.message));
            }
        })();

    }, []);

    useEffect(() => {
        if (!paymentData.method) {
            navigate(ROUTES.CART)
        } else {
            !query.get("buynow") && getCartData();
        }

    }, []);

    console.log(paymentData, "payment data <<<========");


    return (
        <>
            <div className="px-4" >
                <h2 className="font-bold text-[22px] my-2 mb-2" >Order now</h2>
                <div className="border border-solid border-gray-300 p-3 px-0 rounded-lg" >
                    <p className="px-3 pb-2 text-ellipsis overflow-hidden whitespace-nowrap" >
                        Shipping to:
                        <span className="ml-1 font-medium" >{paymentData.notes.address.fullName}, {paymentData.notes.address.apartment}, {paymentData.notes.address.area}</span>
                    </p>
                    <div className="p-3 border border-solid border-gray-300 border-l-0 border-r-0" >
                        <div className="flex justify-between" >
                            <p>Items:</p>
                            <p>{paymentData.order ? (paymentData.order.amount / 100) + ".00" : "--"}</p>
                        </div>
                        <div className="flex justify-between" >
                            <p>Delivery:</p>
                            <p>0.00</p>
                        </div>
                        <div className="flex justify-between" >
                            <p>Order Total:</p>
                            <p>{paymentData.order ? (paymentData.order.amount / 100) + ".00" : "--"}</p>
                        </div>
                    </div>
                    {/* <div className="p-3" >
                        <p className="text-sm text-red-700 font-bold" >Your Savings: 700.00 {"(29%)"} </p>

                        <ul>
                            <li className="text-xs" >Item discount</li>
                        </ul>
                    </div> */}
                </div>
                <div className="p-3 rounded-sm border border-solid border-gray-300 mt-4" >
                    <p className="text-lg" >Pay with</p>
                    <p className="font-semibold" ><span className="uppercase" >{paymentData.method}</span> : {paymentType()}</p>
                </div>
                <div className="flex items-center p-3 rounded-sm border border-solid border-gray-300 rounded-b-lg" >
                    <FontAwesomeIcon icon={faChevronDown} size="2xs" />
                    <p className="text-teal-700 ml-1" >Add Gift Card or Promo Code</p>
                </div>

                <div className="p-3 rounded-sm border border-solid border-gray-300 mt-4" >
                    <p className="text-lg" >Deliver to</p>
                    <p className="font-semibold capitalize" >{paymentData.notes.address.fullName}</p>
                    <p className="overflow-hidden text-ellipsis whitespace-nowrap" >{paymentData.notes.address.apartment}, {paymentData.notes.address.area}</p>
                </div>
                {/* <div className="flex items-center p-3 rounded-sm border border-solid border-gray-300 rounded-b-lg" >
            <FontAwesomeIcon icon={faChevronDown} size="2xs" />
            <p className="text-teal-700 ml-1" >Add Gift Card or Promo Code</p>
        </div> */}

                <div>
                    {
                        shipment ? (
                            shipment.map((item, index) => (
                                <div className="" >
                                    <p>Shipment {index + 1} of {shipment.length}</p>
                                    <small>Items shipped from Varyns</small>
                                    <div className="border border-solid border-gray-300 rounded-md " >
                                        <div className="rounded-md border-2 border-solid border-orange-500 w-[90%] p-2 gap-x-4 px-3 flex items-center justify-between mx-auto bg-orange-50 my-3" >
                                            <input checked={true} disabled={true} className="w-6 h-6" type="radio" />
                                            <div className="w-[90%]" >
                                                <p className="text-green-700" >{generateDateOfDeliveryTxt(paymentData.estimated_delivery_days)}</p>
                                                <p className="font-semibold leading-snug" >Free Delivery when you buy on Varyn's</p>
                                            </div>
                                        </div>
                                        <div className="p-3 py-2 border border-solid border-gray-300 border-l-0 border-r-0" >
                                            <h3 className="text-orange-700 font-medium text-lg leading-snug" >Estimated delivery: {`${generateDateOfDeliveryTxt(paymentData.estimated_delivery_days).split("/")[0]} ${months[generateDateOfDeliveryTxt(paymentData.estimated_delivery_days).split("/")[1]]} ${generateDateOfDeliveryTxt(paymentData.estimated_delivery_days).split("/")[2]}`}</h3>
                                        </div>
                                        <div className="p-3 flex gap-x-2" >
                                            <img className="block w-1/3 h-20" src={item.value.images[0]} />
                                            <div>
                                                <p className="font-bold leading-tight h-20 overflow-hidden" >{item.value.description}</p>
                                                <div className="flex items-center" >
                                                    <s>{item.value.mrp ? item.value.mrp : item.value.cost}.00</s>
                                                    <p className="text-red-600 font-bold ml-1" >{paymentData?.order?.products[index].cost}.00</p>
                                                </div>

                                                {paymentData.order &&

                                                    paymentData.order.products[index].size && (
                                                        <>
                                                            <small className="text-emerald-600 font-medium block leading-none mt-1" >Quantity: {paymentData.order.products[index].quantity}</small>
                                                            <small className="text-emerald-600 font-medium block leading-none mt-1" >Size: {paymentData.order.products[index].size.name}</small>
                                                        </>
                                                    )
                                                }
                                                {paymentData.order &&

                                                    paymentData.order.products[index].color && (
                                                        <>
                                                            <small className="flex items-center text-black mt-1 font-medium block leading-none" >Color: <span className="ml-3 inline-block w-6 h-6 rounded-full" style={{ backgroundColor: paymentData.order.products[index].color.name }} ></span></small>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <Skeleton count={1} />
                        )
                    }
                </div>
                <p className="mt-6 text-stone-600 leading-relaxed text-xs" >By placing your order, you agree to Varyns's <span className="text-teal-600" >privacy notice</span> and <span className="text-teal-700" >conditions of use</span>.</p>
                <p className="my-3 text-stone-600 leading-relaxed text-xs" >
                    When your order is places, we'll send you an e-mail message to acknowledging receipt of your order. if you choose to pay using electronic payment
                    method{"("}credit or debit cart{")"}, you will be directed to your bank's website to complete your payment. Your contract to purchase an item will not be complete until we recieve your electronic payent and dispatch your item. if you choose to pay using Pay on Delivery , you can pay using cash/card/net banking
                    when you recive you items.
                </p>

                <p className="text-stone-600 leading-relaxed text-xs" >See Varyns's <span className="text-teal-700" >Return Policy</span>.</p>

                <p className="text-stone-600 leading-relaxed text-xs" >Go to the <Link to="/" className="text-teal-700" >Varyns homepage</Link> without completing order</p>
            </div>
            <div className="sticky h-[74px] bottom-0 right-0 left-0 p-3 bg-white border-t-solid  border border-t-gray-200 border border-solid border-red-400" >
                <button onClick={initiatePayment} className="w-full rounded-lg bg-[#ffd814] p-3" >Place Your Order and Pay</button>
            </div>
            {
                loading && <div className="sticky left-0 top-0 bottom-0 right-0 w-screen h-screen bg-slate-200 flex justify-center items-center" >
                    <Loader />
                </div>
            }
        </>
    )
}