import { RouterProvider } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

import router from "./router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { updateErr } from "./redux/errSlice";
import { logoutUser } from "./redux/userSlice";

function App() {

  const user = useSelector(state => state.user);
  const err = useSelector(state => state.err);
  const dispatch = useDispatch();

  const notifyErr = (msg, options = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "light",
  }) => toast(msg, options);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (err) {
      notifyErr(err);
      dispatch(updateErr(""));
    };
    if (user) {
      const decodedJwt = parseJwt(user.token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch(logoutUser());
      }
    };
  }, [err]);

  return (
    <>
      <RouterProvider
        router={router(user)}
      />
      <ToastContainer />
    </>
  );
}

export default App;
