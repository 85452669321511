import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const { ENV } = process.env;

const endPoint = "https://varyns.onrender.com";

export const addToCartAsync = createAsyncThunk(
    'cart/add',
    async (data) => {
        const { userId, token, productArr } = data;
        try {
            const res = await fetch(endPoint + "/cart", {
                headers: { 'Content-Type': 'application/json', 'x-access-token': token },
                method: "POST",
                body: JSON.stringify({
                    userId,
                    productArr,
                })
            })
            const cart = await res.json();
            if (!res.ok) {
                throw cart;
            };
            return { cart };
        } catch (err) {
            throw err;
        }
    }
);

export const quantityInCartAsync = createAsyncThunk(
    'cart/quantity',
    async (data) => {
        const { userId, token, productId, shouldIncrease } = data;
        try {
            const res = await fetch(endPoint + "/cart/quantity", {
                headers: { 'Content-Type': 'application/json', 'x-access-token': token },
                method: "POST",
                body: JSON.stringify({
                    userId,
                    productId,
                    shouldIncrease
                })
            })
            const cart = await res.json();
            if (!res.ok) {
                throw cart;
            };
            return { cart };
        } catch (err) {
            throw err;
        }
    }
);

export const getCartAsync = createAsyncThunk(
    'cart',
    async ({ userId, token }) => {
        try {
            const res = await fetch(endPoint + `/cart?userId=${userId}`, {
                headers: { 'x-access-token': token }
            })
            const cart = await res.json();
            if (!res.ok) {
                throw cart;
            }
            return { cart }
        } catch (err) {
            throw err;
        }
    }
)

export const removeFromCartAsync = createAsyncThunk(
    'cart/remove',
    async ({ userId, productId, token }) => {
        try {
            const res = await fetch(endPoint + `/cart?userId=${userId}&cartItemId=${productId}`, {
                headers: { 'Content-Type': 'application/json', 'x-access-token': token },
                method: "DELETE",
            })
            const cart = await res.json();
            if (!res.ok) {
                throw cart;
            };
            return { cart };
        } catch (err) {
            throw err;
        }
    }
);

export const removeAllItemsFromCartAsync = createAsyncThunk(
    'cart/removeAll',
    async ({ userId, token }) => {
        try {
            const res = await fetch(endPoint + `/cart-empty?userId=${userId}`, {
                headers: { 'Content-Type': 'application/json', 'x-access-token': token },
                method: "DELETE",
            })
            const cart = await res.json();
            if (!res.ok) {
                throw cart;
            };
            return { cart: [] };
        } catch (err) {
            throw err;
        }
    }
);

export const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        addToCart: (state, action) => {
            if(!state.map(item => item.productId).includes(action.payload.productId)){
                return [...state, action.payload];
            }
        },
        quantityInCart: (state, action) => {
            if (state && state.length > 0) {
                for (let i = 0; i < state.length; i++) {
                    if (state[i].productId === action.payload.productId) {
                        if(action.payload.shouldIncrease){
                            state[i].quantity += 1
                        } else if(state[i].quantity > 1) {
                            state[i].quantity -= 1;
                        }
                        break;
                    }
                }
            }
        },
        removeFromCart: (state, action) => {
            const cart = [
                ...state.filter(item => item.productId !== action.payload)
            ];
            return cart
        },
        emptyCart: (state, action) => {
            return [];
        }
    },
    extraReducers: {
        [addToCartAsync.fulfilled]: (state, action) => {
            return [...action.payload.cart.productList]
        },
        [quantityInCartAsync.fulfilled]: (state, action) => {
            return [...action.payload.cart.productList]
        },
        [removeFromCartAsync.fulfilled]: (state, action) => {
            return [...action.payload.cart.productList]
        },
        [removeAllItemsFromCartAsync.fulfilled]: (state, action) => {
            return [...action.payload.cart]
        },
        [getCartAsync.fulfilled]: (state, action) => {
            return action.payload.cart ? [...action.payload.cart.productList] : [];
        }
    }
});

export const { addToCart, removeFromCart, emptyCart, quantityInCart } = cartSlice.actions;

export default cartSlice.reducer;