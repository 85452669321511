import { faLocationDot, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { ColorRing } from "react-loader-spinner";

import AddressCard from "../components/AddressCard";

export default function AddressSelector({ pincode, user, selected, handleSelectAddress, pincodeEditor = true, handleChoosePincode, handleChangePincode, handlePincode }) {
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if(loading){
            setLoading(false);
        }
    }, []);
    return (
        <div className="py-4 px-4 bg-white transform duration-100" >
            {
                pincodeEditor ? (
                    <div>
                        <button className="mb-6 flex items-center" onClick={handleChoosePincode} >
                            <FontAwesomeIcon color="#c4c4c4" size="lg" icon={faArrowLeft} />
                            <span className="ml-2 text-[11px] font-medium " >Enter Pincode</span>
                        </button>
                        <form onSubmit={handlePincode} className="mx-5 border-t border-solid border-gray-300" >
                            <input placeholder="Enter Pincode" className="px-2 border py-1 border-solid border-black w-full mb-4" type="text" value={pincode} onChange={handleChangePincode} />
                            <button type="submit" className="relative py-[10px] text-sm rounded-md block w-full bg-yellow-400" >
                                Apply
                                {
                                    loading && (
                                        <span className="rounded-md absolute bg-[rgba(0,0,0,0.3)] top-0 left-0 w-full h-full flex justify-center items-center" >
                                            <ColorRing
                                                visible={true}
                                                height="22"
                                                width="22"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                            />
                                        </span>
                                    )
                                }
                            </button>
                        </form>
                    </div>
                ) : (
                    <>
                        {user && user.address.length > 0 &&
                            <>
                                <h3 className="font-semibold leading-tight" >Choose your location</h3>
                                <p className="text-gray-600 text-[11px] mb-2" >Select a delivery location to see products availability and delivery options</p>
                                <div className="overlfow-hidden overflow-x-scroll flex gap-x-3 border-b-2 border-solid border-gray-200" >
                                    {user.address.map(address => <AddressCard handleSelectAddress={handleSelectAddress} selected={selected ? address.fullName === selected.fullName : false} address={address} />)}
                                </div>
                            </>}
                        <button onClick={handleChoosePincode} className="text-teal-700 text-xs cursor-pointer flex items-center mt-3" >
                            <FontAwesomeIcon icon={faLocationDot} size="md" />
                            <span className="ml-2 " >Enter pincode</span>
                        </button>
                    </>
                )
            }
        </div>
    );
}