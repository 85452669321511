import { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { faXmark, faHouse, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import * as ROUTES from "../constant/routes";


export default function SidebarContainer({ show = false, handleToggleSidebar, handleUserMenuToggle, user }) {

    // It will be replaced by the value coming from the react store.

    return (
        <Transition show={show}
            className="h-screen w-full fixed top-0 z-[2] bg-[rgba(0,0,0,0.4)] overflow-hidden"
            enter="transition ease duration-500 transform"
            enterFrom="opacity-0 -translate-x-[80%]"
            enterTo="opacity-100 -translate-x-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 -translate-x-0"
            leaveTo="opacity-0 -translate-x-[80%]"
        >
            <div className="w-[80%] bg-slate-100 h-full relative" >
                <button className="absolute top-1 -right-8" >
                    <FontAwesomeIcon onClick={handleToggleSidebar} icon={faXmark} size="2xl" className="text-white" />
                </button>
                <section className="bg-sky-200 w-full h-28 relative flex justify-end items-start flex-col p-3" >
                    {
                        user ? (
                            <button onClick={handleUserMenuToggle} className="absolute font-medium top-2 right-3" >
                                {user.fullName.split(" ")[0]}
                                <FontAwesomeIcon icon={faUser} size="lg" className="ml-2" />
                            </button>
                        ) : (
                            <Link to={ROUTES.LOGIN} className="absolute font-medium top-2 right-3" >
                                Sign in
                                <FontAwesomeIcon icon={faUser} size="lg" className="ml-2" />
                            </Link>
                        )
                    }
                    <p className="text-lg leading-none font-medium" >Browse</p>
                    <p className="text-[28px] font-semibold leading-none" >Varyns</p>
                </section>

                <Link onClick={handleToggleSidebar} to={ROUTES.HOME} className="border-b-4 p-3 py-4 border-solid border-gray-300 flex justify-between" >
                    <span className="font-medium text-xl" >Varyns Home</span>
                    <FontAwesomeIcon icon={faHouse} size="xl" />
                </Link>
                <section className="border-b-4 p-3 py-4 border-solid border-gray-300 flex flex-col" >
                    <h3 className="font-medium text-xl" >Trending</h3>
                    <p className="text-sm mt-4" >Coming Soon</p>
                </section>
                <section className="border-b-4 p-3 py-4 border-solid border-gray-300 flex flex-col" >
                    <h3 className="font-medium text-xl capitalize" >Top Categories for you</h3>
                    <Link onClick={handleToggleSidebar} to="/see-more?category=groceries" className=" mt-4 text-lg" >Groceries</Link>
                    <Link onClick={handleToggleSidebar} to="/see-more?category=cosmetics" className=" mt-4 text-lg" >Cosmetics</Link>
                    <Link onClick={handleToggleSidebar} to="/see-more?key=Your%20Trendy%20Tops" className="mt-4 text-lg" >Crop Tops</Link>
                </section>
            </div>
        </Transition >
    );
}