const HOME = "/";
const CATALOGUE = "/catalogue/search/:slug";
const PRODUCT = "/catalogue/product/:id"
const LOGIN = "/login";
const REGISTER = "/register";
const VERIFY_MOBILE_NUMBER = "/register/verify-mobile-number"
const CONDITION_OF_USE = "/condition-of-use";
const PRIVACY_POLICY = "/privacy-policy";
const RETURN_POLICY = "/return-policy";
const CART = "/cart";
const TODAYSDEALS = "/catalogue/todays-deals";
const BUY = "/buy";
const PLACE_ORDER = "/buy/place-order";
const PAYMENT = "/buy/payment";
const DELIVERY = "/buy/delivery";
const ADDRESS = "/buy/address";
const NEW_ADDRESS = "/buy/address/new";
const ORDER_PLACED_SUCCESSFULLY = "/order-placed";
const ORDER_PROCESSING = "/order-processing";
const ORDERS = "/orders";
const PAYMENT_FAILED = "/orders/order-detail/payment-failed";
const ORDER_DETAILS = "/orders/order-detail";
const RETRY_PAYMENT = "/order/retry-payment";
const ABOUT_US = "/about-us";
const RETURNS = "/returns";
const TRACKING_ORDER = "/orders/order-detail/tracking-order";
const FORGOT_PASSWORD = "/forgot-password";
const SEE_MORE = "/see-more";
const CUSTOMER_SERVICE = "/customer-service";


const FACEBOOK = "https://www.facebook.com/people/Varyns/61552901677975/";
const TWITTER = "https://twitter.com/varyns184780?t=ykVXgoULzbSugFnQ3twGdg&s=08";
const INSTAGRAM = "https://www.instagram.com/varyns2526/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"; 

export {
    HOME,
    CATALOGUE,
    LOGIN,
    REGISTER,
    CONDITION_OF_USE,
    PRIVACY_POLICY,
    VERIFY_MOBILE_NUMBER,
    PRODUCT,
    CART,
    TODAYSDEALS, BUY,
    NEW_ADDRESS,
    ADDRESS,
    PAYMENT,
    DELIVERY,
    PLACE_ORDER,
    ORDER_PLACED_SUCCESSFULLY,
    ORDER_PROCESSING,
    ORDERS,
    PAYMENT_FAILED,
    ORDER_DETAILS,
    RETRY_PAYMENT,
    ABOUT_US,
    TRACKING_ORDER,
    RETURNS,
    FACEBOOK,
    TWITTER,
    INSTAGRAM,
    RETURN_POLICY,
    FORGOT_PASSWORD,
    SEE_MORE,
    CUSTOMER_SERVICE,
};