import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getOrder, getProductDetails, orderDetails, scrollToTop } from "../assets";
import useQuery from "../hooks/use-query";

import CustomStepper from "../components/CustomStepper";
import FooterContainer from "../container/footer.container";
import Skeleton from "react-loading-skeleton";
import month from "../constant/months";
import week from "../constant/week";

export default function TrackingOrder() {
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);

    const endpoint = "https://varyns.onrender.com";
    const user = useSelector(state => state.user);
    const query = useQuery();
    const moveToTopRef = useRef(null);


    const generateStepperSteps = () => {
        const steps = [{ label: "Ordered " }, { label: 'Shipped ' }, { label: 'Out for delivery ' }, { label: 'Delivered ' }]
        for (let i = 0; i < order.shippingStatus.length; i++) {
            steps[i].label += generateDate(+order.shippingStatus[i].time);
        }
        return steps;
    }


    useEffect(() => {
        (async () => {
            const [data] = await getOrder(endpoint + `/orders?userId=${user._id}&orderId=${query.get("orderId")}&productId=${query.get("productId")}`, user.token);
            const [orderData] = await getProductDetails([{ product: data.productId }]);
            setOrder({ ...data, description: orderData.value.description, images: orderData.value.images, title: orderData.value.title });
            setLoading(false);
        })();
        scrollToTop(window);
    }, []);


    const generateDate = (createdAt) => {
        const date = new Date(createdAt);
        return `${week[date.getDay()]}, ${date.getDate()} ${month[date.getMonth()]}`
    };

    const generateHeader = () => {
        const { estimated_delivery_days, createdAt } = order;
        const date = new Date(createdAt);
        let difference = new Date().getTime() - date.getTime();
        difference = difference / (1000 * 3600 * 24);
        if ((estimated_delivery_days - difference) > 1) {
            let toBeDelivered = date.getDay() + estimated_delivery_days;
            date.setDate(toBeDelivered);
            return "Will be delivered on " + week[date.getDay()];
        } else {
            return "Will be delivered today"
        }
    }

    return (
        <div ref={moveToTopRef} className=" py-4" >
            {
                loading ? (
                    <Skeleton className="my-3" height="40px" />
                ) : (
                    <h1 className={`px-4 my-4 mb-6 ${order.cancelled ? "text-red-700" : "text-teal-500"} text-2xl font-medium`} >{order.cancelled ? "Cancelled" : order.delivered ? "Delivered" : generateHeader()}</h1>
                )
            }
            <section>
                {
                    loading ? (
                        <div className="p-4" >
                            <Skeleton className="my-3" height="20px" />
                            <Skeleton className="my-3" height="20px" />
                            <Skeleton className="my-3" height="20px" />
                            <Skeleton className="my-3" height="20px" />
                        </div>
                    ) : (
                        <div className="border-t-2 border-zinc-200 border-solid" >
                            <CustomStepper active={order.shippingStatus.length - 1} steps={generateStepperSteps()} />
                        </div>
                    )
                }
            </section >

            <section className="border-t-4 p-4 border-zinc-200 border-solid " >
                <p className="text-lg font-medium mb-1" >Delivery will be handled by Varyns</p>
                {/* <p className="text-[15px]" >Tracking Id: {"123456789123"}</p> */}
            </section>

            <section className="border-t-4 p-4 border-zinc-200 border-solid " >
                <p className="text-lg font-medium mb-2" >Shipping Address</p>
                {
                    loading ? (
                        <>
                            <Skeleton height="20px" className="mb-4" />
                            <Skeleton height="100px" />
                        </>
                    ) : (
                        <>
                            <p>{order.address[0].fullName}</p>
                            <p className="text-[15px]"  >
                                {order.address[0].apartment} {order.address[0].area},
                                <br />
                                {order.address[0].town} {order.address[0].state}
                                <br />
                                {order.address[0].pincode}
                            </p>
                        </>
                    )
                }
            </section>
            <section className="border-t-4 p-4 border-zinc-200 border-solid mb-4" >
                <p className="text-lg font-medium mb-2" >Billing Address</p>
                {
                    loading ? (
                        <>
                            <Skeleton height="20px" className="mb-4" />
                            <Skeleton height="100px" />
                        </>
                    ) : (
                        <>
                            <p>{order.address[0].fullName}</p>
                            <p className="text-[15px]"  >
                                {order.address[0].apartment} {order.address[0].area},
                                <br />
                                {order.address[0].town} {order.address[0].state}
                                <br />
                                {order.address[0].pincode}
                            </p>
                        </>
                    )
                }
            </section>
            <FooterContainer passedRef={moveToTopRef} />
        </div >
    );
}