import { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { Link, Outlet, useLocation } from "react-router-dom";
import { scrollToTop } from "../../assets";

export default function Buy() {

    const [step, setStep] = useState(0);

    const location = useLocation();

    useEffect(() => {
        const pathArr = location.pathname.split("/");
        const key = pathArr[pathArr.length - 1];
        switch (key) {
            case "address":
                setStep(0);
                break;
            case "delivery":
                setStep(1);
                break;
            case "payment":
                setStep(2);
                break;
            case "place-order":
                setStep(3);
                break;
            default: 
                setStep(0);
        }
        scrollToTop(window);
    }, [location]);

    return <div>
        <div className="flex bg-slate-300 z-[10]" >
            <Link to={"/"} className="cursor-pointer font-medium text-xl p-2 py-1" >Varyns</Link>
        </div>
        <Stepper
            style={{ padding: "10px 0", position: "sticky", top: '0', left: "0", backgroundColor: "#fff", borderBottom: "5px", borderStyle: "solid", borderColor: "#d3d3d3" }}
            activeStep={step}
            connectorStateColors="#bbbfbf"
            styleConfig={{ activeBgColor: "green", activeTextColor: "green", size: "1.25em", completedBgColor: "#007185", completedTextColor: "#007185", labelFontSize: "0.8rem", inactiveBgColor: "#f0f2f2", inactiveTextColor: "#6f7373" }}
            connectorStyleConfig={{ activeColor: "#007185", disabledColor: "#f0f2f2" }}
        >
            <Step label="Address" />
            <Step label="Delivery" />
            <Step label="Payment" />
            <Step label="Place order" />
        </Stepper>
        <Outlet />
    </div>
}