import Skeleton from "react-loading-skeleton"

export default function ProductGrouper({ title, products = [], handleProductClick }) {
    // if(products.length !== 4) return "Can't render";


    const renderStyle = (length) => {
        if (length === 4) {
            return "grid gap-2 grid-rows-[47%_47%] h-72 grid-cols-2"
        } else if (length === 3) {
            return "grid gap-2 grid-rows-[47%_47%] h-72 grid-cols-2"
        } else if (length === 2) {
            return " grid gap-2 grid-rows-1 h-72 grid-cols-2";
        } else {
            return "grid gap-2 grid-rows-1 h-72 grid-cols-1"
        }
    }

    return (
        <div className=" h-80 p-6 py-4 border-box " >
            <p className="pt-2 pb-2 text-xl font-bold col-start-1 col-end-3 row-start-1 row-end-2" >{title}</p>
            <div className={renderStyle(products.length)} >
                {products ?
                    products.map(({ value }) => (
                        <div key={value?._id} className="border-4 border-solid border-slate-200 rounded bg-white shadow-xl cursor-pointer p-3" onClick={() => handleProductClick(value._id)} >
                            {value ?
                                <img className="h-full object-contain w-full inline-block" src={value.images[0]} /> :
                                <div className="h-full w-full flex items-center justify-center" >
                                    <p>Not Available</p>
                                </div>
                            }
                        </div>
                    )) : <>
                        <Skeleton height={"110px"} count={1} />
                        <Skeleton height={"110px"} count={1} />
                        <Skeleton height={"110px"} count={1} />
                        <Skeleton height={"110px"} count={1} />
                    </>
                }
            </div>
        </div>
    )
}