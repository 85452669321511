import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faShareNodes, faLock, faTruckFast, faMoneyBill, faShield, faRecycle, faCircle } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Transition } from '@headlessui/react';
import ReactStars from "react-stars";
import { ColorRing } from "react-loader-spinner";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import FooterContainer from "../container/footer.container";
import CustomerReviewsContainer from "../container/customerReviews.container";
import { addToCart, addToCartAsync } from "../redux/cartSlice";
import { updateErr } from "../redux/errSlice";
import { addReviewByProductId, addUserHistory, getAddressFromPincode, getProduct, scrollToTop, getRelated } from "../assets";
import * as ROUTES from "../constant/routes";
import { updatePaymentData } from "../redux/paymentSlice";
import costCalculator from "../helpers/costCalculator";
import defaultAddress from "../helpers/defaultAddress";
import AddressSelector from "../container/address.selector.container";
import deliveryText from "../helpers/deliveryText";
import month from "../constant/months";
import week from "../constant/week";
import canReview from "../helpers/canReview";

export default function Product() {

    const offers = [0, 1, 2, 3, 4];
    const youMightAlsoLike = [0, 1, 2, 3, 4, 5];
    const [quantity, setQuantity] = useState(1);
    const [addressSelector, setAddressSelector] = useState({
        pincode: null,
        show: false,
        selected: null,
        pincodeEditor: false,
        pincodeAddress: null,
        deliveryTime: null,
        discount: 0
    });
    const [newReview, setNewReview] = useState({
        open: false,
        rating: 0,
    });
    const [reviews, setReviews] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState(null);

    const settings = {
        customPaging: function (i) {
            return (
                <a className="w-16 h-16 inline-block" >
                    <img className="inline-block h-12 w-12 object-contain" src={`${productDetail.images[i]}`} />
                </a>
            );
        },
        dots: true,
        infinite: true,
        dotsClass: "",
        speed: 500,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: dots => (
            <div >
                <ul className="mt-4 scrollbar scrollbar-none flex overflow-x-scroll mx-auto flex gap-x-4 justify-center overflow-hidden overflow-x-scroll"  > {dots.map(item => <div className="cursor-pointer inline-block h-16 w-16 p-2 rounded border-2 border-solid border-slate-200 relative shrink-0" >{item}</div>)} </ul>
            </div>
        ),
    };

    const [savingInCartLoader, setSavingInCartLoader] = useState(false);
    const [productDetail, setProductDetail] = useState({
        bullet_points: null,
        category: null,
        cost: null,
        description: null,
        images: null,
        product_details: null,
    });

    const { id } = useParams();
    const { user, cart, orders } = useSelector(state => ({ user: state.user, cart: state.cart, orders: state.orders }));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const endPoint = "https://varyns.onrender.com/"
    const moveToTopRef = useRef();

    // only for clothing

    const [selectedSize, setSelectedSize] = useState("");
    const [selectedColor, setSelectedColor] = useState("");

    const handleSize = (size) => {
        setSelectedSize(prevState => prevState.name === size.name ? "" : size);
    }

    const handleColor = (color) => {
        setSelectedColor(prevState => prevState.name === color.name ? "" : color);
    }

    const handleCart = useCallback(() => {
        if (cart.map(item => item.productId).includes(id)) {
            dispatch(updateErr("Product already exist in your cart"));
        } else {
            if (productDetail.sizes.length > 0) {
                if (selectedSize === "" && productDetail?.sizes.length > 0) {
                    alert("Select size!");
                    return "";
                }
            }

            if (productDetail.colors.length > 0) {
                if (selectedColor === "" && productDetail?.colors.length > 0) {
                    alert("Select color!");
                    return "";
                }
            }
            setSavingInCartLoader(true);
            if (!user) {
                const productToBeAdded = { productId: id, quantity };
                if ((productDetail.sizes.length > 0) && (productDetail.colors.length > 0)) {
                    productToBeAdded.size = selectedSize;
                    productToBeAdded.color = selectedColor;
                } else if (productDetail.sizes.length > 0) {
                    productToBeAdded.size = selectedSize;
                } else if (productDetail.colors.length > 0) {
                    productToBeAdded.color = selectedColor;
                }
                dispatch(addToCart(productToBeAdded));
                setTimeout(() => {
                    setSavingInCartLoader(false);
                }, 500);
            } else {
                const product = { productId: id, quantity };

                if ((productDetail.sizes.length > 0) && (productDetail.colors.length > 0)) {
                    product.size = selectedSize;
                    product.color = selectedColor;
                } else if (productDetail.sizes.length > 0) {
                    product.size = selectedSize;
                } else if (productDetail.colors.length > 0) {
                    product.color = selectedColor;
                }
                dispatch(addToCartAsync({ userId: user._id, productArr: [product], token: user.token }))
                    .then(() => setSavingInCartLoader(false));
            }
        }
    }, [selectedSize, productDetail, quantity, selectedColor]);


    const handleBuyNow = useCallback(() => {
        if (user) {
            if (productDetail.sizes.length > 0) {
                if (selectedSize === "" && productDetail?.sizes.length > 0) {
                    alert("Select size!");
                    return "";
                }
                if (productDetail.colors.length > 0) {
                    if (selectedColor === "" && productDetail?.colors.length > 0) {
                        alert("Select color!");
                        return "";
                    }
                }
            }
            dispatch(updatePaymentData(
                (productDetail.sizes.length > 0) && (productDetail.colors.length > 0) ?
                    { quantity, selectedSize: selectedSize === "" ? {} : selectedSize, selectedColor: selectedColor === "" ? {} : selectedColor }
                    :
                    (productDetail.sizes.length > 0)
                        ? { quantity, selectedSize: selectedSize === "" ? {} : selectedSize }
                        : productDetail.colors.length > 0
                            ? { quantity, selectedColor: selectedColor === "" ? {} : selectedColor }
                            : { quantity }));
            const route = ROUTES.ADDRESS + "?buynow=true&order=" + id;
            setSelectedSize("");
            setSelectedColor("");
            navigate(route);
        } else {
            navigate(ROUTES.LOGIN);
        }
    }, [selectedSize, productDetail, selectedColor]);

    const toggleAddressSelector = (e) => {
        e?.stopPropagation();
        setAddressSelector(prevState => ({ ...prevState, show: !prevState.show }))
    }

    const handleSelectAddress = (selected) => {
        if (user) {
            setAddressSelector(prevState => ({ ...prevState, selected, show: false, pincodeAddress: null }));
        }
    };

    const handleChangePincode = ({ target }) => {
        const { value } = target;
        if (!isNaN(value)) {
            setAddressSelector(prevState => ({ ...prevState, pincode: value }));
        }
    }

    const handleChoosePincode = (e) => {
        setAddressSelector(prevState => ({ ...prevState, pincodeEditor: !prevState.pincodeEditor }));
    }

    const handlePincode = useCallback(async (e) => {
        e.preventDefault();
        if (addressSelector.pincode.length >= 6) {
            const res = await getAddressFromPincode(addressSelector.pincode);
            if (res) {
                setAddressSelector(prevState => ({ ...prevState, pincodeAddress: res, pincode: null, pincodeEditor: false, show: false }));
            }
        }
    }, [addressSelector]);

    const writeReview = (action, value) => {
        setNewReview(prevState => {
            switch (action) {
                case "open":
                    return { ...prevState, open: true };
                case "close":
                    return { ...prevState, open: false };
                case "description":
                    return { ...prevState, description: value };
                case "rating":
                    return { ...prevState, rating: value };
                case "loader":
                    return { ...prevState, loader: value };
                default: return prevState
            }

        })

    };

    const handleShare = async ({ title, _id }) => {
        const shareData = {
            title: "Varyns",
            text: title,
            url: `https://www.varyns.com/catalogue/product/${_id}`
        }
        try {
            if (navigator.canShare(shareData)) {
                const res = await navigator.share(shareData);
            }
        } catch (err) {
            console.log("Your browser does not support this feature.")
        }
    }

    const handleReview = useCallback(async e => {
        e.preventDefault();
        if (newReview.description) {
            writeReview("loader", true);
            const { rating, description } = newReview;
            const res = await addReviewByProductId(`${endPoint}review`, { userId: user._id, name: user.fullName, description, rating, productId: id }, user.token);
            if (res) {
                setNewReview({
                    open: false,
                    rating: 0,
                    loader: false
                });
                setReviews(res);
            }
        }
    }, [newReview]);

    const handleQuantity = (shouldIncrease) => {
        setQuantity(prevState => {
            if (prevState === 1 && !shouldIncrease) return prevState;
            return shouldIncrease ? prevState + 1 : prevState - 1
        });
    }

    useEffect(() => {
        if (productDetail && productDetail.subCategory) {
            (async () => {
                const related = await getRelated(`${endPoint}catalogue/related-products?key=${productDetail.subCategory ? productDetail.subCategory : "null"}`);
                setRelatedProducts(related);
            })()
        }
    }, [productDetail]);

    useEffect(() => {
        (async () => {
            const product = await getProduct(id);
            setProductDetail(prevState => ({ ...prevState, ...product }));

            if (user) {
                const data = await addUserHistory(user.token, user._id, id);
            }
        })();
        scrollToTop(window);
    }, [id]);

    const selectedAddress = defaultAddress(user ? user.address.length > 0 ? user.address : null : null);
    const { text: renderDeliveryText, pincode } = deliveryText(addressSelector, user, selectedAddress);

    useEffect(() => {
        if (pincode) {
            (async () => {
                try {
                    const response = await fetch(`${endPoint}courier-availability?cod=${1}&delivery_postcode=${pincode}&weight=${'0.8'}`, {
                        type: 'GET',
                        // headers: {
                        //     'x-access-token': user.token,
                        // }
                    })
                    const data = await response.json();
                    setAddressSelector(prevState => ({ ...prevState, deliveryTime: data.etd ? new Date(data.etd) : "Not Available" }))
                } catch (err) {
                    dispatch(updateErr(err.message));
                }
            })();
        }
    }, [pincode]);

    return (
        <div ref={moveToTopRef} className="relative" >
            <button onClick={toggleAddressSelector} className="bg-slate-400 h-10 flex items-center px-3 w-full" >
                <FontAwesomeIcon icon={faLocationDot} size="lg" />
                <p className="cursor-pointer font-medium ml-2 text-sm leading-0" >
                    {
                        renderDeliveryText
                    }
                </p>
            </button>
            {canReview(orders, id, reviews, user ? user._id : null) && (
                <div className="bg-slate-200" >
                    <p className="px-2 pt-2 text-center" >You Have recently bought this product. Tell us what you think.</p>
                    <button onClick={() => writeReview("open")} className="border border-solid border-slate-300 rounded-lg w-[calc(100%-18px)] border-box m-2 p-2 px-4 font-medium text-left" >Write a review</button>
                </div>
            )}
            <div className="relative py-6 px-4" >
                <Slider {...settings} >
                    {productDetail.images ? productDetail.images.map((src) => <div key={src} className="h-[450px] flex items-center" >
                        <img className="h-full w-full object-contain" src={src} alt="" />
                    </div>) : <Skeleton count={1} height={"350px"} />}
                </Slider>
                {<span className="flex justify-center items-center text-sm font-semibold bg-red-700 rounded-full text-white h-11 w-11 absolute top-4 left-4 leading-none" >{productDetail.discount > 0 ? productDetail.discount : Math.round((productDetail.mrp - productDetail.cost) / productDetail.mrp * 100)}% <br /> off</span>}
                <button onClick={() => handleShare(productDetail)} className="flex justify-center items-center rounded-full border border-solid border-slate-300 w-11 h-11 absolute top-4 right-4 bg-white" >
                    <FontAwesomeIcon icon={faShareNodes} size="xl" />
                </button>
            </div>
            {
                productDetail.title ? (
                    <p className="mb-3 mt-10 leading-snug text-medium text-slate-600 text-justify px-2" >{productDetail.title}</p>
                ) : (
                    <Skeleton count={4} height={"20px"} />
                )
            }
            <div className="py-3 px-2 border-2 border-solid border-slate-400 border-l-0 border-r-0" >
                {/* <p className="text-left" >Style Name: <span className="font-semibold" >67W</span></p> */}
                {
                    productDetail.sizes && productDetail.sizes.length > 0 && (
                        <div className="flex" >
                            <p className="mr-2 leading-none mt-1 mr-6" >Size :</p>
                            <div>
                                {productDetail.sizes.map(item => (
                                    <button key={item.name} onClick={() => handleSize(item)} className={`${selectedSize.name === item.name ? "bg-slate-300" : "bg-white"} text-sm text-medium border border-solid mr-2 border-slate-300 rounded py-1 px-4 capitalize`} >{item.name}</button>
                                ))}
                            </div>
                        </div>
                    )
                }
                {
                    productDetail.colors && productDetail.colors.length > 0 && (
                        <div className="flex mt-6 items-center" >
                            <p className="mr-2 leading-none" >Colors :</p>
                            <div>
                                {productDetail.colors.map(item => (
                                    <button
                                        key={item.name}
                                        onClick={() => handleColor(item)}
                                        className={`${selectedColor.name === item.name ? "border-black border-2" : "border border-gray-300"} border-solid text-sm text-medium border border-solid mr-2 rounded-full p-4`}
                                        style={{ backgroundColor: item.name }}
                                    ></button>
                                ))}
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="flex flex-col my-2 px-2" >
                <p className="text-red-700 font-bold" >Best price on the Entire Planet</p>
                <div className="flex " >
                    <span className="text-red-700 text-[37px] leading-none" >-{productDetail.discount > 0 ? productDetail.discount : Math.round((productDetail.mrp - productDetail.cost) / productDetail.mrp * 100)}%</span>
                    <p className="text-[37px] leading-none relative ml-3" ><span className="text-sm font-semibold absolute -top-1 -left-2" >&#8377;</span>{selectedSize ? Math.round(costCalculator(productDetail.discount, productDetail.cost) * (+selectedSize.price)) : costCalculator(productDetail.discount, productDetail.cost)}</p>
                </div>
                <small className="mt-1 text-xs font-medium text-slate-600" >M.R.P <s>&#8377; {productDetail.mrp ? productDetail.mrp : productDetail.cost}</s></small>
            </div>
            {/* <div className="p-2 border-2 border-solid border-slate-400 border-l-0 border-r-0" >
                <div className="flex items-center" >
                    <p className="font-bold flex justify-center items-center rounded-full w-5 h-5 border border-solid border-red-600" >%</p>
                    <span className="font-bold ml-4" >Offers</span>
                </div>
                <div className="flex gap-x-4 overflow-x-scroll mt-2 " >
                    {offers.map(offer => <div key={offer} className="shrink-0 w-36 h-20 border-2 border-solid border-red-100 rounded-lg" ></div>)}
                </div>
            </div> */}
            <div className="my-5 px-2" >
                <p className="text-teal-600 text-sm" >
                    FREE delivery {addressSelector.deliveryTime ? "by" : ""} <span className="font-semibold text-black" >{addressSelector.deliveryTime ? `${week[addressSelector.deliveryTime.getDay()]}, ${addressSelector.deliveryTime.getDate()} ${month[addressSelector.deliveryTime.getMonth()]}` : ""} </span>
                </p>
                <button onClick={toggleAddressSelector} className="flex items-center w-full mt-2" >
                    <FontAwesomeIcon icon={faLocationDot} size="lg" />
                    <p className="text-teal-600 ml-2 text-sm leading-0" >
                        {
                            renderDeliveryText
                        }
                    </p>
                </button>
            </div>
            {
                productDetail && productDetail.quantity > 0 ? (
                    <>
                        <div className="mx-2 mb-5 w-[85px] shadow-lg flex justify-between items-center rounded-md border border-solid border-slate-400" >
                            <button disabled={quantity === 1} onClick={() => handleQuantity(false)} className={`p-2 py-1 font-semibold text-lg leading-none ${quantity === 1 ? "cursor-not-allowed" : "cursor-pointer"}`} >-</button>
                            <small className="whitespace-nowrap font-semibold" >Qty: {quantity}</small>
                            <button onClick={() => handleQuantity(true)} className="cursor-pointer p-2 py-1 font-semibold text-lg leading-none" >+</button>
                        </div>
                        <div className="mx-2" >
                            <button disabled={savingInCartLoader} onClick={handleCart} className="py-[10px] font-medium text-sm rounded block w-full bg-yellow-400" >{savingInCartLoader ? "Adding..." : "Add to Cart"}</button>
                            <button onClick={handleBuyNow} className="my-3 py-[10px] rounded font-medium text-sm block w-full bg-amber-600" >Buy Now</button>
                        </div>
                    </>
                ) : (
                    <p className="text-red-700 text-2xl p-2 mb-4" >Out of Stock</p>
                )
            }
            <small className="text-teal-600 px-2" >
                <FontAwesomeIcon className="text-gray-400 mr-3" icon={faLock} />
                Secure transaction
            </small>
            <p className="m-2 text-left text-[13px]  font-medium" >
                Sold by <span className="text-sky-700" >Va</span> and <span className="text-sky-700" >Fulfilled by our Delivery Partner</span>.
            </p>
            <div className="py-4 flex justify-evenly border-2 border-solid border-slate-400 border-l-0 border-r-0" >
                <div className="flex shrink-none flex-col max-w-[65px] justify-center items-center" >
                    <FontAwesomeIcon icon={faTruckFast} size="xl" />
                    <small className="text-sky-700 text-xs text-center font-medium leading-none mt-1" >Free Delivery</small>
                </div>
                <div className="flex shrink-none flex-col max-w-[65px] justify-center items-center" >
                    <FontAwesomeIcon icon={faMoneyBill} size="xl" />
                    <small className="text-sky-700 text-xs text-center font-medium leading-none mt-1" >Cash/Pay on Delivery</small>
                </div>
                <div className="flex shrink-none flex-col max-w-[65px] justify-center items-center" >
                    <FontAwesomeIcon icon={faRecycle} size="xl" />
                    <small className="text-sky-700 text-xs text-center font-medium leading-none mt-1" >7 days Replacement</small>
                </div>
                {/* <div className="flex shrink-none flex-col max-w-[65px] justify-center items-center" >
                    <FontAwesomeIcon icon={faShield} size="xl" />
                    <small className="text-sky-700 text-xs text-center font-medium leading-none mt-1" >Cash/Pay on Delivery</small>
                </div> */}
            </div>
            <div className="my-4 mb-2 p-2  border-2 border-solid border-slate-400 border-l-0 border-r-0" >
                <p className="font-bold text-medium text-left mb-1" >Product details</p>
                <div className="flex flex-col" >
                    {productDetail.product_details?.map(({ label, value }) =>
                    (
                        <div className="w-full mb-[6px]" key={value} >
                            <p className="w-1/2 inline-block text-slate-600 text-sm font-medium" >{label}</p>
                            <p className="w-1/2 inline-block text-black text-sm font-medium" >{value}</p>
                        </div>
                    )
                    )}
                </div>
                <div className="border-t border-t-solid border-slate-400" >
                    <ul className="p-4" >
                        {
                            productDetail.bullet_points ? (
                                productDetail?.bullet_points.map(item => (
                                    <li className="text-sm mb-2 list-disc " key={item} >{item}</li>
                                ))
                            ) : <>
                                <Skeleton className="text-sm mb-2 list-disc " />
                                <Skeleton className="text-sm mb-2 list-disc " />
                                <Skeleton className="text-sm mb-2 list-disc " />
                            </>
                        }
                    </ul>
                </div>
            </div>
            {relatedProducts && (
                <div className="py-1 px-2" >
                    <p className="font-bold text-xl mb-2 leading-tight" >You might also like</p>
                    <div className="scrollbar scrollbar-thumb-slate-300 scrollbar-track-slate-100 scrollbar-thin flex overflow-x-scroll gap-x-5" >
                        {
                            relatedProducts.map(product => (
                                <Link to={"/catalogue/product/" + product._id} key={product._id} className="border-4 shadow-xl bg-white border-slate-200 rounded-md border-solid w-44 h-72 inline-block shrink-0" >
                                    <img src={product.images && product.images[0]} alt={product.title} className="object-contain w-full block h-[60%] bg-blend-luminosity p-2" />
                                    <div className=" px-2 pt-2 bg-slate-200 h-[40%]" >
                                        {/* <p className="text-sm" >Deal of the Day</p> */}
                                        <p className="traking-sm leading-tighter font-[400] w-full h-10 text-left text-sm pt-1 text-red-600 overflow-hidden text-ellipsis " >{product.title}</p>
                                        <div className="flex items-center mt-1" >
                                            <p className="text-[14px]" >MRP:<s className=" text-gray-700" >{product.mrp ? product.mrp : product.cost}</s></p>
                                            <p className="text-[22px] relative px-2" >&#x20B9;{product.cost}<span className="text-[11px] absolute top-[5px]" >00</span></p>
                                        </div>
                                    </div>
                                </Link>
                                // <Link to={"/catalogue/product/" + product._id} key={product._id} className="border-4 bg-white border-slate-200 rounded-md border-solid w-36 h-52 inline-block shrink-0" >
                                //     <img src={product.images && product.images[0]} alt={product.title} className="object-contain w-full block h-[84%] bg-blend-luminosity p-2" />
                                //     <p className="bg-slate-200 traking-sm font-[400] w-full h-8 text-md pt-1 px-2 overflow-hidden text-ellipsis" >{product.title}</p>
                                // </Link>
                            ))
                        }
                    </div>
                </div>
            )}
            <div className="px-2 my-4 py-2 border-2 border-solid border-slate-300 border-l-0 border-r-0" >
                <p className="font-bold text-left " >Description</p>
                <p className="my-4" >{productDetail.description}</p>
            </div>
            <div className="px-2 my-4 py-2 border-2 border-solid border-slate-300 border-l-0 border-r-0" >
                <p className="font-bold text-left " >Product image gallery</p>
                {
                    productDetail.images ? productDetail.images.map(src => <img src={src} alt="" key={src + "product_image_gallery"} className="inline-block object-contain h-72 w-full mb-4" />) : <>
                        <Skeleton className="inline-block object-cover h-72 w-full mb-4" />
                        <Skeleton className="inline-block object-cover h-72 w-full mb-4" />
                        <Skeleton className="inline-block object-cover h-72 w-full mb-4" />
                    </>
                }
            </div>
            {/* <CustomerQuestions questionsArr={questionsArr} /> */}
            <CustomerReviewsContainer reviews={reviews} setReviews={setReviews} writeReview={writeReview} productId={id} userId={user ? user._id : null} />
            <FooterContainer passedRef={moveToTopRef} />
            <Transition
                show={addressSelector.show}
            // show={false}
            >
                <Transition.Child
                    onClick={toggleAddressSelector}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.4)] w-screen h-screen z-[10]"
                >

                </Transition.Child>
                <Transition.Child
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                    className="fixed bottom-0 left-0 right-0 z-[11]"
                >
                    <AddressSelector handlePincode={handlePincode} pincode={addressSelector.pincode} handleChoosePincode={handleChoosePincode} handleChangePincode={handleChangePincode} handleSelectAddress={handleSelectAddress} pincodeEditor={addressSelector.pincodeEditor} selected={addressSelector.selected} user={user} />
                </Transition.Child>
            </Transition>
            {
                user && (
                    <Transition
                        show={newReview.open}
                    // show={false}
                    >
                        <Transition.Child
                            onClick={() => writeReview("close")}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.4)] w-screen h-screen z-[10]"
                        >

                        </Transition.Child>
                        <Transition.Child
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                            className="fixed bottom-0 left-0 right-0 z-[11] bg-white h-[calc(100vh-52px)]"
                        >
                            <form onSubmit={handleReview} className="p-4" >
                                {
                                    productDetail.images && <div className="flex items-center" >
                                        <img className="h-12 w-12 object-contain" src={productDetail.images[0]} alt={productDetail.title} />
                                        <p className="px-4 overflow-hidden ellipsis whitespace-nowrap" >{productDetail.title}</p>
                                    </div>
                                }
                                <h3 className="font-semibold my-2" >How would you rate it?</h3>
                                <div className="flex items-center mb-2" >
                                    <ReactStars
                                        count={5}
                                        edit={true}
                                        value={newReview.rating}
                                        size="55"
                                        color2={'#ffd700'}
                                        onChange={(new_rating) => writeReview("rating", new_rating)}
                                    />
                                    <button className="ml-5 mt-2 text-teal-600 font-medium" onClick={() => newReview.rating !== 0 && writeReview("rating", 0)} >Clear</button>
                                </div>
                                <p className="font-semibold my-2" >Write your review</p>
                                <textarea required className="p-2 w-full h-56 border border-solid border-slate-300" placeholder="Write genuine thoughts after using the product?" value={newReview.description} onChange={e => writeReview("description", e.target.value)} />
                                <div className="justify-end my-5 flex" >
                                    <button disabled={newReview.loader} type="submit" className="relative border border-solid border-gray-300 rounded-md px-16 py-3 bg-yellow-400" >
                                        {
                                            newReview.loader && (
                                                <span className="rounded-md absolute bg-[rgba(0,0,0,0.3)] top-0 left-0 w-full h-full flex justify-center items-center" >
                                                    <ColorRing
                                                        visible={true}
                                                        height="52"
                                                        width="52"
                                                        ariaLabel="blocks-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass="blocks-wrapper"
                                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                                    />
                                                </span>
                                            )
                                        }
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </Transition.Child>
                    </Transition>
                )
            }
        </div>
    )
}