import { useEffect, useState, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import useQuery from "../hooks/use-query";
import { getDeals, scrollToTop } from "../assets";
import { updateErr } from "../redux/errSlice";
import FooterContainer from "../container/footer.container";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SeeMore() {

    const [products, setProducts] = useState(null);
    const query = useQuery();
    const endPoint = "https://varyns.onrender.com";
    const dispatch = useDispatch();
    const moveToTopRef = useRef(null);
    const navigate = useNavigate();


    const generateHeading = (query) => {
        let heading;
        if (query.get("key")) {
            heading = query.get("key");
        } else if (query.get("category")) {
            heading = query.get("category");
        } else if (query.get("subCategory")) {
            heading = query.get("subCategory");
        }
        return heading;
    }

    const handleBack = () => navigate("/");

    const key = query.get("key");
    const category = query.get("category");
    const subCategory = query.get("subCategory");


    useEffect(() => {
        (async () => {
            let api_address = endPoint;
            if (query.get("key")) {
                api_address += "/catalogue/see-more?name=" + key;
            } else if (query.get("category")) {
                api_address += "/catalogue/see-more?category=" + category;
            } else if (query.get("subCategory")) {
                api_address += "/catalogue/see-more?subCategory=" + subCategory;
            }

            const response = await getDeals(api_address, query.get("key") ? "name" : "not name");
            if (response) {
                setProducts(query.get("key") ? response.products : response);
            } else {
                dispatch(updateErr("Something Went Wrong!"));
            }
        })();
        scrollToTop(window);
    }, [key, category, subCategory]);

    return (
        <div ref={moveToTopRef} className="" >
            <button onClick={handleBack} className="mx-3 mt-6 font-medium" >
                <FontAwesomeIcon className="mr-1" icon={faChevronLeft} size="xs" />
                Back
            </button>
            <h1 className="p-4 text-3xl mb-3 capitalize font-medium" >{generateHeading(query)}</h1>
            <div className="p-4 mb-2 pt-0 gap-4 grid grid-cols-[repeat(auto-fill,minmax(180px,1fr))]" >
                {
                    products ? products.length > 0 ? (
                        products.map(deal =>
                            <Link to={"/catalogue/product/" + deal._id} key={deal._id} className="border-4 shadow-xl bg-white border-slate-200 rounded-md border-solid  h-72 inline-block shrink-0" >
                                <img src={deal.images && deal.images[0]} alt={deal.title} className="object-contain w-full block h-[70%] bg-blend-luminosity p-2" />
                                <div className=" px-2 pt-2 bg-slate-200 h-[30%]" >
                                   
                                    <p className="traking-sm leading-tighter font-[400] w-full h-10 text-left text-sm pt-1 text-red-600 overflow-hidden text-ellipsis underline" >{deal.title}</p>
                                    <div className="flex items-center mt-1" >
                                        <p className="text-[14px]" >MRP:<s className=" text-gray-700" >&#x20B9;{deal.mrp ? deal.mrp : deal.cost}</s></p>
                                        <p className="text-lg relative px-2" ><span className="text-[11px] absolute -top-[2px] left-[2px]" >&#x20B9;</span>{deal.cost}<span className="text-[11px] absolute -top-[2px]" >00</span></p>
                                    </div>
                                </div>
                            </Link>
                        )
                    ) : (
                        <p className="text-lg my-6 text-center col-start-1 col-end-3" >No Products Available </p>
                    ) : (
                        <>
                            <Skeleton height="250px" />
                            <Skeleton height="250px" />
                            <Skeleton height="250px" />
                        </>
                    )
                }
            </div>
            <FooterContainer passedRef={moveToTopRef} />
        </div>
    );
}