import { ColorRing } from "react-loader-spinner";

const CustomLoader = () => {
    return (
        <span className="rounded-md absolute bg-[rgba(0,0,0,0.3)] top-0 left-0 w-full h-full flex justify-center items-center" >
            <ColorRing
                visible={true}
                height="32"
                width="32"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
        </span>
    );
}

export default CustomLoader;