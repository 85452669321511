import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { registerUserAsync, sendOtpAsync } from '../redux/userSlice';
import * as ROUTES from '../constant/routes';
import { scrollToTop } from '../assets';
import CustomLoader from '../components/CustomLoader';
import { updateErr } from '../redux/errSlice';


export default function VerifyMobileNumber() {

    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingResend, setLoadingResend] = useState(false);

    const [disableResend, setDisableResend] = useState(true);
    const [resendTimer, setResendTimer] = useState(1);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    !state && navigate(ROUTES.REGISTER);
    const { mobileNumber, fullName, password } = state;

    const disabled = (otp.length !== 6) || !mobileNumber || !fullName || !password;

    const handleChange = ({ target }) => {
        const { value } = target;
        setOtp(value);
    }

    const startTimer = useCallback(() => {
        const timer = setInterval(() => {
            setResendTimer(prevState => {
                if (prevState >= 30) {
                    clearInterval(timer);
                    setDisableResend(false);
                    return prevState;

                } else {
                    return prevState + 1;
                }
            })
        }, 1000);
    }, [resendTimer]);

    const resendOtp = async () => {
        setLoadingResend(true)
        dispatch(sendOtpAsync({ mobileNumber })).then(() => {
            setLoadingResend(false);
            startTimer();
            setDisableResend(true);
        });
    };

    const handleRegister = (otp) => {
        setLoading(true);
        dispatch(
            registerUserAsync({
                ...state,
                otp: otp
            })
        ).then((res) => {
            setLoading(false);
            dispatch(updateErr("You have been successfully registered!. You will be redirected shortly."))
            navigate(ROUTES.HOME);
        }).catch(err => {
            setLoading(false);
            setOtp('');
            dispatch(updateErr("Incorrect OTP"));
        })
    };

    useEffect(() => {
        scrollToTop(window);
        if (resendTimer === 1) {
            startTimer();
            setDisableResend(true);
        }
    }, []);


    return (
        <div className='p-4' >
            <h1 className="text-2xl font-bold my-3" >Verify mobile number</h1>
            <h3 className="font-semibold mb-3" >IN +91 {mobileNumber} (<span onClick={() => navigate(-1)} className="text-sky-600" >Change</span>)</h3>
            <input placeholder="Enter OTP" className="h-12 w-full p-2 rounded-sm block my-3 bg-slate-300" onChange={handleChange} value={otp} />
            <button disabled={disabled} className={`relative w-full h-11 rounded-sm ${disabled ? "cursor-not-allowed bg-sky-200" : "cursor-pointer bg-sky-300"}`} onClick={() => handleRegister(otp)} >
                Create your Va account
                {
                    loading && <CustomLoader />
                }
            </button>
            <small className='leading-tight block mt-2' >By creating an account or logging in, you agree to Va's <Link to={ROUTES.CONDITION_OF_USE} className="text-sky-600" >Conditions of Use</Link> and <Link to={ROUTES.PRIVACY_POLICY} className="text-sky-600" >Privacy Policy</Link>.</small>
            <button disabled={disableResend} onClick={resendOtp} className='w-full bg-slate-300 h-10 px-4 flex justify-between items-center mt-2 text-sm font-semibold' >
                Resend OTP
                <span>&#62;</span>
            </button>
        </div>
    )
}