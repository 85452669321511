const endPoint = "https://varyns.onrender.com";

const getProductDetails = async (data) => {
    const arr = await Promise.allSettled(data.map(async ({ product }) => {
        try {
            const res = await fetch(endPoint + "/catalogue?productId=" + product);
            if (res.ok) {
                const data = await res.json();
                const discountRes = await fetch(endPoint + "/catalogue/discounted?productId=" + product)
                const response = await discountRes.json();
                const discountData = response ? response[0] : null;
                return { ...data, discount: discountData ? discountData.discount : 0 };
            }
            return null;
        } catch (err) {
            throw err.message;
        }
    }))
    return arr;
};


const getProduct = async (id) => {
    try {
        const res = await fetch(endPoint + "/catalogue?productId=" + id);
        if (res.ok) {
            const data = await res.json();
            const discountRes = await fetch(endPoint + "/catalogue/discounted?productId=" + id)
            const response = await discountRes.json();
            const discountData = response ? response[0] : null;
            return { ...data, discount: discountData ? discountData.discount : 0 };
        }
        return res;
    } catch (err) {
        return err;
    }
}


const getHomepage = async (api_address) => {
    try {
        const response = await fetch(api_address);
        const data = await response.json();
        if (response.ok) {
            return data;
        }
        return null;
    } catch (err) {
        return err.message
    }
}


const getDeals = async (api_address, type) => {
    try {
        const response = await fetch(api_address);
        let data = await response.json();
        if (response.ok) {
            if (type === "name") {
                const arr = await Promise.all(data.products.map(async (item) => await getProduct(item)))
                data.products = [...arr];

            }

            return data;
        }
        return null;
    } catch (err) {
        return err.message
    }
}

const userBought = async (api_address, token) => {
    try {
        const response = await fetch(api_address, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        const bought = await response.json();
        if (response.ok) {
            const productList = await Promise.allSettled(bought.reverse().slice(0, 4).map(async ({ productId }) => {
                try {
                    const res = await fetch(endPoint + "/catalogue?productId=" + productId);
                    const data = await res.json();
                    return data;
                } catch (err) {
                    throw err
                }
            }))
            return productList;
        }
    } catch (err) {
        return err.message
    }

}

const getHomepageData = async (api_address, token) => {
    const response = await fetch(api_address, {
        method: "GET",
        headers: {
            "x-access-token": token
        }
    });
    const dataHistory = await response.json();
    if (response.ok) {
        const productList = await Promise.allSettled(dataHistory.productList.slice(0, 4).map(async product => {
            try {
                const res = await fetch(endPoint + "/catalogue?productId=" + product);
                const data = await res.json();
                return data;
            } catch (err) {
                throw err
            }
        }))
        return productList;
    }
    return null;

}

const getDiscountedProducts = async (api_address) => {
    const response = await fetch(api_address, {
        method: "GET"
    });
    const data = await response.json();
    if (response.ok) {
        const productList = (await getProductDetails(data)).map(item => item.value);
        return productList;
    }
    return null;
}

const getTodaysDeals = async (api_address) => {
    const response = await fetch(api_address, {
        method: "GET"
    });
    const data = await response.json();
    if (response.ok) {
        const productList = (await getProductDetails(data)).map(item => item.value);
        return productList.map((item, index) => ({ ...item, discount: data[index].discount }));
    }
    return null;
}

const getProductsBySlug = async (api_address) => {
    const response = await fetch(api_address, {
        method: "GET"
    });
    const data = await response.json();
    if (response.ok) {
        return data;
    }

    return null;
}

const orderDetails = async (api_address, token, body) => {
    try {
        const response = await fetch(api_address, {
            method: "POST",
            headers: {
                "x-access-token": token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...body }),

        })
        const data = await response.json();
        return { ...data };
    } catch (err) {
        return err;
    }
}

const authenticatePayment = async (api_address, razorpay, token) => {
    try {
        const res = await fetch(api_address, {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
                'x-access-token': token
            },
            body: JSON.stringify({
                ...razorpay
            })
        })
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            return null
        }
    } catch (err) {
        return err.message;
    }
}

const authenticatePod = async (api_address, doc_id, token) => {
    try {
        const res = await fetch(api_address, {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
                'x-access-token': token
            },
            body: JSON.stringify({ doc_id })
        })
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            return null
        }
    } catch (err) {
        return err.message;
    }
}

const orders = async (api_address, token) => {
    try {
        const res = await fetch(api_address, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });

        const data = await res.json();
        return data;
    } catch (err) {
        return err.message;
    }
}

const getEligibleReturns = async (api_address, token) => {
    try {
        const res = await fetch(api_address, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });

        const data = await res.json();
        return data;
    } catch (err) {
        return err.message;
    }
}


const getOrder = async (api_address, token) => {
    try {
        const res = await fetch(api_address,
            {
                method: "GET",
                headers: {
                    "x-access-token": token
                }
            }
        );
        if (res.ok) {
            const data = await res.json();
            return data;
        }
        return null;
    } catch (err) {
        throw err.message;
    }
};


const addUserHistory = async (token, userId, productId) => {
    try {
        const res = await fetch(endPoint + "/user-history", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": token
            },
            body: JSON.stringify({
                userId,
                productId
            })
        })
        if (res.ok) {
            return await res.json();
        }
        return res;
    } catch (err) {
        return err.message;
    }
}

const scrollToTop = (window) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

const getAddressFromPincode = async (pincode) => {
    try {
        const res = await fetch(endPoint + "/address?pincode=" + pincode);
        if (res.ok) {
            const [data] = await res.json();
            return data;
        }
    } catch (err) {
        return { message: err.message, ok: false };
    }
}

const getReviewsByProductId = async (api_address) => {
    try {
        const res = await fetch(api_address,
            {
                method: "GET",
            }
        );
        if (res.ok) {
            const data = await res.json();
            return data;
        }
        return null;
    } catch (err) {
        throw err.message;
    }
};

const addReviewByProductId = async (api_address, review, token) => {
    try {
        const res = await fetch(api_address,
            {
                method: "POST",
                headers: {
                    "x-access-token": token,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(review)
            }
        );
        if (res.ok) {
            const data = await res.json();
            return data;
        }
        return null;
    } catch (err) {
        throw err.message;
    }
};

const getRatingByProductId = async (api_address, token) => {
    try {
        const res = await fetch(api_address,
            {
                method: "GET",
                headers: {
                    "x-access-token": token
                }
            }
        );
        if (res.ok) {
            const data = await res.json();
            return data;
        }
        return null;
    } catch (err) {
        throw err.message;
    }
};

const returnProduct = async (api_address, reason, token) => {
    try {
        const res = await fetch(api_address, {
            method: "POST",
            headers: {
                "x-access-token": token
            },
            body: JSON.stringify({ reason })
        });
        if (res.ok) {
            const data = await res.json();
            return data;
        }
        return null;
    } catch (err) {
        throw err.message;
    }
}

const verifyOtp = async (mobileNumber, otp) => {
    try {
        const res = await fetch(endPoint + "/verify-otp", {
            headers: { 'Content-Type': 'application/json' },
            method: "POST",
            body: JSON.stringify({
                mobileNumber,
                otp
            })
        })

        if (res.ok) {
            return res;
        }
        return null
    } catch (err) {
        throw err;
    }
}

const resetPassword = async (api_address, data) => {
    try {
        const res = await fetch(api_address, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...data
            })
        })

        if (res.ok) {
            return res;
        }

        return null

    } catch (err) {
        throw err;
    }
}

const addUserSearchToHistory = async (api_address, token) => {
    try {
        const res = await fetch(api_address, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        })
        if (res.ok) {
            const data = res.json();
            return data;
        }
        return null

    } catch (err) {
        throw err;
    }
}

const deleteUserSearchFromHistory = async (api_address, token) => {
    try {
        const res = await fetch(api_address, {
            method: "DELETE",
            headers: {
                "x-access-token": token
            }
        })
        if (res.ok) {
            const data = res.json();
            return data;
        }
        return null

    } catch (err) {
        throw err;
    }
}


const getUserSearchHistory = async (api_address, token) => {
    try {
        const res = await fetch(api_address, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        })
        if (res.ok) {
            const data = res.json();
            return data;
        }
        return null

    } catch (err) {
        throw err;
    }
}

const cancelOrder = async (api_address, token) => {
    try {
        const res = await fetch(api_address, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        })
        if (res.ok) {
            const data = res.json();
            return data;
        }
        return null

    } catch (err) {
        throw err;
    }
}

const getRelated = async (api_address) => {
    try {
        const response = await fetch(api_address);
        const data = await response.json();
        if (response.ok) {
            return data.length > 0 ? data : null;
        }
        return response;
    } catch (err) {
        return err.message
    }
}


const shouldCacheUpdate = async (api_address) => {
    try {
        const response = await fetch(api_address);
        const data = await response.json();
        if (response.ok) {
            return data;
        }
        return null;
    } catch (err) {
        return err.message
    }
}

const generateInvoice = async (api_address, token) => {
    try {
        const response = await fetch(api_address, {
            headers: {
                "x-access-token": token
            }
        });
        const data = await response.json();
        if (response.ok) {
            return data;
        }
        return null;
    } catch (err) {
        return err.message
    }
}

export {
    getHomepage,
    getHomepageData,
    getDiscountedProducts,
    getTodaysDeals,
    getProductsBySlug,
    getProductDetails,
    getProduct,
    orderDetails,
    authenticatePayment,
    authenticatePod,
    orders,
    getOrder,
    addUserHistory,
    scrollToTop,
    getAddressFromPincode,
    userBought,
    getReviewsByProductId,
    getRatingByProductId,
    addReviewByProductId,
    getEligibleReturns,
    returnProduct,
    verifyOtp,
    resetPassword,
    getUserSearchHistory,
    addUserSearchToHistory,
    deleteUserSearchFromHistory,
    cancelOrder,
    getDeals,
    getRelated,
    shouldCacheUpdate,
    generateInvoice
};