import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ReactStars from "react-stars";
import { useEffect, useState } from "react";
import { getRatingByProductId } from "../assets";

export default function Card({ ratingChanged, endPoint, _id, cost, title, images, category, discount, mrp }) {
    const [rating, setRating] = useState(null);

    useEffect(() => {
        (async () => {
            const res = await getRatingByProductId(`${endPoint}/rating?productId=${_id}`);
            setRating(res.rating);

        })();
    }, []);

    return (
        <Link to={"/catalogue/product/" + _id} key={_id} className="grid grid-cols-[42%_58%] h-[200px] border border-solid border-slate-300 bg-slate-200 rounded-md" >
            <div className="bg-white h-[198px]" >
                <img src={images[0]} alt="" className="inline-block w-full object-contain h-full" />
            </div>
            <div className="p-2" >
                <p className="overflow-hidden max-h-[70px] ellipsis leading-snug" >{title}</p>
                <div className="rating mb-1" >
                    <ReactStars
                        count={5}
                        edit={false}
                        value={rating}
                        size={24}
                        color2={'#ffd700'}
                    />
                </div>
                <div>
                    <p>&#8377;{cost}</p>
                    <small>M.R.P <s className="ml-1" >&#8377;{mrp}</s></small>
                    {discount > 0 && <small className="ml-1" >discount</small>}
                    <p className="text-xs text-teal-700" >category: <span className="text-sm text-black capitalize" >{category}</span></p>
                </div>
            </div>
            {/* <div className="relative h-3/4" >
                <button className="absolute left-0 top-0 h-full px-4" >
                <FontAwesomeIcon icon={faChevronLeft} size="2xl" />
                </button>
                <img className="block h-full" alt="item" />
                <button className="absolute right-0 top-0 h-full px-4" >
                <FontAwesomeIcon icon={faChevronRight} size="2xl" />
                </button>
            </div>
            <div className="h-1/4 border-box p-4 bg-zinc-100" >
                <div className="flex justify-between items-center pb-3" >
                    <h3 className="font-semibold text-2xl" >The Product</h3>
                    <p className="font-bold text-2xl text-sky-500" >$1.99</p>
                </div>
                <p className="text-justify" >A Product showcase here will come a description of a page.</p>

            </div> */}
        </Link>
    );
}