import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faTrash, faLock, faTruck } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";


import FooterContainer from "../container/footer.container";
import carts from "../assets/images/carts.png";
import { emptyCart, getCartAsync, removeFromCart, removeFromCartAsync, quantityInCart, quantityInCartAsync } from "../redux/cartSlice";
import { getProductDetails, scrollToTop } from "../assets";
import * as ROUTES from "../constant/routes";
import { updateErr } from "../redux/errSlice";
import costCalculator from "../helpers/costCalculator";

export default function Cart() {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const cart = useSelector(state => state.cart);
    const navigate = useNavigate();

    const [cartProducts, setCartProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteSpinner, setDeleteSpinner] = useState(null);
    const moveToTopRef = useRef();

    const removeItem = (id) => {
        setDeleteSpinner(id);
        if (user) {
            dispatch(removeFromCartAsync({ userId: user._id, token: user.token, productId: id })).then(() => {
                setDeleteSpinner(null);

            })
        } else {
            dispatch(removeFromCart(id));
            setDeleteSpinner(null)
        }
    }

    const handleContinueShopping = () => {
        navigate("/");
    }

    const calculateSubtotal = () => {
        let subTotal = 0;
        if (cartProducts.length > 0) {
            cartProducts.forEach(item => {
                subTotal += costCalculator(item.discount, item.cost, item.size?.price) * item.quantity;
            });
        }
        return subTotal;
    }

    const handleQuantity = (productId, shouldIncrease) => {
        if (user) {
            dispatch(quantityInCartAsync({ productId, shouldIncrease, userId: user._id, token: user.token }))
        } else {
            dispatch(quantityInCart({ productId, shouldIncrease }));
        }
    }

    const handleProceedToBuy = () => {
        if (user) {
            navigate(ROUTES.ADDRESS)
        } else {
            navigate(ROUTES.LOGIN);
        }
    }

    useEffect(() => {
        user && (async () => {
            dispatch(getCartAsync({ userId: user._id, token: user.token }));
        })();
        scrollToTop(window);
    }, []);

    useEffect(() => {
        if (cart.length > 0) {

            (async () => {
                const data = await getProductDetails(cart.map(item => ({ product: item.productId })));
                if (data[0].status !== "rejected") {
                    setCartProducts(data.map((item, index) => ({ ...item.value, quantity: cart[index].quantity, color: cart[index].color ? cart[index].color : null, size: cart[index].size ? cart[index].size : null })));
                    setLoading(false);
                } else {
                    dispatch(updateErr("Something went wrong. Try again later!"));
                }
            })();

        } else {
            setCartProducts([]);
            setLoading(false);
        }
    }, [cart]);

    return (
        <div ref={moveToTopRef} >
            {loading ? (
                <div className="p-3 w-[90%] my-3 mx-auto flex flex-col gap-y-2" >
                    <Skeleton height={"150px"} count={1} />
                    <Skeleton height={"150px"} count={1} />
                </div>
            ) :
                cartProducts.length === 0 ? (
                    <>
                        <button className="bg-slate-400 h-10 flex items-center px-3 w-full" >
                            <FontAwesomeIcon icon={faLocationDot} size="lg" />
                            {
                                user ? (
                                    <p className="font-medium ml-2 text-sm leading-0" >{user.address ? `Deliver to ${user.fullName}-110009` : "Select delivery location"}</p>
                                ) : (
                                    <p className="font-medium ml-2 text-sm leading-0" >Select delivery location</p>
                                )
                            }
                        </button>
                        <div className="h-52 flex justify-center items-center" >
                            <img src={carts} alt="a big cart" className="inline-block h-3/4" />
                        </div>
                        <h2 className="font-bold text-xl text-center" >Your Va Cart is empty</h2>
                        <p className="text-teal-700 text-sm text-center mb-4" >Shop today's deals</p>
                        {
                            !user && (
                                <>
                                    <button className="rounded-lg bg-amber-300 h-10 m-2 font-medium text-sm w-[calc(100%-14px)]" >Sign in to your account</button>
                                    <button className="rounded-lg border-amber-700 border-[0.1px] outline-0 border-solid h-10 mx-2 mb-9 font-medium text-sm w-[calc(100%-14px)]" >Sign up now</button>
                                </>
                            )
                        }
                        <button onClick={handleContinueShopping} className="rounded-sm bg-[#fed813] h-12 m-2 font-medium text-sm w-[calc(100%-16px)]" >Continue Shopping</button>
                    </>
                ) : (
                    <div className="p-2" >
                        <p className="font-medium text-xl my-1" >
                            Subtotal
                            <span className="relative text-3xl font-semibold mx-3" >
                                <small className="absolute top-[3px] -left-2 text-xs font-semibold" >&#x20B9;</small>
                                {calculateSubtotal()}
                                <small className="absolute top-[3px] -right-[14px] text-xs font-semibold" >00</small>
                            </span>
                        </p>
                        <button onClick={handleProceedToBuy} className="rounded-md bg-[#fed813] h-10 font-medium text-sm w-full my-3" >Proceed to Buy ({cartProducts.length} items)</button>
                        {
                            cartProducts.length > 0 ? (
                                cartProducts.map((item) => <div className="grid grid-rows-[60%_35%] grid-cols-[35%_62%] gap-2 h-56 py-2 border border-solid border-slate-200 border-l-0 border-r-0" >
                                    <img className="inline-block row-start-1 row-end-2 h-full w-full object-contain" src={item.images[0]} alt="gillete" />
                                    <div className="flex h-7 bg-slate-200 rounded-md row-start-2 row-end-3 col-start-1 col-end-2" >
                                        <button disabled={item.quantity === 1} onClick={() => handleQuantity(item._id, false)} className="w-[30%] flex justify-center items-center" >
                                            <FontAwesomeIcon icon={faTrash} size="sm" /></button>
                                        <input className="w-[40%] text-center bg-white" value={item.quantity} />
                                        <button onClick={() => handleQuantity(item._id, true)} className="w-[30%] font-semibold text-xl flex justify-center items-center leading-none" >+</button>
                                    </div>
                                    <div className="row-start-2 row-end-3 col-start-2" >
                                        <button onClick={() => removeItem(item._id)} className="relative border border-slate-200 border-solid rounded-md p-1 px-2 text-xs mr-2" >
                                            {
                                                deleteSpinner === item._id && (
                                                    <span className="rounded-md absolute bg-[rgba(0,0,0,0.3)] top-0 left-0 w-full h-full flex justify-center items-center" >
                                                        <ColorRing
                                                            visible={true}
                                                            height="22"
                                                            width="22"
                                                            ariaLabel="blocks-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass="blocks-wrapper"
                                                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                                        />
                                                    </span>
                                                )
                                            }
                                            Delete
                                        </button>
                                        {/* <button className="border border-slate-200 border-solid rounded-md p-1 px-2 text-xs" >Save for later</button> */}
                                    </div>
                                    <div className="row-start-1 row-end-2 col-start-2 py-3" >
                                        <p className="text-justify leading-tight font-medium text-sm max-h-8 mb-2 overflow-hidden" >{item.title}</p>
                                        <span className="relative text-xl font-semibold mx-2 block " >
                                            <small className=" absolute top-[3px] -left-2 text-xs font-semibold" >&#x20B9;</small>
                                            <span className="relative" >
                                                {costCalculator(item.discount, item.cost, item.size ? item.size.price : null)}
                                                <small className="absolute top-[3px] -right-[15px] text-xs font-semibold" >00</small>
                                            </span>
                                        </span>
                                        <small className="text-emerald-600 font-medium block leading-none mt-1" >Quantity: {item.quantity}</small>
                                        {
                                            item.size && (
                                                <small className="text-black mt-3 mb font-medium block leading-none" >Size: {item.size.name}</small>
                                            )
                                        }
                                        {
                                            item.color && (
                                                <small className="flex items-center text-black mt-1 font-medium block leading-none" >Color: <span className="ml-3 inline-block w-6 h-6 rounded-full" style={{backgroundColor: item.color.name}} ></span></small>
                                            )
                                        }
                                        {/* <small className="font-medium leading-none" > (Minimum order quantity)</small> */}
                                    </div>
                                </div>)
                            ) : <Skeleton count={1} />
                        }
                        <div className="flex justify-evenly items-center mt-6" >
                            <div className="flex flex-col" >
                                <FontAwesomeIcon icon={faLock} size={"2x"} />
                                <p className="text-sky-600 text-sm mt-2" >Secure Payment</p>
                            </div>
                            <div className="flex flex-col" >
                                <FontAwesomeIcon icon={faTruck} size={"2x"} />
                                <p className="text-sky-600 text-sm mt-2" >Secure Delivery</p>
                            </div>
                        </div>
                        <button onClick={handleContinueShopping} className="rounded-sm bg-white border border-slate-400 border-solid border-l-0 border-r-0 h-12 m-2 mt-6 font-medium text-sm w-[calc(100%-16px)]" >Continue Shopping</button>
                    </div>
                )
            }

            <FooterContainer passedRef={moveToTopRef} />
        </div>
    )


}