import { useState, useCallback, useRef, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBars, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import SearchBar from "../components/search.bar";
import SidebarContainer from "./sidebar.container";
import { CART, HOME } from "../constant/routes";
import UserMenuContainer from "./user.menu.container";
import { logoutUser } from "../redux/userSlice";
import SearchArea from "../components/SearchArea";
import { addSearchToHistoryAsync } from "../redux/searchHistorySlice";
import VarynsLogo from "../assets/logos/varyns-logo-big.svg";


export default function NavbarContainer() {
    // States
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [userMenuToggle, setUserMenuToggle] = useState(false);
    const [searchSlug, setSearchSlug] = useState("");
    const [searchSuggestions, setSearchSuggestions] = useState({
        show: false,
        suggestions: null
    });

    const endPoint = "https://varyns.onrender.com";


    const navigate = useNavigate();
    const searchBarRef = useRef(null);
    const user = useSelector(state => state.user);
    const cart = useSelector(state => state.cart);
    const dispatch = useDispatch();

    // handlers

    const handleFocus = useCallback((focus) => {
        focus ? searchBarRef.current.focus() : searchBarRef.current.blur();
    }, [searchBarRef]);

    const handleToggleSidebar = () => {
        setSidebarToggle(prevState => !prevState);
        setUserMenuToggle(false);
    }

    const handleUserMenuToggle = e => {
        setUserMenuToggle(prevState => !prevState);
        setSidebarToggle(false);
    }

    const handleSearch = useCallback(async e => {
        e.preventDefault();
        if (searchSlug.length > 3) {
            user && dispatch(addSearchToHistoryAsync({ api_address: `${endPoint}/add-user-search-history?userId=${user._id}&value=${searchSlug}`, token: user.token }));
            handleFocus(false);
            navigate(`/catalogue/search/${searchSlug}`);
        } else {
            handleFocus(true)
        }
    }, [user, searchSlug])

    const handleChange = ({ target }) => {
        const { value } = target;
        setSearchSlug(value);
    }

    const handleClick = async () => navigate(CART);

    const handleLogout = () => {
        handleUserMenuToggle();
        dispatch(logoutUser());
    }

    const handleSearchArea = () => {
        setSearchSuggestions(prevState => ({ ...prevState, show: !prevState.show }));
    }

    useEffect(() => {
        const clickEventHandler = () => {
            setSearchSuggestions(prevState => ({ ...prevState, show: false }));
        }
        if (searchSuggestions.show) {
            window.addEventListener("click", clickEventHandler);
        } else {
            window.removeEventListener("click", clickEventHandler);
        }
    }, [searchSuggestions]);

    return (
        <>
            <nav className="flex bg-slate-300 justify-between sticky right-0 top-0 left-0 z-[10]" >
                <div className="flex items-center p-2" >
                    <button onClick={handleToggleSidebar} >
                        <FontAwesomeIcon icon={faBars} size="2x" />
                    </button>
                    <Link to={HOME} className="ml-4 font-bold text-3xl" >
                        <img src={VarynsLogo} className="w-32" alt="varyn's logo" />
                    </Link>
                </div>
                <div className="flex items-center p-2" >
                    <div onClick={(e) => e.stopPropagation()} className="relative" >
                        <SearchBar passedRef={searchBarRef} handleChange={handleChange} handleSearch={handleSearch} value={searchSlug} handleFocus={handleSearchArea} />
                        {searchSuggestions.show && <SearchArea suggestions={searchSuggestions.suggestions} handleFocus={handleSearchArea} />}
                    </div>

                    <button onClick={handleClick} className="ml-2 relative " >
                        <FontAwesomeIcon icon={faShoppingCart} size="xl" />
                        {cart?.length > 0 && <span className={`font-bold absolute ${cart?.length > 9 ? "right-[4px]" : "right-[7px]"} -top-3 leading-0 text-orange-500`} >{cart?.length}</span>}
                    </button>
                </div>
                <SidebarContainer
                    show={sidebarToggle}
                    handleToggleSidebar={handleToggleSidebar}
                    handleUserMenuToggle={handleUserMenuToggle}
                    user={user}
                />
                {
                    user && (
                        <UserMenuContainer
                            show={userMenuToggle}
                            handleUserMenuToggle={handleUserMenuToggle}
                            handleToggleSidebar={handleToggleSidebar}
                            user={user}
                            handleLogout={handleLogout}
                        />
                    )
                }
            </nav>
            <Outlet />
        </>
    )
}