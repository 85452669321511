import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const updateCacheAsync = createAsyncThunk(
    'cache',
    async ({ api_address }) => {
        try {
            const response = await fetch(api_address);
            let cache = await response.json();
            if (response.ok) {
                return { cache }
            }
            return null;
        } catch (err) {
            return err.message
        }
    }
);

// export const getCartAsync = createAsyncThunk(
//     'cart',
//     async ({ userId, token }) => {
//         try {
//             const res = await fetch(endPoint + `/cart?userId=${userId}`, {
//                 headers: { 'x-access-token': token }
//             })
//             const cart = await res.json();
//             if (!res.ok) {
//                 throw cart;
//             }
//             return { cart }
//         } catch (err) {
//             throw err;
//         }
//     }
// )

export const cacheSlice = createSlice({
    name: 'cache',
    initialState: {
        orders: null,
        homePageItems: null
    },
    reducers: {
        updateCache: (state, action) => {
            const cache = action.payload ? action.payload : state;
            return cache;
        }
    },
    extraReducers: {
        [updateCacheAsync.fulfilled]: (state, action) => {
            return action.payload.cache ? action.payload.cache : state;
        }
    }
});

export const { updateCache } = cacheSlice.actions;

export default cacheSlice.reducer;