const canReview = (orders, productId, reviews, userId) => {
    if (!userId) return false;

    for (let i = 0; i < orders.slice(0, 10).length; i++) {
        if (orders[i].productId === productId) {
            if (reviews){
                if(reviews.users.includes(userId)){
                    return false;
                }else {
                    return true;
                }
            }else{
                return true;
            }
        }
    }
    return false
}

export default canReview;