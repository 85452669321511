import { useEffect, useRef } from "react";
import { scrollToTop } from "../assets";
import FooterContainer from "../container/footer.container";

export default function PrivacyPolicy() {

    const moveToTopRef = useRef();

    useEffect(() => {
        scrollToTop(window);
    }, []);

    return (
        <div ref={moveToTopRef} >
            <div className="p-6" >
                <h1 className="text-[35px] font-medium" >Privacy Policy</h1>
                <p className="leading-7 px-4 my-3" >
                    <span className="text-lg text-red-700" >Last Updated:</span>October 10th 2023</p>
                <p className="leading-7 px-4 my-3 text-justify" >
                    Varyns ("we," "our," or "us") is committed to protecting the privacy and security of your personal information. This Privacy Policy describes how we collect, use, and disclose your personal information when you visit and make use of our e-commerce website (the "Site") and any related services offered through the Site. By using our Site, you consent to the practices described in this Privacy Policy.
                </p>
                <ol className="list-decimal px-4" >
                    <li>
                        <p className="text-xl font-medium my-3" >Information We Collect</p>
                        <ul className="list-disc px-6 " >
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    <span className="text-lg text-red-700" >Personal Information:</span> We may collect personal information from you when you create an account, make a purchase, or contact us. This may include your name, email address, phone number, and shipping/billing address.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify " >
                                    <span className="text-lg text-red-700" >Payment Information:</span> When you make a purchase, we collect payment information, such as credit card or other payment details. Please note that we do not store your payment information; it is securely processed by our payment service provider.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify " >
                                    <span className="text-lg text-red-700" >Usage Information:</span> We collect information about how you use our Site, such as your IP address, device type, browser, and the pages you visit.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify " >
                                    <span className="text-lg text-red-700" >Cookies and Similar Technologies:</span> We may use cookies, web beacons, and other tracking technologies to enhance your online experience and gather information about your use of the Site. You can manage your cookie preferences through your browser settings.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >How We Use Your Information</p>
                        <p>We may use your informatio for the following purposes:</p>
                        <ul className="list-disc" >
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    To process and fulfill your orders, including order confirmation, shipping, and customer support.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    To send you transactional emails related to your purchases and account.                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    To personalize your online experience.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    To improve our Site, products, and services.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3 text-justify" >
                                    To send promotional emails and newsletters if you have opted in.
                                </p>
                            </li>
                            <li>
                                <p className="leading-7 px-4 my-3" >
                                    To respond to yor inquireies and provide customer supoort.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Information Sharing</p>
                        <p className="leading-7 px-4 my-3 text-justify " >We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third-party service providers and partners who assist us in running and improving our business, but they are contractually bound to keep your information confidential.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Data Security</p>
                        <p className="leading-7 px-4 my-3 text-justify " >We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of data transmission over the internet or electronic storage is entirely secure. Therefore, we cannot guarantee the absolute security of your data.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Your Choices</p>
                        <p className="leading-7 px-4 my-3 text-justify " >You can manage your personal information and communication preferences by logging into your account on our Site. You may also unsubscribe from our marketing emails at any time by following the instructions in the email.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Changes to this Privacy Policy</p>
                        <p className="leading-7 px-4 my-3 text-justify " >We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will provide notice of any material changes on our Site.</p>
                    </li>
                    <li>
                        <p className="text-xl font-medium my-3" >Contact Us</p>
                        <p className="leading-7 px-4 my-3 text-justify " >If you have any questions or concerns regarding this Privacy Policy, please contact us at +919560543590.</p>
                    </li>

                </ol>

            </div>
            <FooterContainer passedRef={moveToTopRef} />
        </div>
    );
}