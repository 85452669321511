export default function deliveryText(data, user, defaultAddress) {
    // user ? selectedAddress ? 
    //     `Deliver to ${selectedAddress.fullName} - ${selectedAddress.state} ${selectedAddress.pincode}`
    //     : ``
    //     :
    //     `Select a location to view product availability

    if (data.pincodeAddress) {
        return {text: `Deliver to ${data.pincodeAddress.District} - ${data.pincodeAddress.Pincode}`, pincode: data.pincodeAddress.Pincode}
    } else {
        if (user && defaultAddress) {
            if (data.selected) {
                return {text: `Deliver to ${data.selected.fullName} - ${data.selected.state} ${data.selected.pincode}`, pincode: data.selected.pincode}
            } else {
                return {text: `Deliver to ${defaultAddress.fullName} - ${defaultAddress.state} ${defaultAddress.pincode}`, pincode: defaultAddress.pincode}
            }
        } else {
            return {text:`Select a location to view product availability`}
        }
    }
}