import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faChevronRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { orders as getOrders, getProductDetails, scrollToTop, shouldCacheUpdate } from "../../assets";
import * as ROUTES from "../../constant/routes";
import { addOrders } from "../../redux/orderSlice";
import Loader from "../../components/loader.spinner";
import { updateCache } from "../../redux/cacheSlice";
import FooterContainer from "../../container/footer.container";

export default function Orders() {

    const [loading, setLoading] = useState(false);
    // const [orders, setOrders] = useState([]);
    const [searchLoader, setSearchLoader] = useState(false);
    const endPoint = "https://varyns.onrender.com";
    const { user, orders, cache } = useSelector(({ user, orders, cache }) => ({ user, orders, cache }));
    const [searchedOrder, setSearchedOrder] = useState([]);
    const [searchKeywords, setSearchKeywords] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const moveToTopRef = useRef(null);

    const handleClick = (status, orderId, productId) => navigate(status === "captured" ? `${ROUTES.ORDER_DETAILS}?order=${orderId}&productId=${productId}` : `${ROUTES.PAYMENT_FAILED}?order=${orderId}&productId=${productId}`)

    const handleChange = ({ target }) => {
        setSearchKeywords(target.value);

        setSearchLoader(true);
        const matched = orders.filter(item => item.value.title.toLowerCase().search(target.value) !== -1);
        setSearchedOrder(matched);
        setSearchLoader(false);
    }

    const handleClearSearch = (e) => {
        e.preventDefault();
        setSearchKeywords("");
        setSearchedOrder([]);
    }

    useEffect(() => {
        (async () => {
            const ordersArr = await getOrders(endPoint + "/orders?userId=" + user._id, user.token);
            const productData = await getProductDetails(ordersArr.map(item => ({ product: item.productId })));
            const updatedCache = await shouldCacheUpdate(`${endPoint}/did-cache-update`);
            if (productData.length !== orders.length || !updatedCache || !cache.orders || (updatedCache.orders.lastUpdated.length !== cache.orders.lastUpdated.length) || orders.length === 0) {
                setLoading(true);
                dispatch(addOrders(productData.map((item, index) => ({ ...item, ...ordersArr[index] })).reverse()));
                if (updatedCache) {
                    dispatch(updateCache({ ...updatedCache }));
                }

            }
            setLoading(false);
        })();
        scrollToTop(window);
    }, []);


    return (
        <div ref={moveToTopRef} className="" >
            {
                orders.length > 0 || loading ? (
                    <>
                        <h1 className="text-xl font-bold p-3" >Your Orders</h1>
                        <div className="flex border border-b-solid border-slate-200" >
                            <form onSubmit={e => e.preventDefault()} className="flex items-center px-2 w-4/5 relative" >
                                <button>
                                    <FontAwesomeIcon className="text-teal-500" size="xl" icon={faMagnifyingGlass} />
                                </button>
                                {
                                    searchKeywords.length > 0 && <button type="button" className="absolute right-2" onClick={handleClearSearch} >
                                        <FontAwesomeIcon className="text-teal-500" size="1x" icon={faCircleXmark} />
                                    </button>
                                }
                                <input value={searchKeywords} onChange={handleChange} className="outline-0 py-3 px-2" type="text" placeholder="Search all orders" />
                            </form>
                            <button className="opacity-[30%] w-1/5 my-1 flex justify-between items-center border-l px-2 border-l-solid border-l-slate-200" >
                                Filter
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                        {/* <div className="bg-gray-100 p-2 py-3" >
                            <div className="bg-white" >
                                <div className="flex p-2 justify-between items-center" >
                                    <h2 className="font-bold" >Buy Again</h2>
                                    <button className="text-sm text-teal-700" >See more</button>
                                </div>
                                <div className="overflow-hidden overflow-x-scroll flex gap-x-2" >
                                    {
                                        orders.products.map(item => (
                                            <div key={item._id} className="w-24 h-28 border border-solid shrink-0 border-red-400" ></div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div> */}
                        {
                            searchLoader ? (
                                <Loader />
                            ) : (
                                <div>
                                    {/* <p className="text-center text-gray-500 text-sm font-semibold my-2 border-b border-solid border-gray-300" >Past three months</p> */}
                                    {loading ? (
                                        <Skeleton className="border-b border-solid border-gray-300 flex justify-between items-center p-2 py-4" height={"80px"} count={2} />
                                    ) : (searchKeywords.length > 0 ? searchedOrder : orders).map(item => (
                                        <div onClick={() => handleClick(item.status, item.orderId, item.productId)} key={item._id} className="border-b border-solid cursor-pointer border-gray-300 grid grid-cols-[30%_60%_10%] items-center p-2 py-4 my-4" >
                                            <div className="w-20 h-20 rounded-full bg-white ml-6" >
                                                <img className="inline-block h-full w-full object-contain" src={item.value.images[0]} />
                                            </div>
                                            {
                                                item.status === "captured" ?
                                                    (<div>
                                                        <p className="font-semibold" >{item.value.title}</p>
                                                        {item.cancelled ? <p className="text-red-600 text-sm" >Order Cancelled</p> : <p className="text-slate-600 text-sm" >Paid on {item.createdAt.split("T")[0]}</p>}
                                                    </div>)
                                                    :
                                                    (
                                                        <div>
                                                            <p className="font-bold text-red-600" >Payment Failed</p>
                                                            <p className="text-sm leading-relaxed" >
                                                                Your payment for this order could not be
                                                                completed. If money has been deducted from your card/bank
                                                                account, it would be refunded in 2-3 business days.
                                                            </p>
                                                        </div>
                                                    )
                                            }
                                            <button>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                        {
                            searchKeywords.length > 0 && searchedOrder.length === 0 && (
                                <div className="" >
                                    <p className="text-center font-medium" >No Orders Found</p>
                                </div>
                            )
                        }
                    </>
                ) : (
                    <>
                        <div className="h-40 flex items-center justify-center" >
                            <h2 className="text-lg font-mono font-medium" >You don't have any Orders!</h2>
                        </div>
                        <button className="fixed bottom-0 rounded-sm bg-[#fed813] h-12 m-2 font-medium text-sm w-[calc(100%-16px)]" >Continue Shopping</button>
                    </>
                )
            }
            <FooterContainer passedRef={moveToTopRef} />
        </div >
    )

}