import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as ROUTES from '../constant/routes';
import { sendOtpAsync } from '../redux/userSlice';
import { scrollToTop } from '../assets';
import CustomLoader from '../components/CustomLoader';
import Varyns from "../assets/logos/varyns-logo-big.svg";

export default function SignUp() {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = async data => {
        setLoading(true)
        dispatch(sendOtpAsync({ mobileNumber: data.mobileNumber })).then(() => {
            setLoading(false);
            navigate(ROUTES.VERIFY_MOBILE_NUMBER, { state: { ...data } });
        });
    };

    const handleShowPassword = ({ target }) => setShowPassword(target.checked);

    useEffect(() => {
        scrollToTop(window);
    }, []);

    return (
        <div className='flex flex-col justify-center items-center min-h-screen' >
            <img src={Varyns} className='mb-8' />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col w-72"
            >
                <p className='my-1 font-medium'>Welcome</p>
                <input
                    className='h-12 bg-slate-200 p-3 rounded-sm'
                    type="text"
                    placeholder="First and last name"
                    {...register("fullName", { required: true })}
                />
                {errors.fullName && <span className="text-xs text-red-500 mt-1 ml-1" >This field is required</span>}
                <input
                    className='h-12 bg-slate-200 p-3 rounded-sm mt-1'
                    type="tel"
                    placeholder="Mobile number"
                    {...register("mobileNumber", { required: true, pattern: /^((\+91)?|91)?[789][0-9]{9}/ })}
                />
                {errors.mobileNumber && <span className="text-xs text-red-500 mt-1 ml-1" >This field is required</span>}
                <input
                    className='h-12 bg-slate-200 p-3 rounded-sm mt-1'
                    type={showPassword ? "text" : "password"}
                    placeholder="password"
                    {...register("password", { required: true})}
                />
                {/* // pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$/  */}
                {errors.password && <span className="text-xs text-red-500 mt-1 ml-1" >This field is required</span>}
                <div className='flex justify-start mt-2' >
                    <label className='w-5 h-5 my-0 ml-0 bg-slate-300 flex justify-center items-center' htmlFor='show-password' >
                        {showPassword ? <span className='w-8/12 h-4/6 bg-sky-600 inline-block' ></span> : <></>}
                    </label>
                    <small className='ml-1 font-medium' >Show password</small>
                    <input
                        id='show-password'
                        className='w-0 h-0'
                        type="checkbox"
                        value={showPassword}
                        onChange={handleShowPassword}
                    />
                </div>
                <ul className="list-disc px-4 text-xs mt-2 mb-3" >
                    <li>At least one upper case</li>
                    <li>At least one lower case</li>
                    <li>At least one digit</li>
                    <li>At least one special character</li>
                    <li>At least should be 8 digit long</li>
                </ul>
                <p className="text-justify leading-[18px] font-medium" >By enrolling your mobile number, you consent to receive automated security notifications via text message from Varyns. Message and data rates may apply.</p>
                <button className='relative font-medium bg-slate-300 py-2 mt-3 mb-3' >
                    Verify mobile number
                    {
                        loading && <CustomLoader />
                    }
                </button>
                <small>By creating an account or logging in, you agree to Va's <Link to={ROUTES.CONDITION_OF_USE} className="text-sky-600" >Conditions of Use</Link> and <Link to={ROUTES.PRIVACY_POLICY} className="text-sky-600" >Privacy Policy</Link>.</small>
            </form>
        </div>
    );
}