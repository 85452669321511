import { faChevronLeft, faXmark, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getOrder, getProductDetails, scrollToTop, cancelOrder, generateInvoice } from "../../assets";
import { useNavigate, Link } from "react-router-dom";
import { Transition } from "@headlessui/react";

import * as ROUTES from "../../constant/routes";
import useQuery from '../../hooks/use-query';
import FooterContainer from "../../container/footer.container";
import { updateErr } from "../../redux/errSlice";
import CustomLoader from "../../components/CustomLoader";

export default function OrderDetails() {
    const [order, setOrder] = useState(null);
    const [popup, setPopup] = useState({
        cancel: false
    });
    const [loading, setLoading] = useState(false);

    const user = useSelector(state => state.user);
    const parentRef = useRef(null);
    const endpoint = "https://varyns.onrender.com";
    const dispatch = useDispatch();
    const query = useQuery();
    const navigate = useNavigate();

    const addDaysToDate = (days, date) => {
        const d = new Date(date);
        d.setDate(d.getDate() + +days);
        return d.toString();
    }

    const handleBack = () => navigate(-1);

    const handleCancelOrderPopup = () => {
        setPopup(prevState => ({ ...prevState, cancel: !prevState.cancel }));
    }

    const handleInvoice = async () => {
        setLoading(true);
        if (order && order.delivered && query.get("productId")) {
            const pdf = await generateInvoice(`${endpoint}/invoice?orderId=${order.orderId}&productId=${query.get("productId")}`, user.token);
            if (pdf) {
                const link = `data:application/pdf;base64,${pdf}`;
                const fileName = "invoice_" + order.orderId + ".pdf";

                const downloadLink = document.createElement("a");
                downloadLink.href = link;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        } else {
            dispatch(updateErr("Something Went Wrong! Try again later."))
        }
        setLoading(false);
    }

    const canCancelOrder = (shippingStatus) => {
        const status = shippingStatus.filter(item => item.label.toLowerCase() === "shipped");
        if (status.length > 0) {
            return false;
        }
        return true;
    }

    const handleCancelOrder = useCallback(async () => {
        const res = await cancelOrder(`${endpoint}/cancel-order?orderId=${order.orderId}&productId=${order.productId}`, user.token);
        if (res) {
            setOrder(prevState => ({ ...prevState, ...res }));
            handleCancelOrderPopup();
        } else {
            dispatch(updateErr("Something Went wrong. Please try again later!"))
        }
    }, [order, user])

    useEffect(() => {
        (async () => {
            const [data] = await getOrder(endpoint + `/orders?userId=${user._id}&orderId=${query.get("order")}&productId=${query.get("productId")}`, user.token);
            const [orderData] = await getProductDetails([{ product: data.productId }]);
            setOrder({ ...data, description: orderData.value.description, images: orderData.value.images, title: orderData.value.title });
        })();
        scrollToTop(window);
    }, []);

    return (
        <div ref={parentRef} >
            <div className="bg-gray-100 px-1 py-3" >
                <button onClick={handleBack} className="block text-left font-semibold text-sm" >
                    <FontAwesomeIcon className="mr-2" size="2xs" icon={faChevronLeft} />
                    Your Orders
                </button>

            </div>
            <h2 className="font-semibold text-[22px] m-2" >View order details</h2>
            <section className="px-2" >
                {
                    order ? (
                        <div className="grid grid-cols-[30%_70%] border-gray-200 rounded-sm p-2 px-4 border-2 border-solid" >
                            <p className="text-gray-700 row-start-1 row-end-2" >Order date</p>
                            <p className="text-gray-700 row-start-2 row-end-3" >Order #</p>
                            <p className="text-gray-700 row-start-3 row-end-4" >Order total</p>

                            <p className="row-start-1 row-end-2 col-start-2 col-end-3" >{order.createdAt.split("T")[0]}</p>
                            <p className="row-start-2 row-end-3 col-start-2 col-end-3" >{order.orderId}</p>
                            <p className="row-start-3 row-end-4 col-start-2 col-end-3" >&#8377; {order.amount}</p>
                        </div>
                    ) : (
                        <Skeleton enableAnimation={true} count={1} height={150} />
                    )
                }
            </section >
            <section className="px-2 rounded-lg mt-2" >
                <h2 className="font-semibold text-[17px] mb-2" >Shipment details</h2>
                <div className="border-gray-200 rounded-md p-2 px-4 text-sm border-2 border-solid rounded-b-[0]" >
                    <p>FREE Delivery with Varyns</p>
                </div>
                <div className=" border-gray-200 rounded-sm p-2 px-4 border-2 border-solid" >
                    {
                        order ? order.cancelled ? (
                            <h2 className="font-semibold text-[18px] mb-2 text-red-700" >Order has been Cancelled</h2>
                        ) : (
                            <h2 className="font-semibold text-[18px] mb-2" >{order.delivered ? "Delivered" : "Will be delivered soon"}</h2>
                        ) : (
                            <Skeleton height="30px" />
                        )
                    }
                    {
                        !order?.delivered && (
                            <div>
                                <p className="text-sm" >Delivery Estimate:</p>
                                {
                                    order ? (
                                        <p className={`${order.cancelled ? "text-red-700" : "text-green-700"} text-sm font-semibold`} >{order.cancelled ? "Cancelled" : addDaysToDate(order.estimated_delivery_days, order.createdAt)}</p>
                                    ) : (
                                        <Skeleton height={10} />
                                    )
                                }
                            </div>
                        )
                    }
                    {
                        order ? (
                            <div className="p-3 flex gap-x-2" >

                                {/* <img className="block w-1/3 h-20" src={failedOrder.images[0]} alt={failedOrder.title} /> */}
                                <div>
                                    {/* <p className="font-bold leading-tight" >{failedOrder.description}</p> */}
                                    <div className="flex items-center" >
                                        {/* <p className="text-red-600 font-bold ml-1" >{failedOrder.amount/100}.00</p> */}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Skeleton enableAnimation={true} count={1} height={120} />
                        )
                    }
                </div>
            </section>
            <div onClick={() => !order?.cancelled && navigate(`${ROUTES.TRACKING_ORDER}?orderId=${query.get("order")}&productId=${query.get("productId")}`)} className={`${order?.cancelled ? "cursor-not-allowed" : "cursor-pointer"} border border-l-0 border-r-0 mx-2 flex items-center justify-between p-3`} >
                <p className="cursor-not-allowed text-sm" >Track shipment</p>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <h2 className="font-bold my-2 mx-2" >Payment information</h2>
            <div className="mx-2 border-gray-200 rounded-lg rounded-b-[0] p-2 px-4 border-2 border-solid" >
                <p className="font-semibold text-sm" >Payment Method</p>
                {order && order.method ?
                    <p>{order.method}</p>
                    : <Skeleton height="10px" />
                }
            </div>
            <div className="mx-2 rounded-lg rounded-t-[0] border-gray-200  p-2 px-4 border-2 border-solid" >
                <p className="font-semibold text-sm" >Billing Address</p>
                {
                    order ? (
                        <>
                            <p className="text-sm" >{order.address[0].apartment}</p>
                            <p className="text-sm" >{order.address[0].area}</p>
                            <p className="text-sm" >{order.address[0].town}</p>
                            <p className="text-sm" >{order.address[0].state}</p>
                            <p className="text-sm" >{order.address[0].pincode}</p>
                        </>
                    ) : (
                        <Skeleton count={5} height={10} />
                    )
                }
            </div>
            <h2 className="font-bold my-2 mx-2" >Shipping Address</h2>
            <div className="mx-2 rounded-lg border-gray-200  p-2 px-4 border-2 border-solid" >
                {
                    order ? (
                        <>
                            <p className="text-sm" >{order.address[0].fullName}</p>
                            <p className="text-sm" >{order.address[0].apartment}</p>
                            <p className="text-sm" >{order.address[0].area}</p>
                            <p className="text-sm" >{order.address[0].town}</p>
                            <p className="text-sm" >{order.address[0].state}</p>
                            <p className="text-sm" >{order.address[0].pincode}</p>
                        </>
                    ) : (
                        <Skeleton count={6} height={10} />
                    )
                }
            </div>

            <h2 className="font-bold my-2 mt-4 mx-2 text-red-700" >Order Cancellation</h2>

            <div className="mx-2 rounded-lg  border-gray-200  p-2 px-4 border-2 border-solid mb-8" >
                {
                    order ? (
                        <div>
                            <p className="text-sm" >You can cancel your order before being Shipped.</p>
                            {
                                order.cancelled ? (
                                    <>
                                        <p className=" text-red-600 my-3" >Order has been cancelled</p>
                                        <p className="text-sm" >Your refund has been initiated. It will be credited within 7 days via the method you have paid.</p>
                                    </>
                                ) : (
                                    <button onClick={handleCancelOrderPopup} className={`${canCancelOrder(order.shippingStatus) ? "cursor-pointer" : "cursor-not-allowed"} underline mt-2 text-red-600 font-medium`} disabled={!canCancelOrder(order.shippingStatus)} >Cancel Order</button>
                                )
                            }
                        </div>
                    ) : (
                        <>
                            <Skeleton count={4} height={50} />
                        </>
                    )
                }
            </div>

            {
                order && order.delivered && (
                    <div >
                        <h2 className="font-bold text-emerald-600 my-2 mx-2" >Invoice</h2>
                        <div className="relative mx-2 rounded-lg border-gray-200 p-2 px-4 border-2 border-solid" >
                            <button disabled={loading} onClick={handleInvoice} className="cursor-pointer" >
                                Download Invoice
                                {loading && <CustomLoader />}
                            </button>
                        </div>
                    </div>
                )
            }


            <h2 className="font-bold my-2 mx-2" >Order Summary</h2>

            <div className="mx-2 rounded-lg  border-gray-200  p-2 px-4 border-2 border-solid mb-8" >
                {
                    order ? (
                        <>
                            <div className="flex items-center justify-between text-sm" >
                                <p>Items:</p>
                                <p>&#8377; {order.amount}</p>
                            </div>
                            <div className="flex items-center justify-between text-sm" >
                                <p>Postage & Packing:</p>
                                <p>&#8377; 0.00</p>
                            </div>
                            <div className="flex items-center justify-between text-sm" >
                                <p>Total before Tax:</p>
                                <p>&#8377; {order.amount}</p>
                            </div>
                            <div className="flex items-center justify-between text-sm" >
                                <p>Tax:</p>
                                <p>&#8377; 0.00</p>
                            </div>
                            <div className="flex items-center justify-between text-sm" >
                                <p>Total:</p>
                                <p>&#8377; {order.amount}</p>
                            </div>
                            <div className="flex items-center justify-between text-sm" >
                                <p className="font-semibold text-[17px]" >Order Total:</p>
                                <p className="text-[16px] text-red-700 font-semibold" >&#8377; {order.amount}</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <Skeleton count={4} height={50} />
                        </>
                    )
                }
            </div>
            <FooterContainer passedRef={parentRef} />
            <Transition
                className="z-[12] shadow-xl bg-[rgba(0,0,0,0.4)] fixed top-0 left-0 w-screen h-screen flex items-center justify-center"
                show={popup.cancel}
            >
                <Transition.Child className="relative w-72 p-4 bg-white rounded text-center" >
                    <button onClick={handleCancelOrderPopup} className="bg-red-600 absolute w-10 h-10 rounded-full -top-4 -right-4" >
                        <FontAwesomeIcon className="" color="#fff" size="xl" icon={faXmark} />
                    </button>
                    <p className="text-justify my-4" >Confirm if you want to cancel your order.</p>
                    <button onClick={handleCancelOrder} className="focus:bg-slate-200 py-2 px-10 border border-solid border-red-600 rounded" >Confirm</button>
                </Transition.Child>
            </Transition>
        </div>
    )
}