import { useState } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../constant/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faSquareFacebook, faSquareInstagram, faSquareXTwitter, faXTwitter } from "@fortawesome/free-brands-svg-icons";


export default function FooterContainer({
    passedRef
}) {
    const [disabled, setDisabled] = useState(false);
    const handleScrollToTop = () => {
        setDisabled(true);
        setTimeout(() => {
            passedRef.current.scrollIntoView({ behavior: 'smooth' });
            setDisabled(false);
        }, 200);
    }

    return (
        <div>
            <button disabled={disabled} onClick={handleScrollToTop} className="h-12 text-center text-white bg-slate-500 text-md font-semibold w-full" >Back to Top</button>
            <div className="flex justify-evenly items-center p-4 py-6 gap-x-2" >
                <div className="flex flex-col justify-evenly items-start" >
                    <p className="text-lg font-bold mb-1 leading-snug" >Connect with Varyns</p>
                    <Link to={ROUTES.FACEBOOK} target="_blank" className="font-medium text-sm" >
                        <FontAwesomeIcon className="mr-1" size="lg" icon={faFacebook} />
                        Facebook</Link>
                    <Link to={ROUTES.TWITTER} target="_blank" className="font-medium text-sm" >
                        <FontAwesomeIcon className="mr-1" size="lg" icon={faXTwitter} />
                        Twitter</Link>
                    <Link to={ROUTES.INSTAGRAM} target="_blank" className="font-medium text-sm" >
                        <FontAwesomeIcon className="mr-1" size="lg" icon={faInstagram} />
                        Instagram</Link>
                </div>
                <div className="flex flex-col justify-evenly items-start" >
                    <p className="text-lg font-bold mb-1 leading-snug" >Get to Know Varyns</p>
                    <Link to={ROUTES.ABOUT_US} className="font-medium text-sm" >About Us</Link>
                    <p className="font-medium text-sm h-[22px]" ></p>
                    <p className="font-medium text-sm h-4" ></p>

                </div>
            </div>
            <div className="flex flex-col items-center mb-4" >
                <div>
                    <Link to={ROUTES.CONDITION_OF_USE} className="text-[15px]" >Conditions of Use</Link>
                    <Link to={ROUTES.PRIVACY_POLICY} className="ml-5 text-[15px]" >Privacy Policy</Link>
                </div>
                <div className="text-[15px]" >
                    &#169; 2023, Varyns.com
                </div>
            </div>
        </div>
    );
}