import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";


import useQuery from "../../hooks/use-query";
import { getOrder, getProductDetails, scrollToTop } from "../../assets";

export default function () {

    const [selectedOrder, setSelectedOrder] = useState(null);

    const user = useSelector(state => state.user);
    const navigate = useNavigate();
    const endpoint = "https://varyns.onrender.com";
    const query = useQuery();

    const handleClick = () => {
        navigate(`/catalogue/product/${selectedOrder.productId}`)
    };

    useEffect(() => {
        (async () => {
            const data = await getOrder(endpoint + `/orders?orderId=${query.get("order")}`, user.token);
            const orderData = await getProductDetails(data.map(item => ({ product: item.productId })));
            setSelectedOrder(data.map((item, index) => ({ ...item, description: orderData[index].value.description, images: orderData[index].value.images, title: orderData[index].value.title, productId: orderData[index].value._id })))
            // setSelectedOrder({ ...data, description: orderData.value.description, images: orderData.value.images, title: orderData.value.title, productId: orderData.value._id });
        })();
        scrollToTop(window);
    }, []);

    return (
        <div className="p-2" >
            <h1 className="my-2 font-semibold text-xl" >Thank you, Your order has been placed.</h1>
            <p className="tracking-tight" >Please check your email for order confirmation and detailed delivery information.</p>
            {!selectedOrder ? <div className="my-2 grid grid-cols-[70%_30%] grid-rows-[repeat(2,50%)]" >
                <h2 className="font-bold row-start-1 row-end-2 col-start-1 col-end-2" >Shipping to
                    <Skeleton count={1} width={150} enableAnimation={true} />
                </h2>
                <div className="font-bold col-start-1 col-end-2 row-start-2 row-end-3" >
                    <Skeleton count={1} width={120} enableAnimation={true} />
                </div>
                <Skeleton count={1} enableAnimation={true} width={70} height={70} />
            </div>
                : (
                    selectedOrder.map(item => (
                        <div className="my-2 grid grid-cols-[70%_30%] grid-rows-[repeat(2,50%)]" >
                            <h2 className="font-bold row-start-1 row-end-2 col-start-1 col-end-2" >Shipping to {
                                !selectedOrder ? <Skeleton count={1} width={150} enableAnimation={true} /> : <small className="font-medium" >{`${item.address[0].fullName} ${item.address[0].apartment}...`}</small>
                            }</h2>
                            <div className="font-bold col-start-1 col-end-2 row-start-2 row-end-3" >
                                {!selectedOrder ? <Skeleton count={1} width={120} enableAnimation={true} /> :
                                    <p className="text-lime-700" >Your Order will be delivered in {item.estimated_delivery_days} days.</p>}
                            </div>
                            {
                                selectedOrder ? <img onClick={handleClick} className="h-32 cursor-pointer inline-block col-start-2 col-end-3 row-start-1 row-end-3" src={item.images[0]} alt={selectedOrder.title} /> : <Skeleton count={1} enableAnimation={true} width={70} height={70} />
                            }
                        </div>
                    ))
                )
            }
            <Link to="/orders" className="my-4 rounded-sm block p-[10px] text-center bg-slate-200 border-solid border border-slate-400" >
                Review or edit your recent orders
            </Link>
        </div>
    );
}