import { useState, useEffect } from 'react';
import { faSearch, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactStars from "react-stars";
import ReviewContainer from "./review.container";
import { getReviewsByProductId } from '../assets';
import canReview from '../helpers/canReview';
import { useSelector } from 'react-redux';


export default function CustomerReviewsContainer({reviews, setReviews, writeReview, productId, userId }) {

    const endPoint = "https://varyns.onrender.com/";
    // const endPoint = "http://localhost:4001/";


    const orders = useSelector(state => state.orders);

    useEffect(() => {
        (async () => {
            const res = await getReviewsByProductId(`${endPoint}reviews?productId=${productId}`);

            if (res) {
                setReviews(res);
            }

        })();
    }, []);

    return (
        <>
            <div className="px-2 my-4 py-2 border-2 border-solid border-slate-300 border-l-0 border-r-0" >
                <p className="font-bold text-left " >Customer Reviews</p>
                {
                    reviews ? (
                        <>
                            <div className="flex items-center" >
                                <ReactStars
                                    count={5}
                                    edit={false}
                                    value={reviews.averageRating}
                                    size={20}
                                    color2={'#ffd700'}
                                />
                                <p className="text-sm ml-2 mt-[5px]" >{reviews.averageRating} out of 5</p>
                            </div>
                            <p className="text-slate-500 text-sm" >{reviews.users.length} ratings</p>
                            <hr className="my-3 mt-4" />
                        </>
                    ) : (
                        <p className='text-center my-4 text-lg' >No Review Available</p>
                    )
                }
                {/* {
                    reviewVideoArr.length > 0 && (
                        <>
                            <p className="font-semibold" >Reviews with videos</p>
                            <div className="flex gap-x-2 h-56 my-2 overflow-x-scroll" >
                                {
                                    reviewVideoArr.map(video => <div className="bg-slate-300 rounded-xl shrink-0 w-32 h-full" >

                                    </div>)
                                }
                            </div>
                            <hr className="my-2" />
                        </>
                    )
                }
                {
                    reviewImagesArr.length > 0 && (
                        <>
                            <p className="font-semibold" >Reviews with images</p>
                            <div className="flex gap-x-2 h-36 my-2 overflow-x-scroll" >
                                {
                                    reviewImagesArr.map(images => <div className="bg-slate-300 rounded-xl shrink-0 w-32 h-full" >

                                    </div>)
                                }
                            </div>
                            <hr className="mt-4" />
                        </>
                    )
                } */}
                {
                    reviews && (
                        <>
                            <p className="text-lg font-bold mt-2 mb-3" >Top reviews</p>
                            <ReviewContainer reviewArr={reviews.allReviews} />

                        </>
                    )
                }


            </div>
            {
                reviews && reviews.allReviews.length > 2 && (
                    <div className="p-2 border-solid border-slate-400 border border-l-0 border-r-0" >
                        <p className="font-semibold" >See all reviews</p>
                    </div>
                )
            }
            {
                canReview(orders, productId, reviews, userId) && <button onClick={() => writeReview("open")} className="border border-solid border-slate-300 rounded-lg w-[calc(100%-18px)] border-box m-2 my-4 p-2 px-4 font-medium text-left" >Write a review</button>
            }
        </>
    )
}