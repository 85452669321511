import { useEffect, useRef, useState } from "react";
import FooterContainer from "../container/footer.container";
import { getEligibleReturns, getProductDetails, returnProduct } from "../assets";
import { useDispatch, useSelector } from "react-redux";
import * as ROUTES from "../constant/routes";
import { Link, useNavigate } from "react-router-dom";
import { updateErr } from "../redux/errSlice";
import Skeleton from "react-loading-skeleton";
import Loader from "../components/loader.spinner";

const returnWindow = 7;

export default function Returns() {

    const moveToTopRef = useRef(null);
    const [orders, setOrders] = useState(null);
    const [reason, setReason] = useState(null);
    const user = useSelector(state => state.user);
    const endPoint = "https://varyns.onrender.com";
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [reasonsArr, setReasonsArr] = useState([
        "Defective or faulty",
        "Wrong product shipped",
        "Poor quality product"
    ]);

    const navigate = useNavigate();

    const handleChange = ({ target }) => {
        const { value } = target;
        setReason(value === "Select a reason for return" ? null : value);
    }

    const handleSubmit = async (e, { _id: id, productId }) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await returnProduct(`${endPoint}/return-product?id=${id}&productId=${productId}`, reason, user.token);
            setLoading(false);
            if (res) {

            }
        } catch (err) {
            dispatch(updateErr(err.message));
        }
    }

    const canReturn = (createdAt) => {
        const date = new Date(createdAt);
        let difference = new Date().getTime() - date.getTime();
        difference = difference / (1000 * 3600 * 24);
        return difference < returnWindow
    }

    const handleContinueShopping = () => {
        navigate("/");
    }

    useEffect(() => {
        (async () => {
            try {
                let ordersArr = await getEligibleReturns(`${endPoint}/returns?userId=${user._id}`, user.token);
                if (ordersArr) {
                    ordersArr = ordersArr.filter(item => item.delivered);
                    const productData = await getProductDetails(ordersArr.map(item => ({ product: item.productId })));
                    setOrders(productData.map((item, index) => ({ ...item, ...ordersArr[index] })).reverse());
                }
            } catch (err) {
                dispatch(updateErr(err.message));
            }
        })();
    }, []);

    return (
        <div ref={moveToTopRef} >
            <div className="p-4" >
                <h1 className="text-2xl font-medium mt-4" >Choose items to return</h1>
                <p className="text-sm" >Read Our <Link className="text-blue-800 cursor-pointer" to={ROUTES.RETURN_POLICY} >Return Policy</Link></p>
                <div>
                    {orders ?
                        orders.length ? orders.map(order => (
                            <div className="p-4 flex my-2 " >
                                <img className="h-48 object-contain w-48" src={order.value.images[0]} alt="product image" />
                                {
                                    !canReturn(order.createdAt) ? (
                                        <div className="flex justify-center items-center" >
                                            <p>This product is no longer eligible for a return.</p>
                                        </div>
                                    ) :
                                        order.toBeReturned ? (
                                            <div className="flex justify-center items-center" >
                                                <p>Your request for a return has been accepted. We will contact you soon.</p>
                                            </div>
                                        ) : (
                                            <form onSubmit={e => handleSubmit(e, order)} className="flex flex-col justify-between" >
                                                <select onChange={handleChange} className=" py-2" required >
                                                    <option value="Select a reason for return" >Select a reason for return</option>
                                                    {
                                                        reasonsArr.map(item => (
                                                            <option value={item} >{item}</option>
                                                        ))
                                                    }
                                                </select>
                                                <div className="text-right" >
                                                    <button disabled={!reason} className={`${!reason ? "cursor-not-allowed bg-slate-400" : "cursor-pointer bg-slate-600"} text-white tracking-wide px-8 py-3 `} >Proceed</button>
                                                </div>
                                            </form>
                                        )
                                }
                            </div>
                        )) : (
                            <div className="flex flex-col items-center justify-center my-7 h-52" >
                                <p className="text-lg" >No order qualifies for return</p>
                                <button onClick={handleContinueShopping} className="rounded-sm bg-[#fed813] h-12 m-2 font-medium text-sm  px-4" >Continue Shopping</button>
                            </div>
                        )
                        : (
                            <>
                                <Skeleton className="mb-4" height="230px" />
                                <Skeleton height="230px" />
                            </>
                        )
                    }
                </div>
            </div>
            <FooterContainer passedRef={moveToTopRef} />
            {
                loading && <Loader small={false} />
            }
        </div>
    );
}