import { useEffect, useRef } from "react";
import { scrollToTop } from "../assets";
import FooterContainer from "../container/footer.container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen, faHeadset } from "@fortawesome/free-solid-svg-icons";



export default function CustomerService() {

    const moveToTopRef = useRef();
    useEffect(() => scrollToTop(window), []);

    return (
        <div ref={moveToTopRef} className="" >
            <div className="p-4" >
                <h1 className="text-xl my-3 mb-5" >
                    Welcome to
                    <br />
                    <span className="font-medium" >Customer Service</span>
                </h1>
                <div className="" >
                    <a href="tel:+919560543590" className="w-[50%] block rounded py-2 px-5 bg-slate-200" >
                        <FontAwesomeIcon className="mr-2" size="md" icon={faHeadset} />
                        Call us
                    </a>
                    <a href="mailto:varynsecommercestore@gmail.com" className="my-4 block w-[50%] rounded py-2 px-5 bg-slate-200" >
                        <FontAwesomeIcon className="mr-2" size="md" icon={faEnvelopeOpen} />
                        Email us
                    </a>
                </div>
            </div>
            <FooterContainer passedRef={moveToTopRef} />
        </div>
    )
}